import { Component, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import jsPDF from 'jspdf';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';


@Component({
  selector: 'app-pp-received',
  standalone: true,
  imports: [TopNavbarComponent,CommonModule,FormsModule],
  templateUrl: './pp-received.component.html',
  styleUrl: './pp-received.component.css'
})
export class PpReceivedComponent implements OnInit {
  fromDate: string = '';
  toDate: string = '';
  reportGenerated: boolean = false;
  reportData: any[] = [];

  constructor(){}

  ngOnInit(): void {
   this.reportData = [
    {
      program: 'NACP',
      category: 'Laboratory',
      platformType: 'Reagent',
      partNumber: '4043067',
      contractNumber: '18-009/2023-2023',
      poNumber: 'PO12345',
      contractQuantity: 1548,
      promisedDate: '15-Nov-23',
      contractUnitCost: '7.17 USD',
      actualDeliveryDate: '15-Nov-23',
      msdValueOfDeliveredQuantity: '4,001,821',
      grnNumber: 'GRN1234',
      supplierInvoiceNumber: 'INV12345',
      incomingShipmentQuantity: 600,
      valueOfIncomingShipments: '4,001,821',
      promisedDatePending: '15-Nov-23',
      paymentReferenceNumber: 'NFMIS123',
      paidAmount: '4,001,821',
      comments: 'Delivered'
    }
   ];
  }

  generateReport() {
    if (this.fromDate && this.toDate) {
      // Here you can call your API to fetch data based on the date range
      // For now, we just display the report with dummy data
      this.reportGenerated = true;
    } else {
      alert('Please select a valid date range.');
    }
    
  }

  downloadPDF(){
    const doc = new jsPDF();

    // Report heading
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text('MEDICAL STORES DEPARTMENT', 105, 10, { align: 'center' });
    doc.text('REPORT: RECONCILIATION OF PROCUREMENT, DELIVERY AND PAYMENT FOR GOODS PROCURED BY MSD', 105, 20, { align: 'center' });
    doc.text('PURPOSE: RECONCILIATION OF REQUEST, PROCUREMENT, DELIVERY AND PAYMENT FOR GOODS PROCURED BY MSD', 105, 30, { align: 'center' });

    // Table in PDF
    (doc as any).autoTable({
      margin: { top: 40 },
      head: [[
        'Program', 'Category', 'Platform/product type', 'Part Number', 'Line Desc', 
        'UOM', 'Name of the Order', 'Reference Letter', 'Submitted Date', 'Order Date', 
        'Expected Delivery Date', 'Unit Price USD', 'Order Product Cost', 
        'Contract Number', 'PO Number', 'Contract Quantity', 'Promised Date', 
        'Contract Unit Cost (USD)', 'Contract Product Cost USD', 'Actual Delivery Date', 
        'MSD Value of Delivered Quantity USD', 'GRN Number', 'Supplier Invoice Number', 
        'Incoming Shipment Quantity', 'Value of Incoming Shipments', 'Promised Date', 
        'Comments', 'Payment Status', 'Payment Reference Number', 'Paid Amount'
      ]],
      body: this.reportData.map(item => [
        item.program, item.category, item.platformProductType, item.partNumber, item.lineDesc, 
        item.uom, item.orderName, item.referenceLetter, item.submittedDate, item.orderDate, 
        item.expectedDeliveryDate, item.unitPriceUSD, item.orderProductCost, 
        item.contractNumber, item.poNumber, item.contractQuantity, item.promisedDate, 
        item.contractUnitCostUSD, item.contractProductCostUSD, item.actualDeliveryDate, 
        item.msdValueOfDeliveredQuantityUSD, item.grnNumber, item.supplierInvoiceNumber, 
        item.incomingShipmentQuantity, item.valueOfIncomingShipments, item.pendingPromisedDate, 
        item.comments, item.paymentStatus, item.paymentReferenceNumber, item.paidAmount
      ]),
      styles: {
        fontSize: 8,
        cellPadding: 3,
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: [40, 57, 101],
        textColor: 255,
        fontSize: 9,
        halign: 'center',
        valign: 'middle'
      },
      theme: 'grid',
      bodyStyles: { halign: 'center', valign: 'middle' },
    });

    doc.save('reconciliation-report.pdf');
  }

  exportToExcel(){
    
  }

}
