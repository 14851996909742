<!-- top-navbar.component.html -->
<div class="top-navbar">
    <div class="logo">
        <!-- <img src="assets/msd.png" alt="MSD Logo"> -->
    </div>
    <!-- <div class="search-bar">
        <input type="text" placeholder="Search">
    </div> -->
    <div class="user-profile">
        <!-- <i class="fa fa-users"></i> -->
        <!-- <i class="fa fa-lightbulb" style="color:#005599"></i>
        <i class="fa fa-question-circle"style="color:#005599"></i> -->
        <div class="user-menu" (click)="toggleDropdown()">
            <i class="fa" [ngClass]="{'fa-user': !isDropdownOpen, 'fa-user-circle': isDropdownOpen}" style="color:#005599"></i>
            <div class="dropdown-menu" *ngIf="isDropdownOpen">
              <a (click)="profile()">Profile</a>
              <a href="#" (click)="logout()">Logout</a>
            </div>
          </div>
        <!-- <img class="w-20px h-20px" src="assets/user_icon.png" alt="User Profile" > -->
    </div>
</div>
