<div class="d">
  <app-top-navbar></app-top-navbar>
  <div class="content-area ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/users/list">Users</a></li>
        <li class="breadcrumb-item active" aria-current="page">List</li>
      </ol>
    </nav>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <!-- Search input on the left -->
      <input type="text" class="form-control w-50" placeholder="Search by name or email" [(ngModel)]="searchTerm"
        (input)="applyFilter()" />

      <!-- Add New User button on the right -->
      <!-- <button class="btn btn-primary me-2" (click)="exportData()">Export</button> -->
      <button class="btn btn-success" (click)="openModel()">Add New User</button>

    </div>

    <!-- User Table -->
    <!-- <app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
    <table class="table table-striped table-bordered user-table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Institution</th>
          <th>Category</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
       
        <tr *ngFor="let user of filteredUsers">
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.institution }}</td>
          <td>{{ user.category }}</td>
          <td>{{ user.is_active ? 'Active' : 'Not Active' }}</td>
          <td style="width:10%;">
            <!-- Action Buttons -->
            <i class="fas fa-eye" data-bs-toggle="tooltip" title="View" (click)="viewUser(user.id)" style="cursor: pointer;"></i>
            <i class="fas fa-edit mx-2" data-bs-toggle="tooltip" title="Edit" style="cursor: pointer;"></i>
            <i class="fas fa-key" data-bs-toggle="tooltip" title="Add Permission" style="cursor: pointer;"></i>
            <i class="fas fa-unlock mx-2" data-bs-toggle="tooltip" title="Unlock account" (click)="openUnlockAccountModel()" style="cursor: pointer;"></i>
            
            <input type="checkbox" data-toggle="switchbutton" checked data-size="xs"data-bs-toggle="tooltip" title="change status">
            

          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination controls -->
    <div class="pagination-controls">
      <button class="btn btn-warning" (click)="goToPreviousPage()" [disabled]="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button class="btn btn-success" (click)="goToNextPage()" [disabled]="currentPage === totalPages">Next</button>
    </div>

  </div>
</div>
<!-- Modal -->
<div class="modal " id="addUserModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addUserModalLabel">Add New User</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeModel()"></button>
      </div>
      <div class="modal-body">
        <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
        <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
          <div class="row mb-3">
            <div class="col-md-6">
              <!-- <label for="category" class="form-label">Category</label> -->
              <label for="category" class="form-label">
                Category<span class="text-danger">*</span> 
              </label>
              <!-- <input type="text" class="form-control" formControlName="category"> -->
                 <select formControlName="category" class="form-select">
                  <option value="">Select Category</option>
                  <option *ngFor="let category of categories" [value]="category.category">
                    {{ category.category }}
                  </option>
                </select>
              <div *ngIf="addUserForm.get('category')?.invalid && addUserForm.get('category')?.touched"
              class="text-danger">
              category is required.
            </div>
              <!-- <select formControlName="userType" class="form-select">
                <option value="">Select User Type</option>
                <option value="internal">Internal User</option>
                <option value="external">External User</option>
              </select> -->
            </div>
            <div class="col-md-6">
              <label for="institution" class="form-label">Institution
                <span class="text-danger">*</span>
              </label>
              <!-- <input type="text" class="form-control" formControlName="institution"> -->
              <select formControlName="institution" class="form-select">
                <option value="">Select institution</option>
                <option *ngFor="let institution of institutions" [value]="institution.institutionid">
                  {{ institution.institutionid }}
                </option>
              </select>
              <div *ngIf="addUserForm.get('institution')?.invalid && addUserForm.get('institution')?.touched"
              class="text-danger">
              institution is required.
            </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-4">
              <label for="firstName" class="form-label">First Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" formControlName="firstName">
              <div *ngIf="addUserForm.get('firstName')?.invalid && addUserForm.get('firstName')?.touched"
                class="text-danger">
                First Name is required.
              </div>
            </div>
            <div class="col-md-4">
              <label for="middleName" class="form-label">Middle Name</label>
              <input type="text" class="form-control" formControlName="middleName">
            </div>
            <div class="col-md-4">
              <label for="lastName" class="form-label">Last Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" formControlName="lastName">
              <div *ngIf="addUserForm.get('lastName')?.invalid && addUserForm.get('lastName')?.touched"
                class="text-danger">
                Last Name is required.
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <label for="phone" class="form-label">Phone
                <span class="text-danger">*</span>
              </label>
              <div class="input-group">
                <!-- Tanzanian flag -->
                <span class="input-group-text" id="basic-addon1">
                  <img src="https://flagcdn.com/w20/tz.png" alt="Tanzanian Flag" style="width: 20px; height: 13px;">
                </span>
                <!-- Country code -->
                <span class="input-group-text">+255</span>
                <!-- Phone number input field -->
                <input type="tel" class="form-control"formControlName="phone"  placeholder="Enter your phone number" aria-label="Phone" aria-describedby="basic-addon1">
              </div>
              <!-- Validation message -->
              <div class="text-danger" id="phone-error" style="display: none;">
                Phone is required and must be a valid number.
              </div>
              <!-- <label for="phone" class="form-label">Phone</label>
              <input type="tel" class="form-control" formControlName="phone">
              <div *ngIf="addUserForm.get('phone')?.invalid && addUserForm.get('phone')?.touched" class="text-danger">
                Phone is required and must be numeric.
              </div> -->
              <!-- <label for="phone" class="form-label">Phone</label>
              <div class="phone-input-wrapper">
                <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="['tz']"
                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                  [separateDialCode]="true" [phoneValidation]="true" formControlName="phone"></ngx-intl-tel-input>
              </div>
              <div *ngIf="addUserForm.get('phone')?.invalid && addUserForm.get('phone')?.touched" class="text-danger">
                Phone is required and must be a valid number.
              </div> -->
            
            </div>
            <div class="col-md-6">
              <label for="email" class="form-label">Email
                <span class="text-danger">*</span>
              </label>
              <input type="email" class="form-control" formControlName="email">
              <div *ngIf="addUserForm.get('email')?.invalid && addUserForm.get('email')?.touched" class="text-danger">
                A valid email is required.
              </div>
            </div>
          </div>

          <!-- <div class="row mb-3">
            <div class="col-md-6">
              <label for="role" class="form-label">Role</label>
              <select formControlName="role" class="form-select">
                <option value="">Select Role</option>
                <option value="admin">Super Admin</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="viewer">Officer</option>
              </select>
            </div>
          </div> -->

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
              (click)="closeModel()">Close</button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

  <!-- Modal for unlock user form-->
  <div class="modal " id="unlockAccountModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addUserModalLabel">Unlock User Account</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeUnlockAccountModel()"></button>
        </div>
        <div class="modal-body">
          <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
          <form [formGroup]="unlockAccountForm" (ngSubmit)="unlockAccount()">
            <div class="row mb-3">
              <div class="col-md-12">
                <label for="email" class="form-label">Email
                    <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" formControlName="email">
               
              </div>
              

            </div>
  
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                (click)="closeUnlockAccountModel()">Close</button>
              <button type="submit" class="btn btn-primary" [disabled]="unlockAccountForm.invalid">Unlock</button>
            </div>
          </form>
  
        </div>
      </div>
    </div>
  </div>