import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-requisition-view',
  standalone: true,
  imports: [TopNavbarComponent,FormsModule,CommonModule],
  templateUrl: './requisition-view.component.html',
  styleUrl: './requisition-view.component.css'
})
export class RequisitionViewComponent implements OnInit {
  disbursements : any = {};
  products : any= [];
  id! : string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService) {}

  ngOnInit(): void {
   this.id = this.route.snapshot.paramMap.get('id')!;
   this.getDisbursementDetails()
  }
  private apiUrl = environment.apiEndpoint;
  getDisbursementDetails(){
    const url = `${this.apiUrl}accounting/api/v1/disbursment/${this.id}`;
    this.http.get(url).subscribe(
      (data:any) => {
        this.disbursements = data.data;
        this.products = data.data.items;
        console.log('disbursement details:', this.disbursements)
        console.log('product details:', this.products);
      }
    )
  }

}
