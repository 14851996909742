import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { AccountcodeserviceService } from './service/accountcodeservice.service';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { Router } from '@angular/router';
import { MsdCodedialogComponent } from './msd-codedialog/msd-codedialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-accounting-code',
  standalone: true,
  imports: [LayoutsComponent,SidebarComponent,TopNavbarComponent,CommonModule,LoadingSpinnerComponent],
  templateUrl: './accounting-code.component.html',
  styleUrl: './accounting-code.component.css'
})
export class AccountingCodeComponent implements OnInit {
  codes: any[] = [];
  loading = true;  // loading state

  constructor(private accountingcode: AccountcodeserviceService, private route: Router,public dialog: MatDialog){}

  ngOnInit(): void {
    this.loading = true;
    this.accountingcode.getAccountCode().subscribe((data: any) => {
     if(data){

       this.codes = data.results;
       this.loading = false;
     }else{
      alert('No data Found!')
     }
    })
  }
 
navigate(){
  alert('New MoF code has been downloaded successfully');
  location.reload();
// this.route.navigate(['/code-mapping']);
}
mapMsdcode(partno: any){
  const dialogRef = this.dialog.open(MsdCodedialogComponent, {
    width: '300px',
    data: { uom: partno.partnumber },
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result if needed, e.g., refresh data or show a success message
      // console.log('Updated Internal UOM:', result);
      this.ngOnInit(); // Refresh data after updating
    }
  });

}
}
