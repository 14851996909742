import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-global-fund-receipt',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, FormsModule],
  templateUrl: './global-fund-receipt.component.html',
  styleUrl: './global-fund-receipt.component.css'
})
export class GlobalFundReceiptComponent implements OnInit {
  fromDate: string = '';
  toDate: string = '';
  reportGenerated: boolean = false;
  reportData: any[] = [];

  constructor(){}

  ngOnInit(): void {
    this.reportData = [
      {
        sno: '1',
        receiptdate: '30/10/2024',
        grantname: 'TZA-MA-MOFP',
        program: 'BE',
        referenceno: '12X423',
        description: 'Procurement of Health commodities',
        currency: 'USD',
        amount: '50000',
        remarks: 'Checked and analysed'
      }

    ]
  }

  generateReport() {
    if (this.fromDate && this.toDate) {
      // Here you can call your API to fetch data based on the date range
      // For now, we just display the report with dummy data
      this.reportGenerated = true;
    } else {
      alert('Please select a valid date range.');
    }
    
  }
  downloadPDF(){
    const doc = new jsPDF();

     // Report heading
     doc.setFontSize(12);
     doc.setFont("helvetica", "bold");
     doc.text('MEDICAL STORES DEPARTMENT (MSD)', 105, 10, { align: 'center' });
     doc.text('Report Title: GLOBAL FUND RECEIPT', 105, 20, { align: 'center' });
     doc.text(`From: ${this.fromDate || '...'} To: ${this.toDate || '...'}`, 105, 30, { align: 'center' });
     doc.text('Purpose: To show the receipt by MSD from global fund during the period', 105, 40, { align: 'center' });
 


    // doc.text('Order Status Report', 10, 10);
    (doc as any).autoTable({
      margin: { top: 50 },
      head: [['S/NO', 'Receipt Date', 'Grant Name', 'Program', 'Reference Number', 'Description', ' Currency ', 'Amount', 'Remarks']],
      body: this.reportData.map(item => [
        item.sno,
        item.receiptdate,
        item.grantname,
        item.program,
        item.referenceno,
        item.description,
        item.currency,
        item.amount,
        item.remarks,
      ]),
    });

    doc.save('Global-fund-Receipt.pdf');
  }
}
