import { Component } from '@angular/core';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-order-report-screen',
  standalone: true,
  imports: [TopNavbarComponent],
  templateUrl: './order-report-screen.component.html',
  styleUrl: './order-report-screen.component.css'
})
export class OrderReportScreenComponent {

}
