<div class="sidenav-header">
  
    <div class="header-text" [class.hide-header-text]="sideNavCollapsed()">
        <h2>PORTAL SERVICES</h2>
        <P></P>
       
    </div>
    <mat-nav-list>
        @for (item of menuItems(); track item.label) {
          
            <app-menu-item [item]="item" [collapsed]="sideNavCollapsed()" />
        }
       
    </mat-nav-list>
</div>