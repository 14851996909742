import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-authenticator',
  standalone: true,
  imports: [FormsModule,CommonModule,ReactiveFormsModule,LoadingSpinnerComponent],
  templateUrl: './authenticator.component.html',
  styleUrl: './authenticator.component.css'
})
export class AuthenticatorComponent implements OnInit {
  private apiUrl = environment.usermgtEndpoint;
  verifyForm: FormGroup;
  loading = false;

  constructor(private router: Router, private http: HttpClient,private fb: FormBuilder, private toastr: ToastrService){
    this.verifyForm = this.fb.group({
      verifytoken: ['', Validators.required]  // Setting up the form control
    });
  }
  ngOnInit(): void {
  //  this.loading = true;
  
  }

  verify2fa(){
    this.loading= true;
    const token = localStorage.getItem('access_token');
    console.log('stored token', token);
    const url = `${this.apiUrl}usermgt/api/v1/verify-2fa/`; 
    // const url = `https://accounts.msd.atomatiki.tech/usermgt/api/v1/verify-2fa/`; 

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    const requestBody = { token: this.verifyForm.get('verifytoken')?.value || '' };

    // Log the request body
    console.log('Request Body:', requestBody);

    this.http.post<any>(url, requestBody, { headers }).subscribe(
      (response) => {
        this.loading = false;
        // alert('Token verified successfully! 2FA is enabled.');
        this.toastr.success('Token verified successfully!');
        this.router.navigate(['/dashboard']);
        // this.showTokenInput = false;
      },
      (error) => {
        console.error('Failed to verify token:', error);
        this.loading = false;
        this.toastr.error('Authentication credentials were not provided');
      }
    );

  }

}
