import { Component } from '@angular/core';

@Component({
  selector: 'app-status-summary-per-program',
  standalone: true,
  imports: [],
  templateUrl: './status-summary-per-program.component.html',
  styleUrl: './status-summary-per-program.component.css'
})
export class StatusSummaryPerProgramComponent {

}
