<div class="parent">
    <div class="container" id="container">

        <div class="form-container sign-in">

            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
              <img src="assets/msd.png" />
          
              <!-- New Password Field with Eye Icon -->
              <div class="password-container">
               
                <input [type]="showNewPassword ? 'text' : 'password'"  id="newpassword" formControlName="newpassword" placeholder="New Password.." />
                <i class="fa" [ngClass]="showNewPassword ? 'fa-eye-slash' : 'fa-eye'" (click)="showNewPasswordVisibility()"> </i>
              </div>
          
              <!-- Confirm Password Field with Eye Icon --> 
              <div class="password-container">
               
                <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmpassword" formControlName="confirmpassword"  placeholder="Confirm Password.." />
                <i class="fa" [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'" (click)="toggleConfirmPasswordVisibility()"></i>
              </div>
          
              <div class="row">
                <button type="submit" [disabled]="passwordForm.invalid">Reset</button>
              </div>
            </form>
          </div>
          
        <div class="toggle-container">
            <!-- <div class="toggle"> -->
            <!-- <div class="toggle-panel toggle-left">
                    <h1>Welcome Back!</h1>
                    <p>Enter your personal details to use all of portal features</p>
                    <button class="hidden" id="login">Sign In</button>
                </div> -->
            <!-- <div class="toggle-panel toggle-right"> -->
            <!-- <h1>MSD PORTAL</h1> -->
            <!-- <p>Login with your personal details to use all of portal features</p> -->
            <!-- <button class="hidden" id="register">Sign Up</button> -->
            <!-- </div> -->
            <!-- </div> -->
        </div>

    </div>

</div>


<!-- <script src="script.js"></script> -->
<!-- <form class="form">
    <h1 class="form-header">Change your password</h1>
    <div class="form-body">
      <div class="form-column-inputs">
        <div class="form-group">
          <span class="form-group-label">New Password</span>
          <input type="password" class="form-group-input" data-name="password" />
        </div>
        <div class="form-group">
          <span class="form-group-label">Confirm New Password</span>
          <span class="form-validation-message"></span>
          <input type="password" class="form-group-input" data-name="confirm-password" />
        </div>
        <div class="form-group">
          <button class="form-submit">Change my password</button>
        </div>
      </div>
      <div class="form-column-guide">
        <p><strong>Password must contain:</strong></p>
        <p id="upper">At least 1 upper case letter (A-Z)</p>
        <p id="number">At least 1 number (0-9)</p>
        <p id="length">At least 8 characters</p>
      </div>
    </div>
  </form> -->
<!-- <span class="credits">Designed by <a href="https://dribbble.com/Priyank551990" target="_blank">Priyank Vyas</a>. Coded by Me.</span> -->