import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UomDialogComponent } from '../../external-uom/uom-dialog/uom-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-msd-codedialog',
  standalone: true,
  imports: [CommonModule,MatDialogModule,
    MatInputModule,
    MatButtonModule,FormsModule],
  templateUrl: './msd-codedialog.component.html',
  styleUrl: './msd-codedialog.component.css'
})
export class MsdCodedialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient
  ) {
    // this.msdPartno = ''; // Initialize internal UOM
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
