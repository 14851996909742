import { Component } from '@angular/core';
import { GrantCodeService } from '../../service/grant-code.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../../shared/loading/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-search-form',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,LoadingSpinnerComponent],
  templateUrl: './search-form.component.html',
  styleUrl: './search-form.component.css'
})
export class SearchFormComponent {
  searchForm: FormGroup;
  mappingForm: FormGroup;
  mofGrantCode: any;
  msdGrantCode: any;
  loading = false;

  constructor(private fb: FormBuilder, private grantCodeService: GrantCodeService) {
    this.searchForm = this.fb.group({
      mofCode: ['']
    });

      // Form for mapping MoF to MSD grant codes
      this.mappingForm = this.fb.group({
        mappedMofCode: [''],
        mappedMsdCode: ['']
      });
  }

  onSearch() {
    this.loading = true;
    const mofCode = this.searchForm.get('mofCode')?.value;
    if (mofCode) {
      this.grantCodeService.getMofGrantCode(mofCode).subscribe(mofData => {
        this.mofGrantCode = mofData;

        // Fetch corresponding MSD grant code using transref
        this.grantCodeService.getMsdGrantCode(this.mofGrantCode.mofcodeautonum).subscribe(msdData => {
          this.msdGrantCode = msdData;
          this.loading =false;
        });
      });
    }
  }
  onMapCodes() {
    if (this.mappingForm.valid) {
      const mappingData = this.mappingForm.value;

      // Save the mapping through the API
      const transrefautonum = this.msdGrantCode.transrefautonum;
      this.grantCodeService.saveMappedCodes(transrefautonum, mappingData).subscribe(
        response => {
          console.log('Mapping saved successfully', response);
        },
        error => {
          console.error('Error saving mapping:', error);
        }
      );
    }
  }

}
