import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-po-details',
  standalone: true,
  imports: [TopNavbarComponent,LoadingSpinnerComponent,CommonModule,],
  templateUrl: './po-details.component.html',
  styleUrl: './po-details.component.css'
})
export class PoDetailsComponent implements OnInit {
  poDetails: any;
  orderNo!: string;
  loading = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
   this.loading = true;
   this.orderNo = this.route.snapshot.paramMap.get('orderNo')!;
   this.getpoDetails();

  }
  private apiUrl = environment.apiEndpoint;
  
  getpoDetails() {
    const orderDetailsEndpoint = `${this.apiUrl}orders/api/v1/po-details/${this.orderNo}/`;
    this.http.get(orderDetailsEndpoint).subscribe((data: any) => {
      if(data){

        this.poDetails = data;
        this.loading=false;
      }else{
        alert("Something went wrong");
        this.loading=false;
      }
    });
  }

  close() {
    this.router.navigate(['order/purchaseOrder']);  
  }
}
