import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'
import { OheaderComponent } from '../../shared/oheader/oheader.component';

@Component({
  selector: 'app-layouts',
  standalone: true,
  imports: [
    RouterOutlet,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    OheaderComponent
  
  ],
  template: `
  <mat-toolbar class="mat-elevation-z3">
    
  </mat-toolbar>
  <mat-sidenav-container>
  <mat-sidenav opened mode="side" [style.width]="'250px'"> 
  
  </mat-sidenav>
  <mat-sidenav-content class="content">
  
    <router-outlet></router-outlet>
   </mat-sidenav-content>
  </mat-sidenav-container>
 
  ` ,
  styles: [`
    
    mat-toolbar{
    background-color: #164283;
    position: relative;
    z-index: 5;
}
    mat-icon{
    background-color:white
    }
    content{
    padding: 24px;
}
    mat-sidenav-container {
   
    height:calc(100vh-64px);
    }
  `]
  // templateUrl: './layouts.component.html',
  // styleUrl: './layouts.component.css'
})
export class LayoutsComponent {

}
