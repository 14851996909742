import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';

interface Product {
  productautonum: number;
  productcode: string;
  productname: string;
  productcategory: string;
  productspecs: string;
  form: string;
  revision: string;
  code: string;
  packsize: number;
  pricecode: string;
  price: string;
  isactive: boolean;
  uom: string;
  extproduct: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private apiUrl = environment.apiEndpoint;
 
  // private apiUrl = 'https://api.msd.atomatiki.tech/configurations/api/v1/product/';
  private externalProducturl= 'https://api.msd.atomatiki.tech/configurations/api/v1/extproduct/';
  // private apiUrl = 'https://45.55.198.41:5002/configurations/api/v1/product/';

  constructor(private http: HttpClient) {}

  // getProducts(): Observable<Product[]> {
  //   return this.http.get<Product[]>(this.apiUrl);
  // }
  getProducts(page:number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}configurations/api/v1/product?page=${page}`);
  }
  getExtProducts(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}configurations/api/v1/extproduct/`);
  }
  getProducts2(): Observable<any> {
    return of({
      count: 1,
      results: [
        {
          productautonum: 1,
          productcode: "10010007",
          productname: "ABACAVIR SULPHATE 600MG",
          productcategory: "mode",
          productspecs: "600MG Tablet",
          form: "Tablet",
          revision: "Rev 1",
          code: "AB001",
          packsize: 30,
          pricecode: "P001",
          price: "4500",
          isactive: true,
          uom: "30TB",
          extproduct: "Yes"
        }
      ]
    });
  }
  
}
