<!-- <app-layouts></app-layouts> -->

    
    <!-- <app-sidebar></app-sidebar> -->
    <div class="d">   
        <app-top-navbar></app-top-navbar>
        <div class="content-area ">
            <p>Settings > Part Numbersss</p>
           

            
            <div class="facility-table">
                <div class="us" style=" margin-left: 1020px;">
   
                    <button (click)="NavigateToCreate()" class="btn btn-secondary" style="background-color: #005590;">Download Part Number</button>
                </div>
                <table class="table table-striped purchase-table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Part Number</th>
                            <th>GDF Part Number</th>
                            <th>Description</th>
                            <th>Category</th>
                            <th>Unit Of Measure</th>
                           
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let number of numbers; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ number.partnumber }}</td>
                            <td>{{ number.wambonumber }}</td>
                            <td>{{ number.description }}</td>
                            <td>{{ number.category }}</td>
                            <td>{{ number.uom }}</td>
                            <td>
                                <button  aria-label="Search">
                                    <i class="fas fa-search"></i>
                                  </button>
                                  <button aria-label="Edit">
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <!-- <button (click)="forward(item)" aria-label="Forward">
                                    <i class="fas fa-share"></i>
                                  </button>
                                <i class="fa fa-search"></i>
                                <i class="fa fa-edit"></i> -->
                               
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        

        </div>
    </div>
