import { Component } from '@angular/core';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { MappingFormComponent } from './mapping-form/mapping-form.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-grant-code-mapping',
  standalone: true,
  imports: [TopNavbarComponent,SearchFormComponent,MappingFormComponent,CommonModule,FormsModule,ReactiveFormsModule],
  templateUrl: './grant-code-mapping.component.html',
  styleUrl: './grant-code-mapping.component.css'
})
export class GrantCodeMappingComponent {

  mofGrantCode: any;
  msdGrantCode: any;

  handleSearchResults(mofGrantCode: any, msdGrantCode: any) {
    this.mofGrantCode = mofGrantCode;
    this.msdGrantCode = msdGrantCode;
  }

}
