import { CommonModule } from '@angular/common';
import { Component, OnInit,  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { RouterLink } from '@angular/router';
import { BudgetService } from '../budget.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentExampleDialog } from '../dialog-content-example-dialog';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';

interface Budget {
  finyear: string;
  mofcode: string;
  description: string;
  amount: string;
  currency: string;
  createdate:string;
   transcode:string;
   openingbalance: string;
}

@Component({
  selector: 'app-budget-details',
  standalone: true,
  imports: [CommonModule,FormsModule,TopNavbarComponent,RouterLink,LoadingSpinnerComponent],
  templateUrl: './budget-details.component.html',
  styleUrl: './budget-details.component.css'
})
export class BudgetDetailsComponent implements OnInit {
  budgetData: any[] = [];
  errorMessage: string = '';
  loading = true;  // loading state
  currentPage: number = 1;
  totalPages: number = 0;
  itemsPerPage: number = 10; // 10 items per page as returned by the API
  totalItems: number = 0;
  filters = {
    facility: '',
    orderNo: '',
    startDate: '',
    endDate: ''
  };

  constructor(private budgetService: BudgetService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadBudget(this.currentPage);
    this.loading = true;
   
  }

  loadBudget(page: number) {  
    this.budgetService.getBudget(page).subscribe((data:any)=>{
      
      if(data){
        this.totalItems = data.count; 
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.budgetData = data.data.results;
        this.loading = false;
        console.log('Budget data', this.budgetData);
      }else{
        alert('No data Found!')
      }
      
     })
  }

  showPopup(title: string, message: string): void {
    this.dialog.open(DialogContentExampleDialog, {
      data: { title: title, message: message },
    });
  }
  applyFilters() {

  }
 

  retrieveBudget() {
    // Logic to retrieve budget details based on budgetPeriod
    // You can make an API call here if needed
  }

  saveBudget() {
    // Logic to save the budget details
    // console.log('Budget saved:', this.budgetDetails);
  }
  loadNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadBudget(this.currentPage);
    }
    // this.currentPage++;
    // this.loadOutstock();
  }
  
  loadPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadBudget(this.currentPage);
    }
    
  }

}
