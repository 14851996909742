import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { MatTableModule } from '@angular/material/table';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';
import { UsermngtService } from '../usermngt.service';
import { response } from 'express';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,FormsModule,MatTableModule,MainDashboardComponent,SidebarComponent,ReactiveFormsModule,LayoutsComponent, NgxIntlTelInputModule,LoadingSpinnerComponent],
  templateUrl: './category.component.html',
  styleUrl: './category.component.css'
})
export class CategoryComponent implements OnInit {
  addCategoryForm: FormGroup;
  categories: any[] =[];
  loading = true;

  constructor(private fb: FormBuilder, private userService: UsermngtService, private toastr: ToastrService,) {
    this.addCategoryForm = this.fb.group({
      category: ['', Validators.required],

    });
  }
  ngOnInit(): void {
    this.addCategoryForm = this.fb.group({
      category: ['', Validators.required],
      
    });
    this.fetchCategories();

  }
  fetchCategories(){
    this.loading = true;
    this.userService.getCategories().subscribe(
      (response)=> {
      this.loading = false;
      this.categories = response.results;
      console.log('categories response', response);
      console.log('categories ', this.categories);
    },
    (error) => {
      alert('Error fetching categories:'+ error)
      this.loading=false;
      console.error('Error fetching categories:', error);
    })
  }

  openModel() {
    const modalDiv = document.getElementById('addCategoryModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'block';
    }
  }

  closeModel() {
    const modalDiv = document.getElementById('addCategoryModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }
  onSubmit(){
    this.loading=true;
    if (this.addCategoryForm.valid) {
      const selectedCategory = this.addCategoryForm.value.category;
      console.log('Selected Category:', selectedCategory);
      const categoryData = {
        category: selectedCategory
      }
      this.userService.createCategory(categoryData).subscribe(
        (response)=>{
          this.loading= false;
          this.toastr.success('Category created successfully');
          console.log('Category created successfully', response);
          this.fetchCategories();
          this.closeModel();
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
        
          // Check if the error object contains the error message
          if (error.error && error.error.error) {
            // Display the error message from the backend
            this.toastr.error(error.error.detail);
          } else {
            // Fallback to a generic error message
            this.toastr.error('An unexpected error occurred. Please try again.');
          }
        
          console.error('Category creation failed', error);
        }
      )
  }
  
}

}
