import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { constructor } from 'assert';
import { Router } from '@angular/router';
import { Order } from '../facility-order/orders.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-facility-consumption',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,SidebarComponent,CommonModule,MainDashboardComponent,LoadingSpinnerComponent,FormsModule,NgSelectModule],
  templateUrl: './facility-consumption.component.html',
  styleUrl: './facility-consumption.component.css'
})
export class FacilityConsumptionComponent implements OnInit {
  loading = true;
  loadingFacilities = false;
  facilities: any[] = [];
  orders: any[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10; // 10 items per page as returned by the API
  totalItems: number = 0;
  filters = {
    facility: '',
    facilityNo: '',
    startDate: '',
    endDate: ''
  };
  
  constructor(private router: Router, private http:  HttpClient){}

 
  ngOnInit(): void {
    this.loading = true;
    this.loadOrdersConsumption();
    this.loadFacilities();
}
private apiUrl = environment.apiEndpoint;
loadOrdersConsumption() {
  const endpoint = `${this.apiUrl}orders/api/v1/facility-consumption-list?page=${this.currentPage}`; 
  // const endpoint = `https://api.msd.atomatiki.tech/orders/api/v1/api/v1/facility-consumption-list?page=${this.currentPage}`; 
  const params: any = {};

  if (this.filters.facility) {
    params.facilitycode = this.filters.facility;
  }
  if (this.filters.facilityNo) {
    params.facilityno = this.filters.facilityNo;
  }
  if (this.filters.startDate && this.filters.endDate) {
    params.startDate = this.filters.startDate;
    params.endDate = this.filters.endDate;
  }

   // Retrieve the token from localStorage
   const token = localStorage.getItem('access_token');

   // Set up the headers with Authorization token
   const headers = new HttpHeaders({
     'Authorization': `Bearer ${token}` // Append token to the Authorization header
   });

   console.log('token passed in header', headers);
   
  this.http.get(endpoint, { params, headers }).subscribe((data: any) => {
    this.totalItems = data.count; 
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.orders = data.results.map((order: any) => {
      // Format the items as a comma-separated string
      order.formattedItems = order.items.map((item: any) => item.productcode).join(', ');
      this. loading = false;
      return order;
    });
  });
}
applyFilters() {
  if (this.filters.facilityNo) {
    // Fetch based on order number if provided
    const orderEndpoint = `https://api.msd.atomatiki.tech/orders/api/v1/api/v1/facility_orders/${this.filters.facilityNo}/`;
    this.http.get(orderEndpoint).subscribe((data: any) => {
      this.orders = [data]; // If the response is a single order, put it in an array
    });
  } else {
    // If order number is not provided, use other filters and fetch data
    this.currentPage = 1; // Reset to the first page when filters are applied
    this.loadOrdersConsumption();     // Reload orders with filters
  }
  // this.currentPage = 1; 
  // this.loadOrdersConsumption();
}

loadNextPage() {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
    this.loadOrdersConsumption();
  }
  // this.currentPage++;
  // this.loadOrdersConsumption();
}

loadPreviousPage() {
  if (this.currentPage > 1) {
    this.currentPage--;
    this.loadOrdersConsumption();
  }
  
}
viewOrderConsumptionDetails(facilityNo: string, mothid:string) {
  this.router.navigate(['/consumption-details', facilityNo,mothid]);  // Navigate to order details page
}
loadFacilities() {
  this.loadingFacilities = true;
  const endpoint = 'https://api.msd.atomatiki.tech/configurations/api/v1/facility/';

  this.http.get(endpoint).subscribe(
    (data: any) => {
      this.facilities = data; // Assuming data is an array of facilities
      this.loadingFacilities = false;
    },
    error => {
      console.error('Error fetching facilities', error);
      this.loadingFacilities = false;
    }
  );
}
// viewDelivered(orderNo: string) {
//   this.router.navigate(['/order-delivered', orderNo]);
// }
}
