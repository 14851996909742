import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterOutlet } from '@angular/router';
import { CustomSidenavComponent } from '../custom-sidenav/custom-sidenav.component';
import { OheaderComponent } from '../../shared/oheader/oheader.component';
@Component({
  selector: 'app-main-dashboard',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatToolbarModule, MatButtonModule, MatIconModule,MatSidenavModule,CustomSidenavComponent,OheaderComponent],
  template: `
    <app-oheader></app-oheader>
  <mat-toolbar class="mat-elevation-z3">
    <button mat-icon-button (click)="collapsed.set(!collapsed())">
   
      <mat-icon>menu</mat-icon> 
    </button>         
  </mat-toolbar>
  <mat-sidenav-container> 
    <mat-sidenav opened mode="side" [style.width]="sidenavwidth()" style=" background-color: #005590">
     <app-custom-sidenav [collapsed]="collapsed()"> </app-custom-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="content" [style.margin-left]= "sidenavwidth()"> 
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
   
  `,
  styles: [
    `
    mat-toolbar {
    background-color: #005590;
    position:relative;
    z-index: 5;
    }
    .content{
    padding:24px;
    }

    mat-sidenav-container{
    
    height: calc(100vh - 64px);
    }
    mat-sidenav, 
    mat-sidenav-content{
    transition:all 500ms ease-in-out;
    }
     mat-icon{
    background-color:white 
    }

    `
  ]
})
export class MainDashboardComponent {

  collapsed = signal(false)
  sidenavwidth = computed(() => this.collapsed() ? '85px':'280px')

}
