<header>
    <div class="top-bar top-banner relative">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 header-title">
                    <div class="img">
                        <a href="#">
                            <img src="assets/msd.png" alt="" class="text-center" />
                        </a>
                    </div>

                    <!-- <div id="content-header-1"> -->
                       <!-- <h1 style="text-align: center;">Medical Store Department</h1>
                       <h3 style="text-align: center;">Portal services</h3> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="absolute top-4 right-1 flex">
            <div id="google_translate_element" class="flex"> 

                <div class="">
                    <div>
                     
                      
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</header>

<!-- <div class="d-flex justify-content-between  p-1"style="background-color:#E0F8F1">
    <div class="p-2 flex">
        <div  class="text-black font-weight-500 text-xsss">
           <marquee>Welcome to MSD Portal!</marquee>
        </div>
    </div>

    <div class="p-2">
        <ul class="navbar-nav" >
            <li class="nav-item dropdown">
                <a hrefy="" class="dropdown-toggle text-white" data-toggle="dropdown">
                   user
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                    <div class="user_set_header">
                        <p>user <br>
                            <span class="address"></span>
                        </p>
                    </div>

                    <div class="user_content">
                        <a class="dropdown-item font-weight-600" >My Profile</a>
                        <a class="dropdown-item font-weight-600" >Change Password</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item font-weight-600" >Logout</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div> -->