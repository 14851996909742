import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HeaderComponent } from '../../shared/header/header.component';
import { routes } from '../../app.routes';
import { ItemselectionService } from '../itemselection.service';
import { TopNavbarComponent } from '../../shared/top-navbar/top-navbar.component';



@Component({
  selector: 'app-switchboard',
  standalone: true,
  imports: [CommonModule,HeaderComponent,TopNavbarComponent],
  templateUrl: './switchboard.component.html',
  styleUrl: './switchboard.component.css'
})
export class SwitchboardComponent {

  switchboardItems = [
    { label: 'Stock', icon: 'fa fa-warehouse', color: '#FF5733' },
    { label: 'Accounting', icon: 'fa fa-file-invoice-dollar', color: '#33B5E5' },
    { label: 'Order', icon: 'fa  fa-shopping-cart', route: '/order' },
   
    { label: 'Reports', icon: 'fa fa-chart-bar', },
    { label: 'User Management', icon: 'fa fa-user-cog' },
    { label: 'Configuration', icon: 'fa fa-cogs',route: '/dashboard'  },
   
   
  
  ];

   constructor(private router: Router, private selectedItemService: ItemselectionService) {}

  navigateTo(route: string, label: string) {
    this.selectedItemService.setSelectedItem(label);
    this.router.navigate([route]);
  }

}
