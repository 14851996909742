import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-content-example-dialog',
  template: `
    <h6 mat-dialog-title class="text-center">{{data.title}}</h6>
    <div mat-dialog-content class="text-center">{{data.message}}</div>
    <div mat-dialog-actions>
      <button  mat-button mat-dialog-close>Close</button>
    </div>
  `,
  styles: [`
    button{
    margin-left:100px;
    }
 
  `]
})
export class DialogContentExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
