<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/audit/orders">AuditTrail</a></li>
            <li class="breadcrumb-item active" aria-current="page">Orders</li>
        </ol>
    </nav>
     <!-- Filters for Date Range, Facility, and Order Number -->
     <div class="filters">
        <form (ngSubmit)="filterAuditTrails()">
          

            <div class="row">
                <!-- Start Date Filter -->
                <div class="form-group col-md-6">
                    <label for="fromDate">From</label>
                    <input type="date" id="fromDate" [(ngModel)]="fromDate" name="fromDate"
                        class="form-control">
                </div>
                <!-- End Date Filter -->
                <div class="form-group col-md-6">
                    <label for="toDate">To</label>
                    <input type="date" id="toDate" [(ngModel)]="toDate" name="toDate"
                        class="form-control">
                </div>
            </div>

            <!-- Submit Button -->
            <div class="row">
                <div class="form-group col-md-12">
                    <button type="submit" class="btn btn-primary">Apply Filters</button>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="auditTrails.length > 0">
        <!-- <h5>Audit Trails (Total: {{ count }})</h5> -->
        <div class="d-flex">
            <!-- PDF Export Button -->
            <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF" (click)="exportToPdf()">
              <i class="fas fa-file-pdf"></i>
            </button>
        
            <!-- XLS Export Button -->
            <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS" (click)="exportToExcel()">
              <i class="fas fa-file-excel"></i>
            </button>
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>AuditID</th>
                    <th>Order Number</th>
                    <th>Action</th>
                    <th>Changes</th>
                    <th>Action Date</th>
                    <th>Remote Addr</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>
                <!-- Loop through audit trails -->
                <tr *ngFor="let audit of auditTrails; let i =index">
                    <td>{{i + 1}}</td>
                  <td>{{ audit.object_pk }}</td>
                  <td>{{ audit.action }}</td>
                  <td>{{ audit.changes }}</td>
                  <td>{{ audit.timestamp | date:'medium' }}</td>
                  <td>{{ audit.remote_addr }}</td>
                  <td>
                    <button (click)="viewOrderDetails(audit.object_pk)" aria-label="View" style="color:#005590 ;">
                        <i class="fas fa-eye" data-bs-toggle="tooltip" title="Order Details"
                            style="cursor: pointer;"></i>
                        <!-- <i class="fas fa-eye"></i> -->
                    </button>
                    <!-- <button (click)="toggleDetails(audit)" class="btn btn-info">View Details</button> -->
                  </td>
                </tr>
              
              
              </tbody>
              
              
              
        </table>
    </div>
</div>
