import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { ProductService } from './services/product.service';
import { Observable, of } from 'rxjs';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [CommonModule,LayoutsComponent,SidebarComponent,TopNavbarComponent,LoadingSpinnerComponent],
  templateUrl: './product.component.html',
  styleUrl: './product.component.css'
})
export class ProductComponent implements OnInit {
  products: any[] = [];
  loading = true;
    // Pagination variables
    currentPage = 1;
    totalPages = 0;
    pageSize = 10

  constructor(private productService: ProductService) {}
  
  ngOnInit(): void {
    this.loading = true;
   this.fetchProducts(this.currentPage)
  }

  fetchProducts(page:number){
    this.productService.getProducts(page).subscribe((data: any) => {  
      if(data){
        this.products = data.results;
        this.totalPages = Math.ceil(data.count / this.pageSize);
        console.log(this.products);
        this.loading = false;
      }else{
        alert('No data Found!')
      }
     
    });

  }

   // Pagination actions
   goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      // this.fetchUsers();
      this.fetchProducts(this.currentPage);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      // this.fetchUsers();
      this.fetchProducts(this.currentPage);
    }
  }

}
