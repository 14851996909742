import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'; // If you are using icons
import { MatFormFieldModule } from '@angular/material/form-field'; // If you are using form fields
import { MatInputModule } from '@angular/material/input'; // For input fields
import { MatPaginatorModule } from '@angular/material/paginator'; // Optional, for pagination
import { MatSortModule } from '@angular/material/sort'; // Optional, for sorting
import { MainDashboardComponent } from '../../../main-dashboard/main-dashboard.component';
import { SidebarComponent } from '../../../../shared/sidebar/sidebar.component';
import { LayoutsComponent } from '../../../../pages/layouts/layouts.component';
import { UsermngtService } from '../../usermngt.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormControl } from '@angular/forms';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


// interface User {
//   firstName: string;
//   lastName: string;
//   email: string;
//   category: string;
//   institution: string;
// }

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,FormsModule,MatTableModule,MainDashboardComponent,SidebarComponent,ReactiveFormsModule,LayoutsComponent, NgxIntlTelInputModule,LoadingSpinnerComponent],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent implements OnInit {
  addUserForm: FormGroup;
  unlockAccountForm: FormGroup;
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'username', 'category','institution', 'actions'];
  users: any=[];
  filteredUsers:any=[];
  searchTerm = '';
  loading = true;
  categories: any[] = [];
  institutions: any[] = [];
  // Pagination variables
  currentPage = 1;
  totalPages = 0;
  pageSize = 10;

  constructor(private fb: FormBuilder, private userService: UsermngtService, private toastr: ToastrService,private router: Router) {
    this.addUserForm = this.fb.group({
      category: ['', Validators.required],
      institution: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(/^[1-9]+$/)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
    });
    this.unlockAccountForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    // Initialize form and fetch the first page of users
    this.addUserForm = this.fb.group({
      category: ['', Validators.required],
      institution: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      // phone: ['', [Validators.required]], 
      phone: ['', [Validators.required, Validators.pattern(/^[1-9]+$/)]], 
      email: ['', [Validators.required, Validators.email]],
      role: ['']
    });
     // this.fetchUsers();
    this.fetchUsers(this.currentPage);
    this.fetchCategories();
    this.fetchInstitutions();
    this.loading=true;
  }

  // Fetch users with pagination
  fetchUsers(page: number) {
    this.loading=true;
    console.log("users list Before end:", this.users);
    this.userService.getUsers(page).subscribe(
      (response) => {
        this.loading = false;
        this.users = response.results;
        console.log("users list After end:", this.users);
        this.filteredUsers = [...this.users]; 
        this.totalPages = Math.ceil(response.count / this.pageSize);
      },
      (error) => {
        alert('Error fetching users:'+ error)
        this.loading=false;
        console.error('Error fetching users:', error);
      }
    );
  }


  // Handle search/filter
  applyFilter() {
    this.filteredUsers = this.users.filter((user: { first_name: string; last_name: string; email: string; }) =>
      user.first_name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  // Pagination actions
  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      // this.fetchUsers();
      this.fetchUsers(this.currentPage);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      // this.fetchUsers();
      this.fetchUsers(this.currentPage);
    }
  }
  openModel() {
    const modalDiv = document.getElementById('addUserModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'block';
    }
  }
  openUnlockAccountModel(){
    const modalDiv = document.getElementById('unlockAccountModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'block';
    }
  }

  closeModel() {
    const modalDiv = document.getElementById('addUserModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }
  closeUnlockAccountModel(){
    const modalDiv = document.getElementById('unlockAccountModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }

  onSubmit() {
    this.loading=true;
    // if (this.addUserForm.valid) {
      console.log('form modal triggered')
      const formData = this.addUserForm.value;
      // const phoneNumber = this.addUserForm.get('phone')?.value.internationalNumber;

      const userData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        // phone:  phoneNumber,
        // phone:  formData.phone,
         // Append +255 to the phone number
        phone: `+255${formData.phone}`,
        category: formData.category,
        institution: formData.institution
      };
      // const userData = this.addUserForm.value;
     
      console.log('Submitting user data:', userData); // Debugging step
      this.userService.createUser(userData).subscribe(
        (response) => {
          this.loading=false;
          this.toastr.success('User created successfully')
          console.log('User created successfully', response);
          this.fetchUsers(this.currentPage); 
          this.closeModel(); 
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
        
          // Check if the error object contains the error message
          if (error.error && error.error.error) {
            // Display the error message from the backend
            this.toastr.error(error.error.error);
          } else {
            // Fallback to a generic error message
            this.toastr.error('An unexpected error occurred. Please try again.');
          }
        
          console.error('User creation failed', error);
        }
      );
    // }
  }
  unlockAccount(){
    this.loading=true;
    if (this.unlockAccountForm.valid) {
      const useremail = this.unlockAccountForm.value.email;
      console.log('email entered:', useremail);
      const unlockAccountData = {
        email: useremail
      }
      this.userService.unlockAccount(unlockAccountData).subscribe(
        (response)=>{
          this.loading= false;
          this.toastr.success('Account unlocked successfully');
          console.log('Account unlocked successfully', response);
          this.fetchUsers(this.currentPage); 
          this.closeUnlockAccountModel();
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
        
          // Check if the error object contains the error message
          if (error.error && error.error.error) {
            // Display the error message from the backend
            this.toastr.error(error.error.detail);
          } else {
            // Fallback to a generic error message
            this.toastr.error('An unexpected error occurred. Please try again.');
          }
        
          console.error('Account failed to unlock', error);
        }
      )
  }
  }

  // Inside your component
get phoneControl(): FormControl {
  return this.addUserForm.get('phone') as FormControl;
}

fetchCategories(){
  this.loading = true;
  this.userService.getCategories().subscribe(
    (response)=> {
    this.loading = false;
    this.categories = response.results;
    console.log('categories response', response);
    console.log('categories ', this.categories);
  },
  (error) => {
    alert('Error fetching categories:'+ error)
    this.loading=false;
    console.error('Error fetching categories:', error);
  })
}
fetchInstitutions(){
  this.loading = true;
  this.userService.getInstitutions().subscribe(
    (response)=> {
    this.loading = false;
    this.institutions = response.results;
    console.log('institutions response', response);
    console.log('institutions ', this.institutions);
  },
  (error) => {
    this.toastr.error('Error fetching institutions:'+ error)
    this.loading=false;
    console.error('Error fetching institutions:', error);
  })
}
viewUser(id: string){
  this.router.navigate(['/users/details', id]);
}
}
