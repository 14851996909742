<!-- uom-dialog.component.html -->
<h2 mat-dialog-title>Map MSD code</h2>
<mat-dialog-content>
  <p><strong>MoF Code:</strong> GRT2024-001</p>
  <mat-form-field>
    <mat-label>MSD Code</mat-label>
    <input matInput  />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button >Map</button>
</mat-dialog-actions>
