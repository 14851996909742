import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { environment } from '../../../../environments/environment';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-purchase-order',
  standalone: true,
  imports: [LayoutsComponent, SidebarComponent, TopNavbarComponent, CommonModule, ReactiveFormsModule, LoadingSpinnerComponent, FormsModule],
  templateUrl: './purchase-order.component.html',
  styleUrl: './purchase-order.component.css'
})
export class PurchaseOrderComponent implements OnInit {
  newPoForm: FormGroup;
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10; // 10 items per page as returned by the API
  totalItems: number = 0;
  filters = {
    facility: '',
    orderNo: '',
    supplier: '',
    startDate: '',
    endDate: ''
  };
  AllPos: any[] = [];
  loading = true;

  constructor(private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.newPoForm = this.fb.group({
      extsystemid: ['', Validators.required],
      orderno: [null, Validators.required],
      requistionno: ['', Validators.required],
      orderdate: ['', Validators.required],
      duedate: ['', Validators.required],
      sourceid: ['', Validators.required],
      grantcycleid: ['', Validators.required],
      programid: ['', Validators.required],
      supplierid: ['', Validators.required],
      totalvalue: [null, Validators.required],
      currency: ['', Validators.required],
      destcountry: ['', Validators.required],
      status: ['', Validators.required],
      erporderno: [null, Validators.required]
    });
  }
  ngOnInit(): void {
    this.loading = true;
    this.loadAllOrders();

  }
  private apiUrl = environment.apiEndpoint;
  loadAllOrders() {
    // const endpoint = `http://45.55.198.41:5003/orders/api/v1/api/v1/po-list/?page=${this.currentPage}`; 
    const endpoint = `${this.apiUrl}orders/api/v1/po-list?page=${this.currentPage}`;
    // const endpoint = `${this.apiUrl}orders/api/v1/po-list/?page=${this.currentPage}`; 
    const params: any = {};

    if (this.filters.facility) {
      params.facilitycode = this.filters.facility;
    }
    if (this.filters.supplier) {
      params.supplierid = this.filters.supplier;
    }
    if (this.filters.orderNo) {
      params.orderno = this.filters.orderNo;
    }
    if (this.filters.startDate && this.filters.endDate) {
      params.startDate = this.filters.startDate;
      params.endDate = this.filters.endDate;
    }
    // Retrieve the token from localStorage
    const token = localStorage.getItem('access_token');

    // Set up the headers with Authorization token
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Append token to the Authorization header
    });

    console.log('token passed in header', headers);
    this.http.get(endpoint, { params, headers }).subscribe((data: any) => {
      this.totalItems = data.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      this.AllPos = data.results.map((order: any) => {
        // Format the items as a comma-separated string
        order.formattedItems = order.items.map((item: any) => item.productcode).join(', ');
        this.loading = false;
        return order;
      });
    },
      (error) => {
        this.toastr.error(error);
      });
  }
  applyFilters() {
    if (this.filters.orderNo) {
      // Fetch based on order number if provided
      const orderEndpoint = `${this.apiUrl}orders/api/v1/facility_orders/${this.filters.orderNo}/`;
      this.http.get(orderEndpoint).subscribe((data: any) => {
        // this.orders = [data]; // If the response is a single order, put it in an array
      });
    } else {
      // If order number is not provided, use other filters and fetch data
      // this.currentPage = 1; // Reset to the first page when filters are applied
      // this.loadOrders();     // Reload orders with filters
    }
    // this.currentPage = 1; 
    // this.loadOrders();
  }
  loadNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadAllOrders();
    }
    // this.currentPage++;
    // this.loadAllOrders();
  }

  loadPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadAllOrders();
    }

  }
  NavigateToCreate() {
    this.router.navigate(['order/dummyPo'])
  }


  addPO() {
    // Logic to add a new PO
    console.log('Add New PO clicked');
  }

  search(item: string) {
    this.router.navigate(['/po-details', item]);

  }

  edit(item: any) {
    // Logic to edit
    console.log('Edit', item);
  }

  forward(item: any) {
    // Logic to forward

  }
  openModel() {
    const modalDiv = document.getElementById('newpo');
    if (modalDiv != null) {
      modalDiv.style.display = 'block';
    }
  }
  closeModel() {
    const modalDiv = document.getElementById('newpo');
    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }
  onSubmit() {
    if (this.newPoForm.valid) {
      const payload = this.newPoForm.value;
      this.http.post('https://api.msd.atomatiki.tech/orders/api/v1/create_po', payload).subscribe(
        (response) => {
          console.log('Success:', response);
          // Handle successful response
        },
        (error) => {
          console.error('Error:', error);
          // Handle error response
        }
      );
    } else {
      console.error('Form is invalid');
    }
  }

  viewAllOrderDetails(orderNo: string) {
    this.router.navigate(['/allorder-details', orderNo]);  // Navigate to order details page
  }

}
