<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/order/purchaseOrder">Orders</a></li>
                <li class="breadcrumb-item"><a href="/order/purchaseOrder">All Orders</a></li>
                <li class="breadcrumb-item active" aria-current="page">Details</li>
            </ol>
        </nav>
        <!-- <h5>Facilities > Orders </h5> -->
        <div class="order-details-container">
            <div class="order-info">
                <h6>Order Details</h6>
                <p><strong>Period:</strong> {{ poDetails?.finyear }}</p>
                <p><strong>Order Number:</strong> {{ poDetails?.orderno }}</p>
                <p><strong>Order Date:</strong> {{ poDetails?.orderdate }}</p>
                <!-- <p><strong>Status:</strong> {{ poDetails?.status }}</p> -->
                <p><strong>Items:</strong></p>
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <table class="table">
                    <thead>
                        <tr>

                            <th>Product Code</th>
                            <th>Part Number</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Check if there are items, and then loop through them -->
                        <ng-container *ngIf="poDetails?.items?.length > 0; else noData">
                          <tr *ngFor="let item of poDetails?.items"> 
                            <td>{{ item.productcode }}</td>
                            <td>{{ item.externalpartno }}</td> 
                            <td>{{ item.productname }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.uom }}</td>
                            <td>{{ item.unitprice }}</td>
                            <td>{{ item.value }}</td>
                            <td>{{ item.status }}</td>
                          </tr>
                        </ng-container>
                      
                        <!-- Template to show if no data is found -->
                        <ng-template #noData>
                          <tr>
                            <td colspan="7" class="text-center">No data found</td>
                          </tr>
                        </ng-template>
                      </tbody>
                </table>
             
            </div>


        </div>
        <div class="close-button">
            <button (click)="close()" class="btn btn-primary">Close</button>
        </div>
    </div>
</div>