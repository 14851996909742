import { Component, OnInit } from '@angular/core';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-facility-consumption-report',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, FormsModule],
  templateUrl: './facility-consumption-report.component.html',
  styleUrl: './facility-consumption-report.component.css'
})
export class FacilityConsumptionReportComponent implements OnInit {
  fromDate: string = '';
  toDate: string = '';
  reportGenerated: boolean = false;
  reportData: any[] = [];
  private apiUrl = environment.apiEndpoint;
  private baseUrl = `${this.apiUrl}reports/api/v1/facility/consumption`;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    // Dummy data for demonstration purposes
    //  this.reportData = [
    //   {
    //     Partnumber: '1000001279',
    //     productdescription: 'TZA-M-MOF',
    //     uom: '30BT',
    //     plant: 'MBEYA',
    //     region: 'DSM',
    //     district: 'KGM',
    //     facilityid: '3000TD',
    //     facilityname: 'TUNDWI',
    //     total: '1,034,577.90'
    //   },

    // ];
  }
  generateReport() {
    if (!this.fromDate || !this.toDate) {
      alert('Please select both start and end dates.');
      return;
    }

    const params = new HttpParams()
      .set('fmt', 'json')
      .set('start_date', this.fromDate)
      .set('end_date', this.toDate);

      this.http.get<any>(this.baseUrl, { params }).subscribe(
        (response) => {
          if (response.success) {
            this.reportData = response.data.results; // Accessing the nested results array
            this.reportGenerated = true;
          } else {
            console.error('Error in response:', response.message);
          }
        },
        (error) => {
          console.error('Error fetching report:', error);
        }
      );
  }

  // Method to download the report as PDF
  downloadPDF() {
    if (!this.fromDate || !this.toDate) {
      alert('Please select both start and end dates.');
      return;
    }

    const params = new HttpParams()
      .set('fmt', 'pdf')
      .set('start_date', this.fromDate)
      .set('end_date', this.toDate);

    this.http.get(this.baseUrl, { params, responseType: 'blob' }).subscribe(
      (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Facility_Consumption_Report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }

  // Method to export the report as Excel
  exportToExcel() {
    if (!this.fromDate || !this.toDate) {
      alert('Please select both start and end dates.');
      return;
    }

    const params = new HttpParams()
      .set('fmt', 'excel')
      .set('start_date', this.fromDate)
      .set('end_date', this.toDate);

    this.http.get(this.baseUrl, { params, responseType: 'blob' }).subscribe(
      (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Facility_Consumption_Report.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading Excel:', error);
      }
    );
  }

  // generateReport() {
  //   if (this.fromDate && this.toDate) {

  //     this.reportGenerated = true;
  //   } else {
  //     alert('Please select a valid date range.');
  //   }

  // }

  // downloadPDF() {
  //   const doc = new jsPDF();


  //    doc.setFontSize(12);
  //    doc.setFont("helvetica", "bold");
  //    doc.text('MEDICAL STORES DEPARTMENT (MSD)', 105, 10, { align: 'center' });
  //    doc.text('Report Title: FACILITY CONSUMPTION REPORT DURING PERIOD', 105, 20, { align: 'center' });
  //    doc.text(`From: ${this.fromDate || '...'} To: ${this.toDate || '...'}`, 105, 30, { align: 'center' });
  //    doc.text('Purpose: To get actual consumption from  facility', 105, 40, { align: 'center' });




  //   (doc as any).autoTable({
  //     margin: { top: 50 },
  //     head: [['Part Number', 'Product Description', 'UOM', 'Plant', 'Region ', 'District', 'Facility ID', 'Facility Name', 'Total AMC']],
  //     body: this.reportData.map(item => [
  //       item.Partnumber,
  //       item.productdescription,
  //       item.uom,
  //       item.plant,
  //       item.region,
  //       item.district,
  //       item.facilityid,
  //       item.facilityname,
  //       item.total,
  //     ]),
  //   });

  //   doc.save('facility-consumption-report.pdf');
  // }
  // exportToExcel(){

  // }

}
