import { Component } from '@angular/core';
import { LayoutsComponent } from '../../../../pages/layouts/layouts.component';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { SidebarComponent } from '../../../../shared/sidebar/sidebar.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-request-part-number',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,SidebarComponent,FormsModule,CommonModule],
  templateUrl: './request-part-number.component.html',
  styleUrl: './request-part-number.component.css'
})
export class RequestPartNumberComponent {

  partNumbers = [
    { wamboPartNumber: 'WB-75937593', msdPartNumber: '10010007' },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '10010008' },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '10010009' },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '', editable: true },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '', editable: true }
  ];

}
