import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener,ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-top-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.css'
})
export class TopNavbarComponent {
  private apiUrl = environment.loginurl;
  isDropdownOpen = false;

  constructor(
    private renderer: Renderer2, 
    private elementRef: ElementRef,private router:Router,
    private http: HttpClient,
    private toastr: ToastrService ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.isDropdownOpen && !this.elementRef.nativeElement.contains(e.target)) {
        this.isDropdownOpen = false;
      }
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  logout_out22() {
    // Implement your logout logic here
    alert('Your about to leave this page, agree?')
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
    console.log('User logged out');
  }
  logout() {
    // Confirm logout action
    const confirmLogout = confirm('You are about to leave this page. Do you want to proceed?');
    
  
    if (confirmLogout) {
      // Fetch the token from local storage
      const token = localStorage.getItem('access_token');
  
      // If the token exists, make the logout request
      if (token) {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
  
        // Make the HTTP request to the logout endpoint 
        this.http.post(`${this.apiUrl}o/auth2/logout`, {}, { headers }).subscribe(
          (response) => {
            console.log('User successfully logged out', response);
  
            // Remove token from local storage
            localStorage.removeItem('access_token');
  
            // Navigate to login page after successful logout
            this.router.navigate(['/login']);
          },
          (error) => {
            console.error('Error logging out', error);
            // Optionally handle errors, like showing an error message
          }
        );
      } else {
        console.log('No access token found');
        // Optionally navigate to the login page directly
        this.router.navigate(['/login']);
      }
    }
  }
  
  profile(){
    this.router.navigate(['/users/profile']);
  }

}
