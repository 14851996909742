<!-- uom-dialog.component.html -->
<h2 mat-dialog-title>Map UOM</h2>
<mat-dialog-content>
  <p><strong>UOM:</strong> {{ data.uom }}</p>
  <mat-form-field>
    <mat-label>Internal UOM</mat-label>
    <input matInput [(ngModel)]="internalUom" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onUpdate()">Map</button>
</mat-dialog-actions>
