import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccountingService } from '../accounting.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-purchase-requisition',
  standalone: true,
  imports: [TopNavbarComponent,CommonModule,ReactiveFormsModule,FormsModule],
  templateUrl: './purchase-requisition.component.html',
  styleUrl: './purchase-requisition.component.css'
})
export class PurchaseRequisitionComponent implements OnInit {
  private apiUrl = environment.apiEndpoint;
  approveForm: FormGroup;
  id!: string;
  // disbursementId!: string;
  filters = {
    ordername: '',
    programid: '',
    startDate: '',
    endDate: ''
  };
 disbursements:any=[];
 currentPage = 1;
 totalPages = 0;
 pageSize = 10;

  constructor(
    private router: Router,
    private fb: FormBuilder,
   private accountservice: AccountingService,
  private toastr: ToastrService,
  private http: HttpClient
){
  this.approveForm = this.fb.group({
    approvedby: ['', Validators.required],  // Automatically set userid
    approveddate: ['', Validators.required] 
  })
}


  ngOnInit(): void {
    this.fetchDisbursements(`${this.apiUrl}accounting/api/v1/disbursment/?page=${this.currentPage}&pageSize=${this.pageSize}`);
    // this.fetchDisbursements(this.currentPage);
     // Get the ID from the route
  // this. disbursementId = this.router.snapshot.paramMap.get('id')!;
    // Retrieve the userid from localStorage
    const userid = localStorage.getItem('user_id');
    
    // Get today's date in 'YYYY-MM-DD' format
    const today = new Date().toISOString().split('T')[0];
    this.approveForm = this.fb.group({
      approvedby: [userid, Validators.required],  // Automatically set userid
      approveddate: [today, Validators.required]    // Default to today's date
    });
  }

  // fetchDisbursements(page: number){
  //   this.accountservice.getDisbursement(page).subscribe(
  //     (response) =>{
  //       this.disbursements = response.data.results;
  //       console.log('Disbursement Data:', this.disbursements);
  //       this.totalPages = Math.ceil(response.data.count / this.pageSize);
  //     },
  //     (error) => {
       
  //     this.toastr.error(error.error.message);
  //       console.error('Error fetching disbursements:', error);
  //     }
  //   );
  // }
  fetchDisbursements(url: string) {
    this.http.get(url).subscribe(
      (response: any) => {
        this.disbursements = response.data.results;
        this.totalPages = Math.ceil(response.data.count / this.pageSize);
        console.log('Filtered Disbursement Data:', this.disbursements);
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.error('Error fetching disbursements:', error);
      }
    );
  }
  

   // Pagination actions
   goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.applyFilters();
      // this.fetchDisbursements(this.currentPage);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.applyFilters();
      // this.fetchDisbursements(this.currentPage);
    }
  }

  NavigateToCreate(){
    this.router.navigate(['/requisition-create'])
  }
  applyFilters() {
    // Initialize base URL with pagination
    let url = `${this.apiUrl}accounting/api/v1/disbursment/?page=${this.currentPage}&pageSize=${this.pageSize}`;
  
    // Add filters to the URL if they are set
    if (this.filters.ordername) {
      url += `&ordername=${this.filters.ordername}`;
    }
    if (this.filters.programid) {
      url += `&programid=${this.filters.programid}`;
    }
    if (this.filters.startDate && this.filters.endDate) {
      url += `&startdate=${this.filters.startDate}&enddate=${this.filters.endDate}`;
    }
  
    // Fetch disbursements with the constructed URL
    this.fetchDisbursements(url);
  }
  
  viewDetails(id: string){
    this.router.navigate(['/requisition-details', id]);

  }
  openApproveModel(id: string){
   this.id = id;
    const modalDiv = document.getElementById('approveModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'block';
    }
  }

  closeApproveModel(){
    const modalDiv = document.getElementById('approveModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }
  approveDisbursement(){
    // alert('Successfully approved');
    if (this.approveForm.valid) {
      const formData = this.approveForm.value;

      // Retrieve the disbursement ID (replace 4 with dynamic id logic if needed)
      const disbursementId = 4;  // Replace with actual logic to get the ID

      // Send the form data to the endpoint
      const endpoint = `${this.apiUrl}/accounting/api/v1/disbursement/approve/${this.id}`;
      console.log('Endpoint:', endpoint);
      console.log('Form data:', formData);
      
      // Use your HTTP service to send the data
      // Example:
      this.http.patch(endpoint, formData).subscribe(response => {
        this.toastr.success('Disbursement approved successfully');
        this.closeApproveModel();
        console.log('Disbursement approved successfully', response);
      });

      
    }
  }
  clearFilters() {
    // Reset filters to default empty values
    this.filters = {
      ordername: '',
      programid: '',
      startDate: '',
      endDate: ''
    };

    // Reset pagination to the first page
    this.currentPage = 1;

    // Reload all data by calling fetchDisbursements with the base URL
    this.fetchDisbursements(`${this.apiUrl}accounting/api/v1/disbursment/?page=${this.currentPage}&pageSize=${this.pageSize}`);
  }
  // receiptDetails = [
   
  
  //   {
  //     referenceno: '10000017893',
  //     grantname: 'GRT-CT4',
  //     date: new Date(),
     
  //     program: 'TUS',
  //     amount: '20000',
  //     ordername: 'mult-Vue',
  //     approvalletterrefno: 'NA',
  //   },
  //   {
  //     referenceno: '22000017893',
  //     grantname: 'HT-67',
  //     date: new Date(),
      
  //     program: 'YNG',
  //     amount: '40000',
  //     ordername: 'mult-Qr',
  //     approvalletterrefno: 'NA',
  //   }
    
  // ];


}
