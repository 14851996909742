import { Component, Input } from '@angular/core';
import { GrantCodeService } from '../../service/grant-code.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-mapping-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mapping-form.component.html',
  styleUrl: './mapping-form.component.css'
})
export class MappingFormComponent {
  @Input() mofGrantCode: any;
  @Input() msdGrantCode: any;

  constructor(private grantCodeService: GrantCodeService) {}

  saveMapping() {
    const mappedData = {
      mofGrantCode: this.mofGrantCode.mofcode,
      msdGrantCode: this.msdGrantCode.transcode
    };

    this.grantCodeService.saveMappedCodes(this.msdGrantCode.transrefautonum, mappedData).subscribe(
      response => {
        console.log('Mapping saved successfully', response);
      },
      error => {
        console.error('Error saving mapping:', error);
      }
    );
  }

}
