<!-- <app-layouts></app-layouts> -->

    <!-- <app-sidebar></app-sidebar> -->
    
        <app-top-navbar></app-top-navbar>
        <div class="content-area ">
            <p>Purchase Orders > Create Dummy PO</p>
            <div class="container">
                <div class="input-section">
                  <input type="text" placeholder="Enter PO Number" [(ngModel)]="poNumber" />
                  <button (click)="getPoDetails()">Get PO from GDF</button>
                </div>
              
                <hr />
              
                <div class="po-details" *ngIf="poDetails">
                  <h3>PO Details</h3>
                  <table>
                    <tr>
                      <td><strong>Requisition Number</strong></td>
                      <td>{{ poDetails.requisitionNumber }}</td>
                    </tr>
                    <tr>
                      <td><strong>PO Number</strong></td>
                      <td>{{ poDetails.poNumber }}</td>
                    </tr>
                    <tr>
                      <td><strong>Order Date</strong></td>
                      <td>{{ poDetails.orderDate }}</td>
                    </tr>
                    <tr>
                      <td><strong>Part Number</strong></td>
                      <td>{{ poDetails.partNumber }}</td>
                    </tr>
                    <tr>
                      <td><strong>Item Description</strong></td>
                      <td>{{ poDetails.itemDescription }}</td>
                    </tr>
                    <tr>
                      <td><strong>Expected Delivery Date</strong></td>
                      <td>{{ poDetails.expectedDeliveryDate }}</td>
                    </tr>
                    <tr>
                      <td><strong>Delivery Status</strong></td>
                      <td>{{ poDetails.deliveryStatus }}</td>
                    </tr>
                    <tr>
                      <td><strong>UOM</strong></td>
                      <td>{{ poDetails.uom }}</td>
                    </tr>
                    <tr>
                      <td><strong>Quantity</strong></td>
                      <td>{{ poDetails.quantity }}</td>
                    </tr>
                    <tr>
                      <td><strong>Expected Unit Price</strong></td>
                      <td>{{ poDetails.expectedUnitPrice }}</td>
                    </tr>
                    <tr>
                      <td><strong>Actual Unit Price</strong></td>
                      <td>{{ poDetails.actualUnitPrice }}</td>
                    </tr>
                  </table>
                </div>
              
                <button class="dummy-po-btn" (click)="createDummyPo()">Create dummy PO</button>
              </div>
              
        </div>
    
