import { Component } from '@angular/core';
import { ExternaluomServiceService } from './externaluom-service.service';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UomDialogComponent } from './uom-dialog/uom-dialog.component';

@Component({
  selector: 'app-external-uom',
  standalone: true,
  imports: [TopNavbarComponent,LoadingSpinnerComponent,CommonModule,FormsModule,ReactiveFormsModule],
  templateUrl: './external-uom.component.html',
  styleUrl: './external-uom.component.css'
})
export class ExternalUomComponent {
  mapuomForm!: FormGroup;
  uoms: any[] = [];
  loading =true;

  constructor(private externalUomService: ExternaluomServiceService,public dialog: MatDialog) {}

  ngOnInit() {
    this.loading =true;
    this.externalUomService.getUoms().subscribe(data => {
      this.uoms = data.results;
      this.loading=false;
    });
  }

  mapUom(uom: any) {
    const dialogRef = this.dialog.open(UomDialogComponent, {
      width: '300px',
      data: { uom: uom.uom },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the result if needed, e.g., refresh data or show a success message
        console.log('Updated Internal UOM:', result);
        this.ngOnInit(); // Refresh data after updating
      }
    });
}

  openModel(){
    const modalDiv = document.getElementById('mapuomModal');
    if(modalDiv != null){
      modalDiv.style.display = 'block';
    }
  }
  closeModel(){
    const modalDiv = document.getElementById('mapuomModal');
    if(modalDiv != null){
      modalDiv.style.display = 'none';
    }
  }

  onSubmit() {
    if (this.mapuomForm.valid) {
    
      console.log(this.mapuomForm.value);
    } else {
     
      console.log('Form is invalid');
    }
  }

}
