import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {
  private apiUrl = environment.apiEndpoint;

  // private apiUrl = 'http://45.55.198.41:5003/orders/api/v1/api/v1/audittrail/';

  constructor(private http: HttpClient) { }

  getAuditTrails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}orders/api/v1/audittrail/`);
  }
}
