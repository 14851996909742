
    <div class="d">   
        <app-top-navbar></app-top-navbar>
        <div class="content-area ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/configuration/partnumber">Settings</a></li>
                    <li class="breadcrumb-item active" aria-current="page">external Product</li>
                </ol>
            </nav>
           
           

            
            <div class="facility-table">
                <div class="d-flex align-items-center mb-3">
                    <!-- Dropdown for External System -->
                    <select [(ngModel)]="selectedExternalSystem" class="form-select me-2" style="width: 450px;" aria-label="External System">
                        <option value="" disabled selected>Select External System</option>
                        <option value="gdf">GDF</option>
                        <option value="wambo">Wambo</option>
                    </select>
    
                    <!-- Input field for Product Category -->
                    <input type="text" [(ngModel)]="productCategory" class="form-control me-2"style="width: 450px;" placeholder="Product Category" />
    
                    <!-- Download Button -->
                    <button (click)="downloadPartNumbers()" class="btn btn-secondary" style="background-color: #005590;">Download New PartNumbers</button>
                </div>
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <table class="table table-striped purchase-table">
                    <thead>
                        <!-- <pre>{{extproducts| json}}</pre> -->
                        <tr>
                            <th>S/N</th>
                            <th>Part Number</th>
                            <th>Description</th>
                            <th>External System</th>
                            <th>MSD Part Number</th>
                          
                           
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let number of extproducts; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ number.partno }}</td>
                            <td>{{ number.productname }}</td>
                            <td>{{ number.extsystemid }}</td> 
                             <td>N/A</td>
                          
                            <td>
                                <button (click)="mapPartno(number)" aria-label="Search">
                                    <i class="fas fa-plus" data-bs-toggle="tooltip" title="map partnumber" style="cursor: pointer;"></i>
                                  </button>
                                 
                               
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        

        </div>
    </div>
