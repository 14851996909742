<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Facilities</a></li>
                <li class="breadcrumb-item"><a href="/facility/order">Orders</a></li>
                <li class="breadcrumb-item active" aria-current="page">Delivered</li>
            </ol>
        </nav>
        <!-- <h5>Facilities > Orders </h5> -->
        <div class="order-details-container">


            <div class="delivered-items">
                <h6>Delivered Items</h6>
                <p><strong>Facility:</strong> {{ deliveredItems?.facilitycode }}</p>
                <p><strong>Order Number:</strong> {{ deliveredItems?.orderno }}</p>
                <p><strong>Delivery Date:</strong> {{ deliveredItems?.receiveddate }}</p>
                <p><strong>Delivered Status:</strong> {{ deliveredItems?.deliverysatatus }}</p>
                <p><strong>Delivered By:</strong> {{ deliveredItems?.deliveredby }}</p>
                <p><strong>Items Delivered:</strong></p>
              
                <table class="table">
                   
                    <thead>
                        <tr>
                            <th>Product Code</th>
                            <th>Shipped Quantity</th>
                            <th>Received Quantity</th>
                            <th>Returned Quantity</th>
                            <th>UOM</th>
                            <th>Product</th>
                            <th>Replace Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="deliveredItems?.poditems?.length > 0; else noData">
                            <tr *ngFor="let podItem of deliveredItems?.poditems">
                            <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                                <td>{{ podItem.productcode }}</td>
                                <td>{{ podItem.shippedquantity }}</td>
                                <td>{{ podItem.receivedquantity }}</td>
                                <td>{{ podItem.returnedquantity }}</td>
                                <td>{{ podItem.uom }}</td>
                                <td>{{ podItem.replacedproductdesc }}</td>
                                <td>{{ podItem.replacereason }}</td>
                            </tr>
                        </ng-container>
                        <!-- Template to show if no data is found -->
                        <ng-template #noData>
                            <tr>
                                <td colspan="7" class="text-center">No data found</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
                <!-- <ul>
                <li *ngFor="let item of deliveredItems">
                  {{ item.productcode }} - {{ item.quantity }} {{ item.uom }} delivered on {{ item.deliverydate }}
                </li>
              </ul> -->
            </div>
            <!-- <div class="close-button">
                <button (click)="close()" class="btn btn-secondary">Close</button>
              </div> -->
        </div>
        <div class="close-button">
            <button (click)="close()" class="btn btn-primary">Close</button>
        </div>
    </div>
</div>