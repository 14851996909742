<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/report/facility-distribution">Reconciliation</a></li>
            <li class="breadcrumb-item active" aria-current="page">Report</li>
        </ol>
    </nav>
    <div class="container mt-5">
        <h5>Reconciliation of Procured Products through WAMBO</h5>

        <!-- Date range input section -->
        <div class="row">
            <div class="col-md-4">
                <label>From Date</label>
                <input type="date" [(ngModel)]="fromDate" class="form-control">
            </div>
            <div class="col-md-4">
                <label>To Date</label>
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>
            <div class="col-md-4">
                <button class="btn btn-primary mt-4" (click)="generateReport()">Generate Report</button>
            </div>
        </div>

        <!-- Display Report Section (including Heading) -->
        <div *ngIf="reportGenerated" class="mt-5">
            <div class="d-flex">
            <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF" (click)="downloadPDF()">
                <i class="fas fa-file-pdf"></i>
            </button>
            <button (click)="exportToExcel()" class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
                <i class="fas fa-file-excel"></i>
              </button>
            </div>
            <!-- Report Header -->
            <div class="text-center">
                <h6><strong>MEDICAL STORES DEPARTMENT (MSD)</strong></h6>
                <p><strong>Report Title:</strong>RECONCILIATION OF PROCUREMENT, DELIVERY AND PAYMENT FOR GOODS PROCURED
                    BY MSD</p>
                <p><strong>Purpose:</strong>RECONCILIATION OF REQUEST, PROCUREMENT, DELIVERY AND PAYMENT FOR GOODS
                    PROCURED BY MSD</p>
                <p><strong>From:</strong> {{ fromDate || '...' }} <strong>To:</strong> {{ toDate || '...' }}</p>
            </div>

            <!-- Report Table -->
            <table class="table table-bordered mt-4 styled-table">
                <!-- Main Headers Row -->
                <thead>
                    <tr>
                        <th colspan="4" class="moh-header">MOH - Program Order Information</th>
                        <th colspan="5" class="msd-contract-header">MSD Contract Information</th>
                        <th colspan="4" class="delivered-products-header">Delivered Products</th>
                        <th colspan="3" class="pending-products-header">Pending Products</th>
                        <th colspan="3"  class="payment-status-header">Payment Status</th>
                    </tr>

                    <!-- Sub-Headers Row -->
                    <tr>
                        <!-- MOH Program Order Information -->
                        <th class="moh-header">Program</th>
                        <th class="moh-header">Category</th>
                        <th class="moh-header">Platform/product type</th>
                        <th class="moh-header">Part Num</th>

                        <!-- MSD Contract Information -->
                        <th class="msd-contract-header">Contract Number</th>
                        <th class="msd-contract-header">PO Number</th>
                        <th class="msd-contract-header">Contract Quantity</th>
                        <th class="msd-contract-header">Promised Date</th>
                        <th class="msd-contract-header">Contract Unit Cost (USD)</th>

                        <!-- Delivered Products -->
                        <th class="delivered-products-header">Actual Delivery Date</th>
                        <th class="delivered-products-header">MSD Value of Delivered Quantity (USD)</th>
                        <th class="delivered-products-header">GRN Number</th>
                        <th class="delivered-products-header">Supplier Invoice Number</th>

                        <!-- Pending Products -->
                        <th class="pending-products-header">Incoming Shipment Quantity</th>
                        <th class="pending-products-header">Value of Incoming Shipments</th>
                        <th class="pending-products-header">Promised Date</th>

                        <!-- Payment Status -->
                        <th  class="payment-status-header">Payment Reference Number</th>
                        <th  class="payment-status-header">Paid Amount</th>
                        <th  class="payment-status-header">Comments</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of reportData">
                        <!-- MOH Program Order Information Data -->
                        <td>{{ item.program }}</td>
                        <td>{{ item.category }}</td>
                        <td>{{ item.platformType }}</td>
                        <td>{{ item.partNumber }}</td>

                        <!-- MSD Contract Information Data -->
                        <td>{{ item.contractNumber }}</td>
                        <td>{{ item.poNumber }}</td>
                        <td>{{ item.contractQuantity }}</td>
                        <td>{{ item.promisedDate }}</td>
                        <td>{{ item.contractUnitCost }}</td>

                        <!-- Delivered Products Data -->
                        <td>{{ item.actualDeliveryDate }}</td>
                        <td>{{ item.msdValueOfDeliveredQuantity }}</td>
                        <td>{{ item.grnNumber }}</td>
                        <td>{{ item.supplierInvoiceNumber }}</td>

                        <!-- Pending Products Data -->
                        <td>{{ item.incomingShipmentQuantity }}</td>
                        <td>{{ item.valueOfIncomingShipments }}</td>
                        <td>{{ item.promisedDatePending }}</td>

                        <!-- Payment Status Data -->
                        <td>{{ item.paymentReferenceNumber }}</td>
                        <td>{{ item.paidAmount }}</td>
                        <td>{{ item.comments }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- Button to Download PDF -->
            <!-- <button class="btn btn-success mt-3" (click)="downloadPDF()">Download PDF</button> -->
        </div>
    </div>


</div>