import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Partnumber } from '../../number.model';
import { LayoutsComponent } from '../../../../../pages/layouts/layouts.component';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../../../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../../../shared/top-navbar/top-navbar.component';
import { MainDashboardComponent } from '../../../../main-dashboard/main-dashboard.component';

@Component({
  selector: 'app-gdf-partnumber',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,SidebarComponent,CommonModule,MainDashboardComponent],
  templateUrl: './gdf-partnumber.component.html',
  styleUrl: './gdf-partnumber.component.css'
})
export class GdfPartnumberComponent implements OnInit {
  numbers: Partnumber[] = [];
  constructor(private router: Router){}

  NavigateToCreate(){
    this.router.navigate(['partnumber/gdfrequest'])
  }

  ngOnInit(): void {
    // Sample data
    this.numbers = [
        // {
        //   partnumber: '1001007',
        //   wambonumber: 'WB-82765439',
        //   description: 'LAMINVADG 300MG TABLETS',
        //   category: 'AB',
        //   uom: '30TB',
        //   status: 'Active'
        // },
        // {
        //     partnumber: '100024006',
        //     wambonumber: 'WB-796523415',
        //     description: 'DRINBGFDS 20DT MG',
        //     category: 'A',
        //     uom: '30TB',
        //     status: 'Active'
        // },
       
    ];
}

}
