import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private currentMenuSubject = new BehaviorSubject<string | null>(null);
  currentMenu$ = this.currentMenuSubject.asObservable();

  openMenu(menuId: string) {
    this.currentMenuSubject.next(menuId);
  }

  closeMenu() {
    this.currentMenuSubject.next(null);
  }
}
