<!-- <a mat-list-item class="menu-item" [routerLink]="item().route" (click)="toggledNested()" > 
    <mat-icon  matListItemIcon>{{ item().icon }}</mat-icon> 
    @if (!collapsed()){
       

        <span matListItemTitle style="margin-right: 60%;">{{ item().label }}</span>
       
    }
    @if(item().subItems){
        <span matListItemMeta>
            @if(nestedMenuOpen()){

                <mat-icon>expand_less</mat-icon>
            } @else{
                <mat-icon>expand_more</mat-icon>
            }
        </span>
    }
</a>

@if(item().subItems && nestedMenuOpen()) {
    <div>
        @for (subItem of item().subItems; track subItem.label){
            <a mat-list-item class="menu-item indented" [routerLink]="item().route + '/' + subItem.route" routerLinkActive="selected-menu-item"  #rla="routerLinkActive" [activated]="rla.isActive" > 
                <mat-icon  matListItemIcon>{{subItem.icon }}</mat-icon> 
                @if (!collapsed()){

                    <span matListItemTitle style="margin-right: 80%;">{{ subItem.label }}</span>
                }
            </a>
        }
    </div>

} -->

<a mat-list-item class="menu-item" [routerLink]="item.route" (click)="toggledNested()">
    <div class="d-flex justify-content-between items-center">
        <span class="d-flex items-center">
            <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
            <span *ngIf="!collapsed" class="menu-label" style="margin-right: 0%;">{{ item.label }}</span>
        </span>
        <span *ngIf="item.subItems">
            <mat-icon>{{ nestedMenuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
        </span>

    </div>
</a>
<!-- #rla="routerLinkActive" [activated]="rla.isActive" -->
<div *ngIf="item.subItems && nestedMenuOpen">
    <ng-container *ngFor="let subItem of item.subItems">
        <a mat-list-item class="menu-item indented" [routerLink]="item.route + '/' + subItem.route"
            routerLinkActive="selected-menu-item" #rla="routerLinkActive" [activated]="rla.isActive">
            <mat-icon matListItemIcon>{{ subItem.icon }}</mat-icon>
            <span *ngIf="!collapsed"  class="submenu-label"  [ngClass]="{'selected': rla.isActive}" style="margin-right: 80%;">{{ subItem.label }}</span>
        </a>
    </ng-container>
</div>