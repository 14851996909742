import { Component, OnInit } from '@angular/core';
import { AuditTrailService } from '../audit-trail.service';
import { CommonModule } from '@angular/common';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auditorders',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,FormsModule],
  templateUrl: './auditorders.component.html',
  styleUrl: './auditorders.component.css'
})
export class AuditordersComponent implements OnInit {
  auditTrails: any[] = [];
  count: number = 0;
  filteredAuditTrails: any[] = [];  // Data after applying the date range filter
  fromDate: string = '';  // From date value
  toDate: string = '';  

  constructor(private auditTrailService: AuditTrailService,  private router: Router,) { }

  ngOnInit(): void {
   
     this.getAuditTrails();
    this.filteredAuditTrails = this.auditTrails;
  }
    // Function to filter audit trails by date range
    filterAuditTrails(): void {
      if (this.fromDate && this.toDate) {
        const from = new Date(this.fromDate).getTime();
        const to = new Date(this.toDate).getTime();
        this.filteredAuditTrails = this.auditTrails.filter(audit => {
          const timestamp = new Date(audit.timestamp).getTime();
          return timestamp >= from && timestamp <= to;
        });
      } else {
        // If no dates are selected, show all records
        this.filteredAuditTrails = this.auditTrails;
      }
    }
    getAuditTrails(){
      this.auditTrailService.getAuditTrails().subscribe(data => {
        this.auditTrails = data.results;
        console.log('data for order audit:', this.auditTrails);
        this.count = data.count;
      });
    }

  toggleDetails(audit: any): void {
    audit.showDetails = !audit.showDetails;
  }

  // Function to export data to PDF
  exportToPdf222(): void {
    const doc = new jsPDF();
    doc.text('Audit Trails Report', 10, 10);
    this.filteredAuditTrails.forEach((audit, index) => {
      doc.text(`Object: ${audit.object_pk}, Action: ${audit.action}, Timestamp: ${audit.timestamp}`, 10, 20 + (index * 10));
    });
    doc.save('audit-trails.pdf');
  }
    // Function to export data to PDF
    exportToPdf(): void {
      const doc = new jsPDF();
  
      // Add header to the PDF
      doc.setFontSize(12);
      doc.text('THE UNITED REPUBLIC OF TANZANIA', 10, 10);
      doc.text('MEDICAL STORE  DEPARTMENT', 10, 18);
  
      // Add subtitle (Date range)
      doc.setFontSize(10);
      doc.text(`Orders From ${this.fromDate} To ${this.toDate}`, 10, 26);
  
      // Define table columns
      const columns = [
        // { title: 'AuditID', dataKey: 'auditId' },
        { title: 'Action', dataKey: 'action' },
        { title: 'Name', dataKey: 'name' },
        { title: 'Action Date', dataKey: 'actiondate' },
        { title: 'Changes', dataKey: 'changes' },
        { title: 'Remote Address', dataKey: 'address' },
      
      ];
  
      // Define the data rows
      const rows = this.filteredAuditTrails.map(audit => ({
        // auditId: audit.object_pk,  // Map to your actual data fields
        action: audit.action,
        name: audit.object_pk,  // Ensure you have this property in your data
        actiondate: audit.timestamp ,  // Provide a default value if necessary
        changes: audit.changes || 'N/A',
        address: audit.remote_addr || 0,
    
      }));
  
      // Create the table in the PDF
      (doc as any).autoTable({
        head: [columns],
        body: rows,
        startY: 34,  // Where the table starts after the header
        styles: { fontSize: 8 },
      });
  
      // Save the PDF
      doc.save('audit-trails.pdf');
    }

  // Function to export data to Excel
  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredAuditTrails);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'audit-trails.xlsx');
  }

  viewOrderDetails(orderNo: string) {
    this.router.navigate(['/auditorder-details', orderNo]);  // Navigate to order details page
  }
  
}
