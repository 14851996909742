import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { AuthService } from './Service/auth.service';
import { LoadingSpinnerComponent } from '../../shared/loading/loading-spinner/loading-spinner.component';
import { CommonModule } from '@angular/common';
import { IdleService } from '../idle.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, RecaptchaModule,ReactiveFormsModule,LoadingSpinnerComponent,CommonModule,NgxSpinnerModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  loading = false;
  loginForm!: FormGroup;
  // captchaToken!: string;
  captchaToken: string = '';
  captchaVerified: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private idleService: IdleService,
    private authService: AuthService,
    private toastr: ToastrService,

  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    
  
  }
   // Called when reCAPTCHA is resolved and the token is generated
   resolvedCaptcha(captchaResponse: string | null) {
    if (captchaResponse) {
      this.captchaToken = captchaResponse;
      console.log('captcha token is:', this.captchaToken);
      this.verifyCaptcha();  // Verify captcha token with backend
    } else {
      this.captchaToken = '';
      this.captchaVerified = false;
      console.error('Captcha response is null.');
    }
  }

  // Verifies the reCAPTCHA token with the backend 
  verifyCaptcha() {
    console.log('verify recapture function');
    const verifyData = { recaptcha_response: this.captchaToken };

    this.http
      .post<any>('https://accounts.msd.atomatiki.tech/usermgt/api/v1/verify-recaptcha/', verifyData)
      .subscribe(
        (response) => {
          console.log('recaptch response:', response);
          console.log('recaptch response message:', response.message);
          if (response.message === "reCAPTCHA verified successfully.") {
            this.captchaVerified = true;
          } else {
            this.captchaVerified = false;
            alert('Failed to verify reCAPTCHA. Please try again.');
          }
        },
        (error) => {
          console.error('reCAPTCHA verification failed', error);
          this.captchaVerified = false;
        }
      );
  }

  login__234() {
    if (!this.captchaVerified) {
    this.loading = true;
    const formData = new HttpParams()
      .set('grant_type', 'password')
      .set('username', this.loginForm.value.username)
      .set('password', this.loginForm.value.password)
      // .set('client_id', '29x1HpnopHIcnW39wxGnhOtSixK2vynNTNgxcm4F')
      // .set('client_secret', 'CgacmkCuMgWGQChCluiSLmLsQjKo1EGFkCDiUi7WSmqU8uSVvb22SKsKaY2AkOxqPDr22DHSUpTOPvtU75KymY2d5VYnCwHPcmln0jG5tYgT4B4EpOgSVYBPDh1untLB');

    this.http
      .post<any>('https://accounts.msd.atomatiki.tech/o/auth2/login/', formData.toString(), {
      // .post<any>('https://accounts.msd.atomatiki.tech/o/token/', formData.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .subscribe(
        (response) => {
          console.log('Access Token:', response.access_token); 
          console.log('User_id:', response.user_id);
          
          
          localStorage.setItem('access_token', response.access_token); 
          localStorage.setItem('user_id', response.user_id); 
          
          
          this.router.navigate(['/dashboard']); 
          this.loading = false;
        },
        (error) => {
          alert("Wrong password or username,Try Again")
          this.loading = false;
          console.error('Login failed', error);
        }
      );
    }
    else{
      alert("Failed to verify reCAPTCHA. Please try again.")
    }
  }
  private apiUrl = environment.loginurl;
  login() {
    // if (this.captchaVerified) {
      this.loading = true;
      const formData = new HttpParams()
        .set('grant_type', 'password')
        .set('username', this.loginForm.value.username)
        .set('password', this.loginForm.value.password);
  
      this.http
        // .post<any>('http://172.16.7.25/o/auth2/login/', formData.toString(), {
        // .post<any>('https://accounts.msd.atomatiki.tech/o/auth2/login/', formData.toString(), {
        .post<any>(`${this.apiUrl}o/auth2/login/`, formData.toString(), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .subscribe(
          (response) => {
            console.log('Access Token:', response.access_token); 
            console.log('User_id:', response.user_id);
            console.log('Responses:', response);
  
            localStorage.setItem('access_token', response.access_token); 
            localStorage.setItem('user_id', response.user_id); 
  
            // Check if 2FA is enabled and redirect accordingly
            if (response['2fa_enabled']= true) {
              console.log('2fa_enabled:', response['2fa_enabled'])
             this.toastr.info('Verification Code has been sent to your Email,Please Enter code here')
              // this.router.navigate(['/dashboard']);
              this.router.navigate(['/2faverification']);
            } else {
              this.toastr.success('Success')
              this.router.navigate(['/dashboard']);
            }
  
            this.loading = false;
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
          
            // Check if the error object contains the error message
            if (error.error && error.error.error) {
              // Display the error message from the backend
              this.toastr.error(error.error.error_description);
              // alert(error.error.error);
            } else {
              // Fallback to a generic error message
             this.toastr.error('An unexpected error occurred. Please try again.');
              // alert('An unexpected error occurred. Please try again.');
            }
          
            console.error('Login failed', error);
          }
        );
    // } else {
    //   this.toastr.error("Failed to verify reCAPTCHA. Please try again.");
    // }
  }
  
 reset(){
  this.router.navigate(['/reset'])
 }
//  loadRecaptcha() {
//   grecaptcha.ready(() => {
//     grecaptcha.execute('6Ld8pEwqAAAAAG9ZO9eZ4Lxhv6Pfm0bN08Ge0-Xc', { action: 'login' }).then((token) => {
//       this.captchaToken = token;
//     });
//   });
// }
  // email: string = '';
  // password: string = '';

  // constructor(private authService: AuthService, private router: Router) {}

  // onLogin(): void {
  //   this.router.navigate(['/dashboard']);
  // }

 
  

  

}
