import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-auditorders-details',
  standalone: true,
  imports: [TopNavbarComponent,CommonModule,LoadingSpinnerComponent],
  templateUrl: './auditorders-details.component.html',
  styleUrl: './auditorders-details.component.css'
})
export class AuditordersDetailsComponent implements OnInit {

 orderNo!: string;  
  orderDetails: any; 
  deliveredItems: any; 
  loading = false; 

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService) {}

  ngOnInit(): void {
    this.loading = true;
    // Get the order number from the route
    this.orderNo = this.route.snapshot.paramMap.get('orderNo')!;
    
    // Fetch the order details
    this.getOrderDetails();
    
   
  }
  private apiUrl = environment.apiEndpoint;
  getOrderDetails() {
    const orderDetailsEndpoint = `${this.apiUrl}orders/api/v1/facility_orders/${this.orderNo}/`;
    this.http.get(orderDetailsEndpoint).subscribe(
      (data: any) => {
     

        this.orderDetails = data;
        this.loading=false;
      
    }, (error: HttpErrorResponse) => {
     
    
      // Check if the error object contains the error message
      if (error.error && error.error.detail) {
        // Display the error message from the backend
        this.loading = false;
        this.toastr.error(error.error.detail);
        // alert(error.error.detail);
      } else {
        // Fallback to a generic error message
        this.toastr.error('Internal Server Error');
        this.loading=false;
        // alert('An unexpected error occurred. Please try again.');
      }
    
      console.error('Retrieve order failed', error);
    });
  }

 
  close() {
    this.router.navigate(['/audit/orders']);  
  }

}
