import { Component, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import jsPDF from 'jspdf';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-distribution-tofacility-report',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, FormsModule],
  templateUrl: './distribution-tofacility-report.component.html',
  styleUrl: './distribution-tofacility-report.component.css'
})
export class DistributionTofacilityReportComponent implements OnInit {
  fromDate: string = '';
  toDate: string = '';
  reportGenerated: boolean = false;
  reportData: any[] = [];

  constructor(){}

  ngOnInit(): void {
   this.reportData = [
    {
      zone: 'Zone 1',
      customerID: 'C001',
      customerName: 'Customer A',
      eLMISOrderNumber: 'E12345',
      msdOrderNumber: 'M12345',
      districts: 'District 1',
      region: 'Region A',
      deliveryDate: '2024-10-27',
      deliveryStatus: 'Delivered',
      itemCode: 'IC001',
      description: 'Item 1 Description',
      uom: 'Unit',
      revision: 'Rev 1',
      invoiceNumber: 'INV001',
      invoicedQuantity: 100,
      receivedQuantity: 95,
      returnedQuantity: 5,
      reason: 'Damaged',
      comment: 'Handled with care',
    }
   ];
  }

  generateReport() {
    if (this.fromDate && this.toDate) {
      // Here you can call your API to fetch data based on the date range
      // For now, we just display the report with dummy data
      this.reportGenerated = true;
    } else {
      alert('Please select a valid date range.');
    }
    
  }

  downloadPDF(){
    const doc = new jsPDF();

    // Report heading
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text('MEDICAL STORES DEPARTMENT', 105, 10, { align: 'center' });
    doc.text('Title: Distribution to Facility', 105, 20, { align: 'center' });
    doc.text('Purpose: To track movement of goods from one facility to another', 105, 30, { align: 'center' });

    // Table in PDF
    (doc as any).autoTable({
      margin: { top: 40 },
      head: [[
        'Zone (Plant)', 'Customer ID', 'Customer Name', 'eLMIS Order Number', 'MSD Order Number', 
        'Districts', 'Region', 'Delivery Date', 'Delivery Status', 'Item Code', 
        'Description', 'UOM', 'Revision', 'Invoice Number', 'Invoiced Quantity', 
        'Received Quantity', 'Returned Quantity', 'Reason', 'Comment'
      ]],
      body: this.reportData.map(item => [
        item.zone, item.customerID, item.customerName, item.eLMISOrderNumber, item.msdOrderNumber,
        item.districts, item.region, item.deliveryDate, item.deliveryStatus, item.itemCode,
        item.description, item.uom, item.revision, item.invoiceNumber, item.invoicedQuantity,
        item.receivedQuantity, item.returnedQuantity, item.reason, item.comment
      ]),
      styles: {
        fontSize: 8,
        // cellPadding: 3,
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: [40, 57, 101],
        textColor: 255,
        fontSize: 9,
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 20 },   // Zone (Plant)
        1: { cellWidth: 20 },   // Customer ID
        2: { cellWidth: 30 },   // Customer Name
        3: { cellWidth: 30 },   // eLMIS Order Number
        4: { cellWidth: 30 },   // MSD Order Number
        5: { cellWidth: 25 },   // Districts
        6: { cellWidth: 20 },   // Region
        7: { cellWidth: 25 },   // Delivery Date
        8: { cellWidth: 25 },   // Delivery Status
        9: { cellWidth: 20 },   // Item Code
        10: { cellWidth: 40 },  // Description
        11: { cellWidth: 15 },  // UOM
        12: { cellWidth: 15 },  // Revision
        13: { cellWidth: 30 },  // Invoice Number
        14: { cellWidth: 20 },  // Invoiced Quantity
        15: { cellWidth: 20 },  // Received Quantity
        16: { cellWidth: 20 },  // Returned Quantity
        17: { cellWidth: 20 },  // Reason
        18: { cellWidth: 30 },  // Comment
      },
      theme: 'grid',
      bodyStyles: { halign: 'center', valign: 'middle' },
    });

    doc.save('distribution-to-facility-report.pdf');
  }
}
