import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-create-budget',
  standalone: true,
  imports: [CommonModule,FormsModule,TopNavbarComponent],
  templateUrl: './create-budget.component.html',
  styleUrl: './create-budget.component.css'
})
export class CreateBudgetComponent {
  budgetPeriod: string = '';
  budgetDetails = {
    period: '2024/25',
    grantCode: '3454546',
    description: 'Purchase of health care equipment',
    amount: 100000000,
    currency: 'TZS'
  };

  retrieveBudget() {
    // Logic to retrieve budget details based on budgetPeriod
    // You can make an API call here if needed
  }

  saveBudget() {
    // Logic to save the budget details
    console.log('Budget saved:', this.budgetDetails);
  }

}
