import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-uom-dialog',
  standalone: true,
  imports: [CommonModule,MatDialogModule,
    MatInputModule,
    MatButtonModule,FormsModule],
  templateUrl: './uom-dialog.component.html',
  styleUrl: './uom-dialog.component.css'
})
export class UomDialogComponent {
  internalUom: string;

  constructor(
    public dialogRef: MatDialogRef<UomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient
  ) {
    this.internalUom = ''; // Initialize internal UOM
  }

  onUpdate() {
    const updateUrl = `https://api.msd.atomatiki.tech/configurations/api/v1/externaluom/${this.data.uom}`;
    this.http.put(updateUrl, { internaluom: this.internalUom }).subscribe(() => {
      this.dialogRef.close(this.internalUom);
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
