import { Injectable, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IdleDialogComponent } from './login/idle-dialog/idle-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private timeout: any;
  private dialogTimeout: any; // Secondary timer for the dialog
  private idleTime: number = 300; // 5 minutes in seconds
  private dialogIdleTime: number = 300;
  private isUserLoggedIn: boolean = false;

  constructor(
    private router: Router, 
    private dialog: MatDialog,
    private ngZone: NgZone,
    private http: HttpClient,
    private toastr: ToastrService,) {
    this.startListening();
    this.monitorRouteChanges();
  }

   // Start listening for user interactions and idle time
   private startListening() {
    this.ngZone.runOutsideAngular(() => {
      this.resetTimer();
      document.addEventListener('click', () => this.resetTimer());
      document.addEventListener('keydown', () => this.resetTimer());
      document.addEventListener('mousemove', () => this.resetTimer());
      document.addEventListener('scroll', () => this.resetTimer());
    });
  }

  // Reset the idle timer
  private resetTimer() {
    if (this.isUserLoggedIn) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.ngZone.run(() => this.openIdleDialog());
      }, this.idleTime * 1000);
    }
  }

  // Open the idle dialog and handle logout after a certain period
  private openIdleDialogOLD() {
    const dialogRef = this.dialog.open(IdleDialogComponent, {
      width: '300px',
      disableClose: true
    });

    // Set the dialog timeout to auto logout after the dialog time
    this.dialogTimeout = setTimeout(() => {
      this.ngZone.run(() => this.logout());
    }, this.dialogIdleTime * 1000);

    dialogRef.afterClosed().subscribe(result => {
      clearTimeout(this.dialogTimeout); // Clear the dialog timeout

      if (result === 'logout') {
        this.logout();
      } else {
        this.resetTimer(); // Reset the timer if user chooses to stay logged in
      }
    });
  }
  private openIdleDialog() {
    // Check the current route
    const currentRoute = this.router.url;
  
    // Only open the dialog if the current route is NOT '/login'
    if (currentRoute !== '/login') {
      const dialogRef = this.dialog.open(IdleDialogComponent, {
        width: '300px',
        disableClose: true
      });
  
      // Set the dialog timeout to auto logout after the dialog time
      this.dialogTimeout = setTimeout(() => {
        this.ngZone.run(() => this.logout());
      }, this.dialogIdleTime * 1000);
  
      dialogRef.afterClosed().subscribe(result => {
        clearTimeout(this.dialogTimeout); // Clear the dialog timeout
  
        if (result === 'logout') {
          this.logout();
        } else {
          this.resetTimer(); // Reset the timer if user chooses to stay logged in
        }
      });
    } else {
      // If the current route is '/login', simply reset the timer
      this.resetTimer();
    }
  }
  
   // private openIdleDialog() {
  //   const dialogRef = this.dialog.open(IdleDialogComponent, {
  //     width: '300px',
  //     disableClose: true
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result === 'logout') {
  //       this.router.navigate(['/login']); 
  //     } else {
  //       this.resetTimer();
  //     }
  //   });
  // }
  private logout() {
    // Fetch the token from local storage
    const token = localStorage.getItem('access_token');

    // If the token exists, make the logout request
    if (token) {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });

      // Make the HTTP request to the logout endpoint
      this.http.post('https://accounts.msd.atomatiki.tech/o/auth2/logout', {}, { headers }).subscribe(
        (response) => {
          this.toastr.success('User successfully logged out')
          console.log('User successfully logged out', response);

          // Remove token from local storage
          localStorage.removeItem('access_token');

          // Navigate to login page after successful logout
          this.router.navigate(['/login']);
        },
        (error) => {
          console.error('Error logging out', error);
          // Optionally handle errors, like showing an error message
          this.router.navigate(['/login']); // Redirect to login page even if error occurs
        }
      );
    } else {
      console.log('No access token found');
      this.router.navigate(['/login']); // Redirect to login if no token is found
    }
  }

    // Monitor route changes to determine when to stop/start the idle timer
    private monitorRouteChanges() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          const currentRoute = event.url;
  
          // Check if the user is on the login page
          if (currentRoute === '/login') {
            this.isUserLoggedIn = false;
            this.stopTimer(); // Stop idle timer on login page
          } else {
            this.isUserLoggedIn = true;
            this.resetTimer(); // Start/reset timer if the user is logged in
          }
        }
      });
    }
   // Stop the idle timer
   private stopTimer() {
    clearTimeout(this.timeout);
    clearTimeout(this.dialogTimeout);
    
  }
}
