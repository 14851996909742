<div *ngIf="mofGrantCode && msdGrantCode">
    <h4>Map MoF Grant Code to MSD ERP Grant Code</h4>
    <table class="table">
      <thead>
        <tr>
          <th>MoF Epicor Grant Code</th>
          <th>MSD ERP Grant Code</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ mofGrantCode.mofcode }}</td>
          <td>{{ msdGrantCode.transcode }}</td>
          <td>
            <button class="btn btn-success" (click)="saveMapping()">Save Mapping</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  