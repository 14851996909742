<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/users/category">Categories</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
      <!-- <button class="btn btn-success" (click)="openModel()">Add New Category</button> -->
      <div class="d-flex justify-content-between align-items-center mb-3">
        <!-- Search input on the left -->
        <input type="text" class="form-control w-50" placeholder="Search..."
          />
  
        <!-- Add New User button on the right -->
        <!-- <button class="btn btn-primary me-2" (click)="exportData()">Export</button> -->
        <button class="btn btn-success" (click)="openModel()">Add New Category</button>
  
      </div>
     
  
      <!-- User Table -->
      <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
      <table class="table table-striped table-bordered user-table">
        <thead>
          <tr>
            <th style="width: 10%;text-align: center;">S/N</th>
            <th style="width: 50%;text-align: center;">Category Name</th>

            <th style="width: 10%;text-align: center;">Actions</th>
          </tr>
        </thead>
        <tbody>
           
          <tr *ngFor="let category of categories; let i = index">
            <td style="text-align: center;">{{ i + 1 }}</td>
            <td style="text-align: center;">{{ category.category }}</td>
          
            <td style="text-align: center;">
              <!-- Action Buttons -->
              <i class="fas fa-eye" data-bs-toggle="tooltip" title="View" style="cursor: pointer;"></i>
              <i class="fas fa-edit mx-2" data-bs-toggle="tooltip" title="Edit" style="cursor: pointer;"></i>
              <i class="fas fa-trash mx-2" data-bs-toggle="tooltip" title="Delete" style="cursor: pointer;"></i>
             
            </td>
          </tr>
        </tbody>
      </table>
  
   
  
    </div>
  </div>

  <!-- Modal -->
<div class="modal " id="addCategoryModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addUserModalLabel">Add New Category</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeModel()"></button>
        </div>
        <div class="modal-body">
          <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
          <form [formGroup]="addCategoryForm" (ngSubmit)="onSubmit()">
            <div class="row mb-3">
              <div class="col-md-12">
                <label for="category" class="form-label">Category
                    <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" formControlName="category">
                <!-- <select formControlName="category" class="form-select">
                  <option value="">Select Category</option>
                  <option *ngFor="let category of categories" [value]="category.category">
                    {{ category.category }}
                  </option>
                </select> -->
              </div>
              

            </div>
  
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                (click)="closeModel()">Close</button>
              <button type="submit" class="btn btn-primary" [disabled]="addCategoryForm.invalid">Save</button>
            </div>
          </form>
  
        </div>
      </div>
    </div>
  </div>