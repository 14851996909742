import { Component, OnInit } from '@angular/core';
import { TopNavbarComponent } from '../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UsermngtService } from '../../modules/userManagement/usermngt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoadingSpinnerComponent } from '../../shared/loading/loading-spinner/loading-spinner.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [TopNavbarComponent,CommonModule,FormsModule,LoadingSpinnerComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {
  private apiUrl = environment.loginurl;
  showTOTP = false;
  showSMS = false;
  qrCode: string = '';
  totpToken: string = '';
  phoneNumber: string = '';
  showTokenInput: boolean = false; // For showing the token input field
  smsToken: string = '';
  user : any ={};
  loading = false;

  currentPassword = '';
  newPassword = '';
  confirmPassword = '';

  // State variables to manage toggle disabling
  disableSMSToggle: boolean = false;
  disableTOTPToggle: boolean = false;

  constructor(private userProfileService: UsermngtService, private http: HttpClient,private toastr: ToastrService,private router: Router) {}

  ngOnInit() {
    const userId = localStorage.getItem('user_id') || ''; 
   
    this.getUserProfile(userId);
  }

  editProfile() {
    console.log('Editing profile...');
    // Add logic to open a form or redirect to the edit profile page
  }

  changePassword() {
    this.loading = true;
    // if (this.newPassword !== this.confirmPassword) {
    //   console.error('Passwords do not match!');
    //   return;
    // }

    // console.log('Changing password...');
    if (this.newPassword !== this.confirmPassword) {
      this.toastr.info('New Password and Confirm Password do not match.')
      // alert("New Password and Confirm Password do not match.");
      this.loading=false;
      return;
    }

    const token = localStorage.getItem('access_token'); // Retrieve token from localStorage
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`, // Include token in headers
      'Content-Type': 'application/json'
    });

    const payload = {
      // current_password: this.currentPassword,
      new_password: this.newPassword,
      confirm_password: this.confirmPassword
    };

    this.http
      // .post('https://accounts.msd.atomatiki.tech/usermgt/api/v1/user-change-password', payload, { headers })
      .post(`${this.apiUrl}usermgt/api/v1/user-change-password`, payload, { headers })
      .subscribe(
        (response) => {
          this.loading=false;
          console.log('Password changed successfully:', response);
          this.toastr.success('Password changed successfully!')
          this.logout();
          // alert('Password changed successfully!');
           // Clear the form data after successful password change
          //  this.currentPassword = '';
          //  this.newPassword = '';
          //  this.confirmPassword = '';
        },
        (error) => {
          console.error('Error changing password:', error);
          this.loading=false;
          this.toastr.error('Failed to change password. Please try again.');
        }
      );
  }
  getUserProfile(userId: string) {
    this.userProfileService.getUserProfile(userId).subscribe(
      (response) => {
        this.user = response;
        this.phoneNumber = response.phone;
        console.log('User profile:', this.user);
      },
      (error) => {
        console.error('Error fetching user profile:', error);
      }
    );
  }

   // Toggle for TOTP authentication
   onToggleTOTP(event: any) {
    if (event.target.checked) {
      this.showTOTP = true;
    
      this.enableTOTP();
    } else {
      this.showTOTP = false;
    }
  }

  // Toggle for SMS authentication
  onToggleSMS(event: any) {
    if (event.target.checked) {
      this.showSMS = true;
    
    } else {
      this.showSMS = false;
    }
  }

   // Enable TOTP 2FA
   enableTOTP() {
    const token = localStorage.getItem('access_token'); // Assuming token is stored in localStorage
    const url = `https://accounts.msd.atomatiki.tech/usermgt/api/v1/enable-totp-2fa/`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.post<any>(url, {}, { headers }).subscribe(
      (response) => {
            // Combine the base URL with the relative qr_code_url from the response
            const baseUrl = 'https://accounts.msd.atomatiki.tech';
            this.qrCode =  response.qr_code;
            // this.qrCode = baseUrl + response.qr_code_url;
            console.log('qr code response:', this.qrCode);
      },
      (error) => {
        console.error('Failed to enable TOTP:', error);
      }
    );
  }

  // Verify TOTP token
  verifyTOTP() {
    const token = localStorage.getItem('access_token');
    const url = `https://accounts.msd.atomatiki.tech/usermgt/api/v1/verify-2fa/`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.post<any>(url, { token: this.totpToken }, { headers }).subscribe(
      (response) => {
        alert('TOTP verified successfully!');
      },
      (error) => {
        console.error('Failed to verify TOTP:', error);
      }
    );
  }

    // Verify SMS
    enableSMS() {
      const token = localStorage.getItem('access_token');
      const url = `https://accounts.msd.atomatiki.tech/usermgt/api/v1/enable-sms-2fa/`;
  
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
  
      this.http.post<any>(url, { phone_number: this.phoneNumber }, { headers }).subscribe(
        (response) => {
          alert('Phone number verified successfully! Token sent to your phone.');
          console.log('enable sms response:', response);
          // Show the token input field for the user to enter the token
          this.showTokenInput = true;
          this.showSMS = false;
        },
        (error) => {
          console.error('Failed to enable SMS 2FA:', error);
          alert('Failed to enable SMS 2FA');
        }
      );
    }
    verifyTokenSMS() {
      const token = localStorage.getItem('access_token');
      const url = `https://accounts.msd.atomatiki.tech/usermgt/api/v1/verify-2fa/`; 
  
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
  
      this.http.post<any>(url, { token: this.smsToken }, { headers }).subscribe(
        (response) => {
          alert('Token verified successfully! 2FA is enabled.');
          this.showTokenInput = false;
        },
        (error) => {
          console.error('Failed to verify token:', error);
          alert('Invalid token. Please try again.');
        }
      );
    }
    private logout() {
      // Fetch the token from local storage
      const token = localStorage.getItem('access_token');
  
      // If the token exists, make the logout request
      if (token) {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
  
        // Make the HTTP request to the logout endpoint
        this.http.post('https://accounts.msd.atomatiki.tech/o/auth2/logout', {}, { headers }).subscribe(
          (response) => {
            this.toastr.success('User successfully logged out')
            console.log('User successfully logged out', response);
  
            // Remove token from local storage
            localStorage.removeItem('access_token');
  
            // Navigate to login page after successful logout
            this.router.navigate(['/login']);
          },
          (error) => {
            console.error('Error logging out', error);
            // Optionally handle errors, like showing an error message
            this.router.navigate(['/login']); // Redirect to login page even if error occurs
          }
        );
      } else {
        console.log('No access token found');
        this.router.navigate(['/login']); // Redirect to login if no token is found
      }
    }
}
