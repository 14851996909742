import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ExternalPoService {
  private url = 'https://api.msd.atomatiki.tech/orders/api/v1/api/v1/externalpos'
  // private url = 'https://api.msd.atomatiki.tech/orders/api/v1/po_list'


  constructor(private http: HttpClient) { }

  getExternalPo(): Observable<any> {
    return this.http.get<any>(this.url);
  }
}
