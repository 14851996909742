import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private apiUrl = environment.apiEndpoint;
//  private apiUrl = 'https://api.msd.atomatiki.tech/configurations/api/v1/';

  constructor(private http: HttpClient) { }

   //method to retrieve programs
   getPrograms(): Observable<any> {
    // Retrieve the access token from localStorage
    const token = localStorage.getItem('access_token');

    // If token exists, create headers and append token to the Authorization header
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
   // Attach headers to the HTTP GET request
   return this.http.get<any>(`${this.apiUrl}configurations/api/v1/program/`, { headers });
  }

     //method to retrieve source bankacc
     getSource(): Observable<any> {
      // Retrieve the access token from localStorage
      const token = localStorage.getItem('access_token');
  
      // If token exists, create headers and append token to the Authorization header
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
     // Attach headers to the HTTP GET request
     return this.http.get<any>(`${this.apiUrl}configurations/api/v1/sourceoffund/`, { headers });
    }

      //method to retrieve  bankacc
      getBankAccount(): Observable<any> {
        // Retrieve the access token from localStorage
        const token = localStorage.getItem('access_token');
    
        // If token exists, create headers and append token to the Authorization header
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
       // Attach headers to the HTTP GET request
       return this.http.get<any>(`${this.apiUrl}configurations/api/v1/bankacc/`, { headers });
      }

      // searchProducts(term: string): Observable<any> {
      //   return this.http.get(`https://api.msd.atomatiki.tech/configurations/api/v1/product/search?q=${term}`);
      // }
    
      // getInitialProducts(): Observable<any> {
      //   return this.http.get(`https://api.msd.atomatiki.tech/configurations/api/v1/product`);
      // }

}
