import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'; 
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-confirm-passwordlink',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,],
  templateUrl: './confirm-passwordlink.component.html',
  styleUrl: './confirm-passwordlink.component.css'
})
export class ConfirmPasswordlinkComponent implements OnInit {
  showNewPassword = false;
  showConfirmPassword = false;
  uid64!: string;
  token!: string;

  passwordForm: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute) {
    this.passwordForm = this.fb.group({
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.uid64 = params['uid64'];
      this.token = params['token'];

      console.log('UID64:', this.uid64);
      console.log('Token:', this.token);

      // Now you can use the uid64 and token to send them to the backend
      // and handle the password reset process
    });
  
  }
 

  showNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      console.log('Form Submitted!', this.passwordForm.value);
    }
  }

}
