<!-- <app-layouts></app-layouts> -->


    <app-top-navbar></app-top-navbar>

    <div class="content-area">

        <p>Accounts > Create New Receipt</p>
        <div class="form-container">
            <div class="form-section">
              <h4>Background Information</h4>
              <div class="form-group">
                <label for="grantCode">Grant Code</label>
                <input id="grantCode" type="text" [(ngModel)]="grantCode" />
              </div>
              <div class="form-group">
                <label for="grantCycle">Grant Cycle</label>
                <input id="grantCycle" type="text" [(ngModel)]="grantCycle" />
              </div>
              <div class="form-group">
                <label for="program">Program</label>
                <input id="program" type="text" [(ngModel)]="program" />
              </div>
              <div class="form-group">
                <label for="msdLegalNumber">MSD Legal Number</label>
                <input id="msdLegalNumber" type="text" [(ngModel)]="msdLegalNumber" />
              </div>
              <div class="form-group">
                <label for="purpose">Purpose</label>
                <input id="purpose" type="text" [(ngModel)]="purpose" />
              </div>
            </div>
          
            <div class="form-section">
              <h4>Receipt Details</h4>
              <div class="form-group">
                <label for="referenceNumber">Reference Number</label>
                <input id="referenceNumber" type="text" [(ngModel)]="referenceNumber" />
              </div>
              <div class="form-group">
                <label for="receiptDate">Receipt Date</label>
                <input id="receiptDate" type="date" [(ngModel)]="receiptDate" />
              </div>
              <div class="form-group">
                <label for="receivedFrom">Received From</label>
                <input id="receivedFrom" type="text" [(ngModel)]="receivedFrom" />
              </div>
              <div class="form-group">
                <label for="bankAccount">Bank Account</label>
                <input id="bankAccount" type="text" [(ngModel)]="bankAccount" />
              </div>
              <div class="form-group">
                <label for="paymentMethod">Payment Method</label>
                <input id="paymentMethod" type="text" [(ngModel)]="paymentMethod" />
              </div>
              <div class="form-group">
                <label for="amount">Amount</label>
                <input id="amount" type="text" [(ngModel)]="amount" />
              </div>
              <div class="form-group">
                <label for="currency">Currency</label>
                <input id="currency" type="text" [(ngModel)]="currency" />
              </div>
              <div class="form-group">
                <label for="comments">Comments</label>
                <input id="comments" type="text" [(ngModel)]="comments" />
              </div>
            </div>
          </div>
          
          <div class="form-submit">
            <button type="submit" class="submit-button">Submit</button>
          </div>
          
    </div>
