import { Injectable, ɵɵdefineInjectable, EventEmitter, Component, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, ElementRef, Directive, ViewContainerRef, Input, Output, TemplateRef, HostBinding, HostListener, NgModule } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { isBs3 } from 'ngx-bootstrap/utils';
import { style, animate, AnimationBuilder } from '@angular/animations';
import { PositioningService } from 'ngx-bootstrap/positioning';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Default dropdown configuration
 */
class BsDropdownConfig {
  constructor() {
    /**
     * default dropdown auto closing behavior
     */
    this.autoClose = true;
    /**
     * default dropdown auto closing behavior
     */
    this.insideClick = false;
    /**
     * turn on/off animation
     */
    this.isAnimated = false;
  }
}
BsDropdownConfig.decorators = [{
  type: Injectable,
  args: [{
    providedIn: 'root'
  }]
}];
/** @nocollapse */
BsDropdownConfig.ɵprov = ɵɵdefineInjectable({
  factory: function BsDropdownConfig_Factory() {
    return new BsDropdownConfig();
  },
  token: BsDropdownConfig,
  providedIn: "root"
});
if (false) {
  /**
   * default dropdown auto closing behavior
   * @type {?}
   */
  BsDropdownConfig.prototype.autoClose;
  /**
   * default dropdown auto closing behavior
   * @type {?}
   */
  BsDropdownConfig.prototype.insideClick;
  /**
   * turn on/off animation
   * @type {?}
   */
  BsDropdownConfig.prototype.isAnimated;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class BsDropdownState {
  constructor() {
    this.direction = 'down';
    this.isOpenChange = new EventEmitter();
    this.isDisabledChange = new EventEmitter();
    this.toggleClick = new EventEmitter();
    this.dropdownMenu = new Promise(
    /**
    * @param {?} resolve
    * @return {?}
    */
    resolve => {
      this.resolveDropdownMenu = resolve;
    });
  }
}
BsDropdownState.decorators = [{
  type: Injectable
}];
/** @nocollapse */
BsDropdownState.ctorParameters = () => [];
if (false) {
  /** @type {?} */
  BsDropdownState.prototype.direction;
  /** @type {?} */
  BsDropdownState.prototype.autoClose;
  /** @type {?} */
  BsDropdownState.prototype.insideClick;
  /** @type {?} */
  BsDropdownState.prototype.isAnimated;
  /** @type {?} */
  BsDropdownState.prototype.isOpenChange;
  /** @type {?} */
  BsDropdownState.prototype.isDisabledChange;
  /** @type {?} */
  BsDropdownState.prototype.toggleClick;
  /**
   * Content to be displayed as popover.
   * @type {?}
   */
  BsDropdownState.prototype.dropdownMenu;
  /** @type {?} */
  BsDropdownState.prototype.resolveDropdownMenu;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
const DROPDOWN_ANIMATION_TIMING = '220ms cubic-bezier(0, 0, 0.2, 1)';
/** @type {?} */
const dropdownAnimation = [style({
  height: 0,
  overflow: 'hidden'
}), animate(DROPDOWN_ANIMATION_TIMING, style({
  height: '*',
  overflow: 'hidden'
}))];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class BsDropdownContainerComponent {
  /**
   * @param {?} _state
   * @param {?} cd
   * @param {?} _renderer
   * @param {?} _element
   * @param {?} _builder
   */
  constructor(_state, cd, _renderer, _element, _builder) {
    this._state = _state;
    this.cd = cd;
    this._renderer = _renderer;
    this._element = _element;
    this.isOpen = false;
    this._factoryDropDownAnimation = _builder.build(dropdownAnimation);
    this._subscription = _state.isOpenChange.subscribe(
    /**
    * @param {?} value
    * @return {?}
    */
    value => {
      this.isOpen = value;
      /** @type {?} */
      const dropdown = this._element.nativeElement.querySelector('.dropdown-menu');
      this._renderer.addClass(this._element.nativeElement.querySelector('div'), 'open');
      if (dropdown && !isBs3()) {
        this._renderer.addClass(dropdown, 'show');
        if (dropdown.classList.contains('dropdown-menu-right')) {
          this._renderer.setStyle(dropdown, 'left', 'auto');
          this._renderer.setStyle(dropdown, 'right', '0');
        }
        if (this.direction === 'up') {
          this._renderer.setStyle(dropdown, 'top', 'auto');
          this._renderer.setStyle(dropdown, 'transform', 'translateY(-101%)');
        }
      }
      if (dropdown && this._state.isAnimated) {
        this._factoryDropDownAnimation.create(dropdown).play();
      }
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
  }
  /**
   * @return {?}
   */
  get direction() {
    return this._state.direction;
  }
  /**
   * \@internal
   * @param {?} el
   * @return {?}
   */
  _contains(el) {
    return this._element.nativeElement.contains(el);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
BsDropdownContainerComponent.decorators = [{
  type: Component,
  args: [{
    selector: 'bs-dropdown-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
      style: 'display:block;position: absolute;z-index: 1040'
    },
    template: `
    <div [class.dropup]="direction === 'up'"
         [class.dropdown]="direction === 'down'"
         [class.show]="isOpen"
         [class.open]="isOpen"><ng-content></ng-content>
    </div>
  `
  }]
}];
/** @nocollapse */
BsDropdownContainerComponent.ctorParameters = () => [{
  type: BsDropdownState
}, {
  type: ChangeDetectorRef
}, {
  type: Renderer2
}, {
  type: ElementRef
}, {
  type: AnimationBuilder
}];
if (false) {
  /** @type {?} */
  BsDropdownContainerComponent.prototype.isOpen;
  /**
   * @type {?}
   * @private
   */
  BsDropdownContainerComponent.prototype._factoryDropDownAnimation;
  /**
   * @type {?}
   * @private
   */
  BsDropdownContainerComponent.prototype._subscription;
  /**
   * @type {?}
   * @private
   */
  BsDropdownContainerComponent.prototype._state;
  /**
   * @type {?}
   * @private
   */
  BsDropdownContainerComponent.prototype.cd;
  /**
   * @type {?}
   * @private
   */
  BsDropdownContainerComponent.prototype._renderer;
  /**
   * @type {?}
   * @private
   */
  BsDropdownContainerComponent.prototype._element;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class BsDropdownDirective {
  /**
   * @param {?} _elementRef
   * @param {?} _renderer
   * @param {?} _viewContainerRef
   * @param {?} _cis
   * @param {?} _state
   * @param {?} _config
   * @param {?} _builder
   */
  constructor(_elementRef, _renderer, _viewContainerRef, _cis, _state, _config, _builder) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this._viewContainerRef = _viewContainerRef;
    this._cis = _cis;
    this._state = _state;
    this._config = _config;
    // todo: move to component loader
    this._isInlineOpen = false;
    this._subscriptions = [];
    this._isInited = false;
    // set initial dropdown state from config
    this._state.autoClose = this._config.autoClose;
    this._state.insideClick = this._config.insideClick;
    this._state.isAnimated = this._config.isAnimated;
    this._factoryDropDownAnimation = _builder.build(dropdownAnimation);
    // create dropdown component loader
    this._dropdown = this._cis.createLoader(this._elementRef, this._viewContainerRef, this._renderer).provide({
      provide: BsDropdownState,
      useValue: this._state
    });
    this.onShown = this._dropdown.onShown;
    this.onHidden = this._dropdown.onHidden;
    this.isOpenChange = this._state.isOpenChange;
  }
  /**
   * Indicates that dropdown will be closed on item or document click,
   * and after pressing ESC
   * @param {?} value
   * @return {?}
   */
  set autoClose(value) {
    this._state.autoClose = value;
  }
  /**
   * @return {?}
   */
  get autoClose() {
    return this._state.autoClose;
  }
  /**
   * Indicates that dropdown will be animated
   * @param {?} value
   * @return {?}
   */
  set isAnimated(value) {
    this._state.isAnimated = value;
  }
  /**
   * @return {?}
   */
  get isAnimated() {
    return this._state.isAnimated;
  }
  /**
   * This attribute indicates that the dropdown shouldn't close on inside click when autoClose is set to true
   * @param {?} value
   * @return {?}
   */
  set insideClick(value) {
    this._state.insideClick = value;
  }
  /**
   * @return {?}
   */
  get insideClick() {
    return this._state.insideClick;
  }
  /**
   * Disables dropdown toggle and hides dropdown menu if opened
   * @param {?} value
   * @return {?}
   */
  set isDisabled(value) {
    this._isDisabled = value;
    this._state.isDisabledChange.emit(value);
    if (value) {
      this.hide();
    }
  }
  /**
   * @return {?}
   */
  get isDisabled() {
    return this._isDisabled;
  }
  /**
   * Returns whether or not the popover is currently being shown
   * @return {?}
   */
  get isOpen() {
    if (this._showInline) {
      return this._isInlineOpen;
    }
    return this._dropdown.isShown;
  }
  /**
   * @param {?} value
   * @return {?}
   */
  set isOpen(value) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }
  /**
   * @return {?}
   */
  get isBs4() {
    return !isBs3();
  }
  /**
   * @private
   * @return {?}
   */
  get _showInline() {
    return !this.container;
  }
  /**
   * @return {?}
   */
  ngOnInit() {
    // fix: seems there are an issue with `routerLinkActive`
    // which result in duplicated call ngOnInit without call to ngOnDestroy
    // read more: https://github.com/valor-software/ngx-bootstrap/issues/1885
    if (this._isInited) {
      return;
    }
    this._isInited = true;
    // attach DOM listeners
    this._dropdown.listen({
      // because of dropdown inline mode
      outsideClick: false,
      triggers: this.triggers,
      show: (
      /**
      * @return {?}
      */
      () => this.show())
    });
    // toggle visibility on toggle element click
    this._subscriptions.push(this._state.toggleClick.subscribe(
    /**
    * @param {?} value
    * @return {?}
    */
    value => this.toggle(value)));
    // hide dropdown if set disabled while opened
    this._subscriptions.push(this._state.isDisabledChange.pipe(filter(
    /**
    * @param {?} value
    * @return {?}
    */
    value => value)).subscribe(
    /**
    * @param {?} value
    * @return {?}
    */
    value => this.hide()));
  }
  /**
   * Opens an element’s popover. This is considered a “manual” triggering of
   * the popover.
   * @return {?}
   */
  show() {
    if (this.isOpen || this.isDisabled) {
      return;
    }
    if (this._showInline) {
      if (!this._inlinedMenu) {
        this._state.dropdownMenu.then(
        /**
        * @param {?} dropdownMenu
        * @return {?}
        */
        dropdownMenu => {
          this._dropdown.attachInline(dropdownMenu.viewContainer, dropdownMenu.templateRef);
          this._inlinedMenu = this._dropdown._inlineViewRef;
          this.addBs4Polyfills();
          this._renderer.addClass(this._inlinedMenu.rootNodes[0].parentNode, 'open');
          this.playAnimation();
        })
        // swallow errors
        .catch();
      }
      this.addBs4Polyfills();
      this._isInlineOpen = true;
      this.onShown.emit(true);
      this._state.isOpenChange.emit(true);
      this.playAnimation();
      return;
    }
    this._state.dropdownMenu.then(
    /**
    * @param {?} dropdownMenu
    * @return {?}
    */
    dropdownMenu => {
      // check direction in which dropdown should be opened
      /** @type {?} */
      const _dropup = this.dropup || typeof this.dropup !== 'undefined' && this.dropup;
      this._state.direction = _dropup ? 'up' : 'down';
      /** @type {?} */
      const _placement = this.placement || (_dropup ? 'top start' : 'bottom start');
      // show dropdown
      this._dropdown.attach(BsDropdownContainerComponent).to(this.container).position({
        attachment: _placement
      }).show({
        content: dropdownMenu.templateRef,
        placement: _placement
      });
      this._state.isOpenChange.emit(true);
    })
    // swallow error
    .catch();
  }
  /**
   * Closes an element’s popover. This is considered a “manual” triggering of
   * the popover.
   * @return {?}
   */
  hide() {
    if (!this.isOpen) {
      return;
    }
    if (this._showInline) {
      this.removeShowClass();
      this.removeDropupStyles();
      this._isInlineOpen = false;
      this.onHidden.emit(true);
    } else {
      this._dropdown.hide();
    }
    this._state.isOpenChange.emit(false);
  }
  /**
   * Toggles an element’s popover. This is considered a “manual” triggering of
   * the popover. With parameter <code>true</code> allows toggling, with parameter <code>false</code>
   * only hides opened dropdown. Parameter usage will be removed in ngx-bootstrap v3
   * @param {?=} value
   * @return {?}
   */
  toggle(value) {
    if (this.isOpen || !value) {
      return this.hide();
    }
    return this.show();
  }
  /**
   * \@internal
   * @param {?} event
   * @return {?}
   */
  _contains(event) {
    return this._elementRef.nativeElement.contains(event.target) || this._dropdown.instance && this._dropdown.instance._contains(event.target);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    // clean up subscriptions and destroy dropdown
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
    this._dropdown.dispose();
  }
  /**
   * @private
   * @return {?}
   */
  addBs4Polyfills() {
    if (!isBs3()) {
      this.addShowClass();
      this.checkRightAlignment();
      this.addDropupStyles();
    }
  }
  /**
   * @private
   * @return {?}
   */
  playAnimation() {
    if (this._state.isAnimated && this._inlinedMenu) {
      setTimeout(
      /**
      * @return {?}
      */
      () => {
        this._factoryDropDownAnimation.create(this._inlinedMenu.rootNodes[0]).play();
      });
    }
  }
  /**
   * @private
   * @return {?}
   */
  addShowClass() {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      this._renderer.addClass(this._inlinedMenu.rootNodes[0], 'show');
    }
  }
  /**
   * @private
   * @return {?}
   */
  removeShowClass() {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      this._renderer.removeClass(this._inlinedMenu.rootNodes[0], 'show');
    }
  }
  /**
   * @private
   * @return {?}
   */
  checkRightAlignment() {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      /** @type {?} */
      const isRightAligned = this._inlinedMenu.rootNodes[0].classList.contains('dropdown-menu-right');
      this._renderer.setStyle(this._inlinedMenu.rootNodes[0], 'left', isRightAligned ? 'auto' : '0');
      this._renderer.setStyle(this._inlinedMenu.rootNodes[0], 'right', isRightAligned ? '0' : 'auto');
    }
  }
  /**
   * @private
   * @return {?}
   */
  addDropupStyles() {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      // a little hack to not break support of bootstrap 4 beta
      this._renderer.setStyle(this._inlinedMenu.rootNodes[0], 'top', this.dropup ? 'auto' : '100%');
      this._renderer.setStyle(this._inlinedMenu.rootNodes[0], 'transform', this.dropup ? 'translateY(-101%)' : 'translateY(0)');
      this._renderer.setStyle(this._inlinedMenu.rootNodes[0], 'bottom', 'auto');
    }
  }
  /**
   * @private
   * @return {?}
   */
  removeDropupStyles() {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      this._renderer.removeStyle(this._inlinedMenu.rootNodes[0], 'top');
      this._renderer.removeStyle(this._inlinedMenu.rootNodes[0], 'transform');
      this._renderer.removeStyle(this._inlinedMenu.rootNodes[0], 'bottom');
    }
  }
}
BsDropdownDirective.decorators = [{
  type: Directive,
  args: [{
    selector: '[bsDropdown],[dropdown]',
    exportAs: 'bs-dropdown',
    providers: [BsDropdownState],
    host: {
      '[class.dropup]': 'dropup',
      '[class.open]': 'isOpen',
      '[class.show]': 'isOpen && isBs4'
    }
  }]
}];
/** @nocollapse */
BsDropdownDirective.ctorParameters = () => [{
  type: ElementRef
}, {
  type: Renderer2
}, {
  type: ViewContainerRef
}, {
  type: ComponentLoaderFactory
}, {
  type: BsDropdownState
}, {
  type: BsDropdownConfig
}, {
  type: AnimationBuilder
}];
BsDropdownDirective.propDecorators = {
  placement: [{
    type: Input
  }],
  triggers: [{
    type: Input
  }],
  container: [{
    type: Input
  }],
  dropup: [{
    type: Input
  }],
  autoClose: [{
    type: Input
  }],
  isAnimated: [{
    type: Input
  }],
  insideClick: [{
    type: Input
  }],
  isDisabled: [{
    type: Input
  }],
  isOpen: [{
    type: Input
  }],
  isOpenChange: [{
    type: Output
  }],
  onShown: [{
    type: Output
  }],
  onHidden: [{
    type: Output
  }]
};
if (false) {
  /**
   * Placement of a popover. Accepts: "top", "bottom", "left", "right"
   * @type {?}
   */
  BsDropdownDirective.prototype.placement;
  /**
   * Specifies events that should trigger. Supports a space separated list of
   * event names.
   * @type {?}
   */
  BsDropdownDirective.prototype.triggers;
  /**
   * A selector specifying the element the popover should be appended to.
   * @type {?}
   */
  BsDropdownDirective.prototype.container;
  /**
   * This attribute indicates that the dropdown should be opened upwards
   * @type {?}
   */
  BsDropdownDirective.prototype.dropup;
  /**
   * Emits an event when isOpen change
   * @type {?}
   */
  BsDropdownDirective.prototype.isOpenChange;
  /**
   * Emits an event when the popover is shown
   * @type {?}
   */
  BsDropdownDirective.prototype.onShown;
  /**
   * Emits an event when the popover is hidden
   * @type {?}
   */
  BsDropdownDirective.prototype.onHidden;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._dropdown;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._isInlineOpen;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._inlinedMenu;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._isDisabled;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._subscriptions;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._isInited;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._factoryDropDownAnimation;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._elementRef;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._renderer;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._viewContainerRef;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._cis;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._state;
  /**
   * @type {?}
   * @private
   */
  BsDropdownDirective.prototype._config;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class BsDropdownMenuDirective {
  // tslint:disable:no-any
  /**
   * @param {?} _state
   * @param {?} _viewContainer
   * @param {?} _templateRef
   */
  constructor(_state, _viewContainer, _templateRef) {
    _state.resolveDropdownMenu({
      templateRef: _templateRef,
      viewContainer: _viewContainer
    });
  }
}
BsDropdownMenuDirective.decorators = [{
  type: Directive,
  args: [{
    selector: '[bsDropdownMenu],[dropdownMenu]',
    exportAs: 'bs-dropdown-menu'
  }]
}];
/** @nocollapse */
BsDropdownMenuDirective.ctorParameters = () => [{
  type: BsDropdownState
}, {
  type: ViewContainerRef
}, {
  type: TemplateRef
}];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class BsDropdownToggleDirective {
  /**
   * @param {?} _changeDetectorRef
   * @param {?} _dropdown
   * @param {?} _element
   * @param {?} _renderer
   * @param {?} _state
   */
  constructor(_changeDetectorRef, _dropdown, _element, _renderer, _state) {
    this._changeDetectorRef = _changeDetectorRef;
    this._dropdown = _dropdown;
    this._element = _element;
    this._renderer = _renderer;
    this._state = _state;
    this.isDisabled = null;
    this._subscriptions = [];
    // sync is open value with state
    this._subscriptions.push(this._state.isOpenChange.subscribe(
    /**
    * @param {?} value
    * @return {?}
    */
    value => {
      this.isOpen = value;
      if (value) {
        this._documentClickListener = this._renderer.listen('document', 'click',
        /**
        * @param {?} event
        * @return {?}
        */
        event => {
          if (this._state.autoClose && event.button !== 2 && !this._element.nativeElement.contains(event.target) && !(this._state.insideClick && this._dropdown._contains(event))) {
            this._state.toggleClick.emit(false);
            this._changeDetectorRef.detectChanges();
          }
        });
        this._escKeyUpListener = this._renderer.listen(this._element.nativeElement, 'keyup.esc',
        /**
        * @return {?}
        */
        () => {
          if (this._state.autoClose) {
            this._state.toggleClick.emit(false);
            this._changeDetectorRef.detectChanges();
          }
        });
      } else {
        this._documentClickListener();
        this._escKeyUpListener();
      }
    }));
    // populate disabled state
    this._subscriptions.push(this._state.isDisabledChange.subscribe(
    /**
    * @param {?} value
    * @return {?}
    */
    value => this.isDisabled = value || null));
  }
  /**
   * @return {?}
   */
  onClick() {
    if (this.isDisabled) {
      return;
    }
    this._state.toggleClick.emit(true);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    if (this._documentClickListener) {
      this._documentClickListener();
    }
    if (this._escKeyUpListener) {
      this._escKeyUpListener();
    }
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }
}
BsDropdownToggleDirective.decorators = [{
  type: Directive,
  args: [{
    selector: '[bsDropdownToggle],[dropdownToggle]',
    exportAs: 'bs-dropdown-toggle',
    host: {
      '[attr.aria-haspopup]': 'true'
    }
  }]
}];
/** @nocollapse */
BsDropdownToggleDirective.ctorParameters = () => [{
  type: ChangeDetectorRef
}, {
  type: BsDropdownDirective
}, {
  type: ElementRef
}, {
  type: Renderer2
}, {
  type: BsDropdownState
}];
BsDropdownToggleDirective.propDecorators = {
  isDisabled: [{
    type: HostBinding,
    args: ['attr.disabled']
  }],
  isOpen: [{
    type: HostBinding,
    args: ['attr.aria-expanded']
  }],
  onClick: [{
    type: HostListener,
    args: ['click', []]
  }]
};
if (false) {
  /** @type {?} */
  BsDropdownToggleDirective.prototype.isDisabled;
  /** @type {?} */
  BsDropdownToggleDirective.prototype.isOpen;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._subscriptions;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._documentClickListener;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._escKeyUpListener;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._changeDetectorRef;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._dropdown;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._element;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._renderer;
  /**
   * @type {?}
   * @private
   */
  BsDropdownToggleDirective.prototype._state;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class BsDropdownModule {
  // tslint:disable-next-line:no-any
  /**
   * @param {?=} config
   * @return {?}
   */
  static forRoot(config) {
    return {
      ngModule: BsDropdownModule,
      providers: [ComponentLoaderFactory, PositioningService, BsDropdownState]
    };
  }
}
BsDropdownModule.decorators = [{
  type: NgModule,
  args: [{
    declarations: [BsDropdownMenuDirective, BsDropdownToggleDirective, BsDropdownContainerComponent, BsDropdownDirective],
    exports: [BsDropdownMenuDirective, BsDropdownToggleDirective, BsDropdownDirective],
    entryComponents: [BsDropdownContainerComponent]
  }]
}];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { BsDropdownConfig, BsDropdownContainerComponent, BsDropdownDirective, BsDropdownMenuDirective, BsDropdownModule, BsDropdownState, BsDropdownToggleDirective };
