import { CommonModule } from '@angular/common';
import { Component, computed, Input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MatListModule} from '@angular/material/list'
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from '../menu-item/menu-item.component';

export type MenuItem = {
  icon:string;
  label: string;
  route?: string;
  subItems? : MenuItem[];
}

@Component({
  selector: 'app-custom-sidenav',
  standalone: true,
  imports: [CommonModule,MatListModule,MatIconModule,RouterModule,MenuItemComponent],
  templateUrl: './custom-sidenav.component.html',
  styleUrl: './custom-sidenav.component.css'
})
export class CustomSidenavComponent {

  sideNavCollapsed = signal(false);
  
  @Input() set collapsed(val: boolean) {
    this.sideNavCollapsed.set(val);
  }

  menuItems = signal<MenuItem[]>([
    {
      icon: 'dashboard',
      label: 'Dashboard',
      route: '/dashboard',
    },
  
    {
      icon: 'home',
      label: 'Facility Orders', 
      route: 'facility',
      subItems: [
        {
          label: 'Orders',
          icon: '',
          route: 'order'
        },
        // {
        //   label: 'Delivered',
        //   icon: '',
        //   route: 'delivery'
        // },
        {
          label: 'Consumption',
          icon: 'usage',
          route: 'consumption'
        },
     
        {
          label: 'Out of stock items',
          icon: '',
          route: 'outstock'
        },
       
      ]
    },
   
    {
      icon: 'shopping cart',
      label: 'VP Orders',
      route: 'order',
      subItems: [
        {
          label: 'External Orders',
          icon: '',
          route: 'externalpo'
        },
        {
          label: 'All Orders',
          icon: '',
          route: 'purchaseOrder'
        },
        // {
        //   label: 'Dummy PO',
        //   icon: '',
        //   route: 'dummyPo'
        // },
      ]
    },
    {
      icon: 'analytics',
      label: 'Accounts',
      route: 'account',
      subItems: [
        {
          label: 'Budget',
          icon: '',
          route: '/budget'
        },
        {
          label: 'Receipt',
          icon: '',
          route: '/receipt-details'
        },
        {
          label: 'Requisition',
          icon: '',
          route: '/purchase-requisition'
        },
        // {
        //   label: 'Payment Voucher',
        //   icon: '',
        //   route: '/payment-voucher'
        // },
        // {
        //   label: 'Voucher', 
        //   icon: '',
        //   route: '/vouch'
        // },
       
      
        // {
        //   label: 'Disbursement',
        //   icon: '',
        //   route: '/disbursement'
        // },
      ]
    },
    // {
    //   icon: 'warehouse',
    //   label: 'Stocks',
    //   route: 'order',
    //   subItems: [
    //     {
    //       label: 'Out stock',
    //       icon: '',
    //       route: '/stock'
    //     },
    //   ]
    // },
    {
      icon: 'settings',
      label: 'Configurations',
      route: '/configuration',
      subItems: [
        {
          label: 'Products',
          icon: '',
          route: 'product'
        },
        {
          label: 'External Products',
          icon: '',
          route: 'partnumber'
        },
        // {
        //   label: 'WAMBO Part Number',
        //   icon: '',
        //   route: 'partnumber'
        // },
        // {
        //   label: 'GDF Part Number',
        //   icon: '',
        //   route: 'gdfpartnumber'
        // },
        {
          label: 'Health Facilities',
          icon: '',
          route: 'facilities'
        },
    
        // {
        //   label: 'Accounting Codes',
        //   icon: '',
        //   route: 'accountingcode'
        // },
        {
          label: 'MoF Account Codes',
          icon: '',
          route: 'accountingcode'
        },
        {
          label: 'MSD Account Codes',
          icon: '',
          route: 'msdaccountingcode'
        },
        {
          label: 'Uom',
          icon: '',
          route: 'externaluom'
        },
      ]
    },
    {
      icon: 'people',
      label: 'User Management',
      route: '/users',
      subItems:[
        {
          label: 'Users',
          icon: '',
          route: 'list'
        },
        {
          label: 'Category',
          icon: '',
          route: 'category'
        },
        {
          label: 'Institution',
          icon: '',
          route: 'institution'
        },
        {
          label: 'Permissions',
          icon: '',
          route: 'permission'
        },
        {
          label: 'Roles',
          icon: '',
          route: 'role'
        },
      ]
    },
    {
      icon: 'analytics',
      label: 'Audit Trail',
      route: '/audit',
      subItems:[
        {
          label: 'Orders',
          icon: '',
          route: 'orders'
        },
        {
          label: 'Accounts',
          icon: '',
          route: 'accounts'
        },
        {
          label: 'Configurations',
          icon: '',
          route: 'configurations'
        },
      ]
    },
    {
      icon: 'report',
      label: 'Reports',
      route: '/report',
      subItems:[
        {
          label: 'Orders',
          icon: '',
          route: 'orders'
        },
        {
          label: 'Stocks',
          icon: '',
          route: 'stocks'
        },
        {
          label: 'Facilities',
          icon: '',
          route: 'facilities'
        },
        {
          label: 'Products',
          icon: '',
          route: 'products'
        },
        {
          label: 'Accounts',
          icon: '',
          route: 'accounts'
        },
      ]
    }
   
  ]);

  // profilePicture = computed(() => this.sideNavCollapsed() ? '32' : '100');

}
