import { Component, OnInit } from '@angular/core';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-stock-report',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, FormsModule],
  templateUrl: './stock-report.component.html',
  styleUrl: './stock-report.component.css'
})
export class StockReportComponent implements OnInit {
  fromDate: string = '';
  toDate: string = '';
  reportGenerated: boolean = false;
  reportData: any[] = [];

  constructor(){}

  ngOnInit(): void {
   this.reportData = [
    {
      plant: 'KAGERA',
      itemcode: '10010007',
      revision: 'AB',
      description: 'ABACAVIR SULPHATE 600MG + LAMVUDINE 500MG TABLETS',
      uom: '30TB',
      lotnumber: 'AB-30004-379737',
      batch: '170',
      quantity: '170',
      expirydate: '31-07-2025',
      mfgdate: '30-08-2022',
      shelf: '39.49',
      warehouse: 'KRDSP',
      binlocation: 'A1AA01A',
      wac: '19,856.20',
      value: '3,375,894.00'
    }
   ];
  }

  generateReport() {
    if (this.fromDate && this.toDate) {
      // Here you can call your API to fetch data based on the date range
      // For now, we just display the report with dummy data
      this.reportGenerated = true;
    } else {
      alert('Please select a valid date range.');
    }
    
  }

  downloadPDFOLD(){
    const doc = new jsPDF();

     // Report heading
     doc.setFontSize(12);
     doc.setFont("helvetica", "bold");
     doc.text('MEDICAL STORES DEPARTMENT (MSD)', 105, 10, { align: 'center' });
     doc.text('REPORT TITLE: DETAILED STOCK ON HAND PER PROGRAM', 105, 20, { align: 'center' });
     doc.text(`FROM: ${this.fromDate || '...'} TO: ${this.toDate || '...'}`, 105, 30, { align: 'center' });
     doc.text('Purpose: EACH TO VIEW AVAILABLE STOCK ON HAND ', 105, 40, { align: 'center' });
 
    (doc as any).autoTable({
      margin: {top: 50 },
      head: [['Plant', 'Item code', 'Revision', 'Item Description','UOM','Lot Number','Batch On Hand QTY','Available OnHand QTY','Expiry Date', 'Mfg Date','Shelf life (%)','Warehouse','Bin Location','WAC (Tshs)','Value(Tshs)']],
      body: this.reportData.map(data => [
        data.plant,
        data.itemcode,
        data.revision,
        data.description,
        data.uom,
        data.lotnumber,
        data.batch,
        data.quantity,
        data.expirydate,
        data.mfgdate,
        data.shelf,
        data.warehouse,
        data.binlocation,
        data.wac,
        data.value,
      ]),
    });

    doc.save('stock-onhand-per-program-report.pdf');
  }
  downloadPDF(){
    const doc = new jsPDF();

    // Report heading
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text('MEDICAL STORES DEPARTMENT (MSD)', 105, 10, { align: 'center' });
    doc.text('REPORT TITLE: DETAILED STOCK ON HAND PER PROGRAM', 105, 20, { align: 'center' });
    doc.text(`FROM: ${this.fromDate || '...'} TO: ${this.toDate || '...'}`, 105, 30, { align: 'center' });
    doc.text('Purpose: EACH TO VIEW AVAILABLE STOCK ON HAND', 105, 40, { align: 'center' });

    (doc as any).autoTable({
        margin: { top: 50 },
        head: [[
            'Plant', 'Item Code', 'Revision', 'Item Description', 'UOM', 
            'Lot Number', 'Batch On Hand QTY', 'Available On Hand QTY', 
            'Expiry Date', 'Mfg Date', 'Shelf Life (%)', 'Warehouse', 
            'Bin Location', 'WAC (Tshs)', 'Value (Tshs)'
        ]],
        body: this.reportData.map(data => [
            data.plant,
            data.itemcode,
            data.revision,
            data.description,
            data.uom,
            data.lotnumber,
            data.batch,
            data.quantity,
            data.expirydate,
            data.mfgdate,
            data.shelf,
            data.warehouse,
            data.binlocation,
            data.wac,
            data.value,
        ]),
        styles: {
            fontSize: 6,
            // cellPadding: 3,
            overflow: 'linebreak'
        },
        headStyles: {
            fillColor: [40, 57, 101],
            textColor: 255,
            fontSize: 6,
            halign: 'center',
            valign: 'middle'
        },
        columnStyles: {
            0: { cellWidth: 10 },   // Plant
            1: { cellWidth: 10 },   // Item Code
            2: { cellWidth: 9 },   // Revision
            3: { cellWidth: 20 },   // Item Description
            4: { cellWidth: 9 },   // UOM
            5: { cellWidth: 12 },   // Lot Number
            6: { cellWidth: 10 },   // Batch On Hand QTY
            7: { cellWidth: 13 },   // Available On Hand QTY
            8: { cellWidth: 12 },   // Expiry Date
            9: { cellWidth: 12 },   // Mfg Date
            10: { cellWidth: 10 },  // Shelf Life (%)
            11: { cellWidth: 10 },  // Warehouse
            12: { cellWidth: 12 },  // Bin Location
            13: { cellWidth: 12 },  // WAC (Tshs)
            14: { cellWidth: 14 },  // Value (Tshs)
        },
        theme: 'grid',
        bodyStyles: { halign: 'center', valign: 'middle' },
    });

    doc.save('stock-onhand-per-program-report.pdf');
}


}
