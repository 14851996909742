import { Component, OnInit } from '@angular/core';
// import { FormGroup } from '@angular/forms';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { MatTableModule } from '@angular/material/table';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';
import { UsermngtService } from '../usermngt.service';
import { response } from 'express';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-institution',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,FormsModule,MatTableModule,MainDashboardComponent,SidebarComponent,ReactiveFormsModule,LayoutsComponent, NgxIntlTelInputModule,LoadingSpinnerComponent],
  templateUrl: './institution.component.html',
  styleUrl: './institution.component.css'
})
export class InstitutionComponent implements OnInit {
  addInstitutionForm: FormGroup;
  institutions: any[] =[];
  loading = true;

  constructor(private fb: FormBuilder, private userService: UsermngtService, private toastr: ToastrService,) {
    this.addInstitutionForm = this.fb.group({
      institution: ['', Validators.required],
      institutionid: ['', Validators.required],

    });
  }
  ngOnInit(): void {
    this.addInstitutionForm = this.fb.group({
      institution: ['', Validators.required],
      institutionid: ['', Validators.required],
      
    });
    this.fetchInstitutions();

  }
  fetchInstitutions(){
    this.loading = true;
    this.userService.getInstitutions().subscribe(
      (response)=> {
      this.loading = false;
      this.institutions = response.results;
      console.log('institutions response', response);
      console.log('institutions ', this.institutions);
    },
    (error) => {
      this.toastr.error('Error fetching institutions:'+ error)
      this.loading=false;
      console.error('Error fetching institutions:', error);
    })
  }

  openModel() {
    const modalDiv = document.getElementById('addInstitutionModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'block';
    }
  }

  closeModel() {
    const modalDiv = document.getElementById('addInstitutionModal');
    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }
  onSubmit(){
    this.loading=true;
    if (this.addInstitutionForm.valid) {
      const selectedinstitution = this.addInstitutionForm.value.institution;
      console.log('Selected Category:', selectedinstitution);
      const categoryData = {
        institutionname: selectedinstitution,
        institutionid: this.addInstitutionForm.value.institutionid
      }
      this.userService.createInstitution(categoryData).subscribe(
        (response)=>{
          this.loading= false;
          this.toastr.success('Category created successfully');
          console.log('Category created successfully', response);
          this.fetchInstitutions();
          this.closeModel();
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
        
          // Check if the error object contains the error message
          if (error.error && error.error.error) {
            // Display the error message from the backend
            this.toastr.error(error.error.detail);
          } else {
            // Fallback to a generic error message
            this.toastr.error('An unexpected error occurred. Please try again.');
          }
        
          console.error('Category creation failed', error);
        }
      )
  }
  
}

}
