import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-idle-dialog',
  standalone: true,
  imports: [FormsModule,CommonModule, MatDialogModule,
    MatButtonModule],
  templateUrl: './idle-dialog.component.html',
  styleUrl: './idle-dialog.component.css'
})
export class IdleDialogComponent {

  constructor(public dialogRef: MatDialogRef<IdleDialogComponent>) {}

  onStay(): void {
    this.dialogRef.close('stay');
  }

  onLogout(): void {
    this.dialogRef.close('logout');
  }

}
