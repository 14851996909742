import { Component } from '@angular/core';

@Component({
  selector: 'app-transfer-gf-account',
  standalone: true,
  imports: [],
  templateUrl: './transfer-gf-account.component.html',
  styleUrl: './transfer-gf-account.component.css'
})
export class TransferGFAccountComponent {

}
