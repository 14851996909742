import { Component } from '@angular/core';

@Component({
  selector: 'app-summary-per-requisition',
  standalone: true,
  imports: [],
  templateUrl: './summary-per-requisition.component.html',
  styleUrl: './summary-per-requisition.component.css'
})
export class SummaryPerRequisitionComponent {

}
