import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';

@Component({
  selector: 'app-facility-delivered',
  standalone: true,
  imports: [ LayoutsComponent,TopNavbarComponent,SidebarComponent,CommonModule,MainDashboardComponent,
    FormsModule],
  templateUrl: './facility-delivered.component.html',
  styleUrl: './facility-delivered.component.css'
})
export class FacilityDeliveredComponent {
  orderNumber: string = '';
  orderDetails: any = null;

  searchOrder() {
    // Logic for fetching order details based on the order number entered
    // once an API works i will use 
    // this.http.get(`your-api-endpoint/orders/${this.orderNumber}`).subscribe(
    //   data => this.orderDetails = data,
    //   error => console.error('Error fetching order details', error)
    // );
    this.orderDetails = {
      facilityNumber: '10010007',
      orderNumber: '3454546',
      orderDate: '12/06/2024',
      partNumber: '10010007',
      itemDescription: 'ABACAVIR SULPHATE 600MG',
      expectedDeliveryDate: '20/06/2024',
      deliveryStatus: 'pending',
      uom: '30TB',
      quantity: 200,
      expectedUnitPrice: 5000,
      actualUnitPrice: 4500,
    };
  }

  submitSignature() {
    // Logic for handling the signature submission
    alert('Signature Submitted!');
  }

}
