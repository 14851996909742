import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { AccountingService } from '../../accounting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-receipt-details',
  standalone: true,
  imports: [TopNavbarComponent,CommonModule,ReactiveFormsModule,FormsModule],
  templateUrl: './receipt-details.component.html',
  styleUrl: './receipt-details.component.css'
})
export class ReceiptDetailsComponent implements OnInit {
  filters = {
    facility: '',
    orderNo: '',
    startDate: '',
    endDate: ''
  };

  receipts: any = [];
  currentPage = 1;
  totalPages = 0;
  pageSize = 10;

  constructor(
    private router: Router,
    private accountservice: AccountingService,
    private toastr: ToastrService
  ){}

  ngOnInit(): void {
   this.fetchReceipt(this.currentPage);
  }
  fetchReceipt(page: number){
    this.accountservice.getReceipt(page).subscribe(
      (response) =>{
        this.receipts = response.results;
        console.log('Receipt Data:', this.receipts);
      },
      (error) => {
        // alert('Error fetching disbursements:'+ error)
      this.toastr.error(error.error.message);
        console.error('Error fetching recept:', error);
      }
    );
  }
 // Pagination actions
 goToPreviousPage() {
  if (this.currentPage > 1) {
    this.currentPage--;
    // this.fetchUsers();
    this.fetchReceipt(this.currentPage);
  }
}

goToNextPage() {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
    // this.fetchUsers();
    this.fetchReceipt(this.currentPage);
  }
}
  NavigateToCreate(){
    this.router.navigate(['account/receipt'])
  }
  applyFilters() {

  }
  receiptDetails = [
    // Example data 
  
    {
      receiptno: '10000017893',
      bankaccountno: '015345670009245',
      receiptdate: new Date(),
      finyear: '2024/2025',
      paymethod: 'Bank',
      amount: '20000',
      currency: 'TZ',
      approvalletterrefno: 'NA',
    },
    {
      receiptno: '22000017893',
      bankaccountno: '015345670009267',
      receiptdate: new Date(),
      finyear: '2023/2024',
      paymethod: 'Bank',
      amount: '40000',
      currency: 'TZ',
      approvalletterrefno: 'NA',
    }
    // Add more items as needed
  ];

}
