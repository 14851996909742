import { Component } from '@angular/core';

@Component({
  selector: 'app-pp-report',
  standalone: true,
  imports: [],
  templateUrl: './pp-report.component.html',
  styleUrl: './pp-report.component.css'
})
export class PpReportComponent {

}
