import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  role: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsermngtService {
  private apiUrl = environment.usermgtEndpoint;
  // private apiUrl = 'https://accounts.msd.atomatiki.tech/usermgt/api/v1/';
  private profileapiurl = 'https://accounts.msd.atomatiki.tech/usermgt/api/v1/user-profile/';
  // private categoryUrl = 'https://accounts.msd.atomatiki.tech/usermgt/api/v1/category/';


  constructor(private http: HttpClient) { }


  getUsers(page: number): Observable<any> {
    // Retrieve the access token from localStorage
    const token = localStorage.getItem('access_token');

    // If token exists, create headers and append token to the Authorization header
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    // Attach headers to the HTTP GET request
    // return this.http.get<any>(`${this.apiUrl}user-accounts/?page=${page}`, { headers });
    return this.http.get<any>(`${this.apiUrl}usermgt/api/v1/user-accounts/?page=${page}`, { headers });
  }

  // Method to create a new user
  createUser(userData: any): Observable<any> {
    const token = localStorage.getItem('access_token');
    console.log('token to append in user_creation:', token);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });

    // Send the POST request with user data
    // return this.http.post<any>(`${this.apiUrl}create-user-account/`, userData, { headers });
    return this.http.post<any>(`${this.apiUrl}usermgt/api/v1/create-user-account/`, userData, { headers });
  }

  // Method to send email for password reset
  resetPassword(email: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(`${this.apiUrl}usermgt/api/v1/password-reset/`, { email }, { headers });
  }

  getUserProfile(userId: string): Observable<any> {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    // return this.http.get<any>(`${this.profileapiurl}${userId}/`, { headers });
    return this.http.get<any>(`${this.apiUrl}usermgt/api/v1/user-profile/${userId}/`, { headers });
  }
  //method to retrieve categories
  getCategories(): Observable<any> {
    // Retrieve the access token from localStorage
    const token = localStorage.getItem('access_token');

    // If token exists, create headers and append token to the Authorization header
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    // Attach headers to the HTTP GET request
    // return this.http.get<any>(`${this.apiUrl}category/`, { headers });
    return this.http.get<any>(`${this.apiUrl}usermgt/api/v1/category/`, { headers });
  }
  // Method to create a new category
  createCategory(categoryData: any): Observable<any> {
    const token = localStorage.getItem('access_token');
    console.log('token to append in user_creation:', token);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });

    // Send the POST request with user data
    return this.http.post<any>(`${this.apiUrl}usermgt/api/v1/category/`, categoryData, { headers });
  }
  // Method to create a new category
  unlockAccount(unlockAccountData: any): Observable<any> {
    const token = localStorage.getItem('access_token');
    console.log('token to append in unlock_account:', token);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });

    // Send the POST request with user email data
    return this.http.post<any>(`${this.apiUrl}usermgt/api/v1/unlock-account/`, unlockAccountData, { headers });
  }

  //Method for getting all institutions
  getInstitutions(): Observable<any> {
    // Retrieve the access token from localStorage
    const token = localStorage.getItem('access_token');

    // If token exists, create headers and append token to the Authorization header
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    // Attach headers to the HTTP GET request
    return this.http.get<any>(`${this.apiUrl}usermgt/api/v1/institution/`, { headers });
  }

  // Method to create a new institution
  createInstitution(institutionData: any): Observable<any> {
    const token = localStorage.getItem('access_token');
    console.log('token to append in user_creation:', token);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });

    // Send the POST request with user data
    return this.http.post<any>(`${this.apiUrl}usermgt/api/v1/institution/`, institutionData, { headers });
  }
}
