<!-- <app-layouts></app-layouts> -->

    
    <!-- <app-sidebar></app-sidebar> -->
    <div class="d">   
        <app-top-navbar></app-top-navbar>
        <div class="content-area ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/facility/consumption">Facilities</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Consumption</li>
                </ol>
            </nav>
            <!-- <p>Facilities > Orders</p> -->


        <!-- Filters for Date Range, Facility, and Order Number https://api.msd.atomatiki.tech/configurations/api/v1/facility/ -->
        <div class="filters">
            <form (ngSubmit)="applyFilters()">
                <div class="row">
                    <!-- Facility Filter -->
                    <!-- <div class="form-group col-md-6">
                        <label for="facility">Facility</label>
                        <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility"
                            class="form-control">
                    </div> -->
                    <div class="form-group col-md-6">
                        <label for="facility">Facility</label>
                        <ng-select
                          [items]="facilities"
                          bindLabel="facilityname" 
                          bindValue="facilitycode"  
                          [(ngModel)]="filters.facility"
                          placeholder="Select facility"
                          [searchable]="true"
                          [loading]="loadingFacilities"
                          [clearable]="true"
                          name="facility"
                          id="facility"
                          class="form-control">
                        </ng-select>
                      </div>
                    <!-- Order Number Filter -->
                    <div class="form-group col-md-6">
                        <label for="orderNo">Facility Number</label>
                        <input type="text" id="orderNo" [(ngModel)]="filters.facilityNo" name="facilityNo"
                            class="form-control">
                    </div>
                </div>

                <div class="row">
                    <!-- Start Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="startDate">Start Date</label>
                        <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate"
                            class="form-control">
                    </div>
                    <!-- End Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="endDate">End Date</label>
                        <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate"
                            class="form-control">
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="row">
                    <div class="form-group col-md-12">
                        <button type="submit" class="btn btn-primary">Apply Filters</button>
                    </div>
                </div>
            </form>
        </div>
           

            
            <div class="facility-table">
                <div class="d-flex justify-content-between mb-2">
                    <!-- Left Side: Export Buttons -->
                    <div class="d-flex">
                      <!-- PDF Export Button -->
                      <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
                        <i class="fas fa-file-pdf"></i>
                      </button>
                  
                      <!-- XLS Export Button -->
                      <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
                        <i class="fas fa-file-excel"></i>
                      </button>
                  
                      <!-- CSV Export Button -->
                      <button class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
                        <i class="fas fa-file-csv"></i>
                      </button>
                    </div>
                  
                    <!-- Right Side: Send All to Facility Button -->
                    <div>
                      <!-- <button class="btn btn-primary" style="background-color: #005590;">Send All to Facility</button> -->
                    </div>
                  </div>
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <!-- <pre>{{ orders[0].items[0].monthid | json  }}</pre> -->
                <table class="table table-striped purchase-table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Facility  Number</th>
                            <!-- <th>Facility Name</th> -->
                            <th>Facility Code</th>
                            <!-- <th>Reporting  Period</th> -->
                            <th>Submission Date</th>
                            <th>Items</th>
                            <!-- <th>Order Items</th> -->
                           
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders; let i = index">
                          
                            <td>{{ i + 1 }}</td>
                            <td>{{ order.facilityno }}</td>
                            <!-- <td>{{order.facility}}</td> -->
                            <td>{{order.facilitycode}}</td>
                            <!-- <td>{{ order.perioddate }}</td> -->
                            <td>{{ order.submissiondate }}</td> 
                            <td>{{ order.formattedItems }}</td>
                           
                            <td>
                                <button (click)="viewOrderConsumptionDetails(order.facilityno,order.monthid)" aria-label="View">
                                    <i class="fas fa-eye" data-bs-toggle="tooltip" title="Consumption Details"
                                        style="cursor: pointer;"></i>
                                    <!-- <i class="fas fa-eye"></i> -->
                                </button>
                                 
                               
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        
            <div class="pagination-controls d-flex align-items-center">
                <button (click)="loadPreviousPage()" [disabled]="currentPage === 1"
                    class="btn btn-warning">Previous</button>
                <!-- Display current page and total pages -->
                <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
                <button (click)="loadNextPage()" class="btn btn-success">Next</button>
            </div>
        </div>
    </div>
