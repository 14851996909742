import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

interface Codes {
  
  grandcode: string;
  description: string;
  module: string;
  costinput: string;
  intervention: string;
  msdcode: string;
 
}
@Injectable({
  providedIn: 'root'
})
export class AccountcodeserviceService {
  private url = 'https://api.msd.atomatiki.tech/configurations/api/v1/mofcode/' 

  constructor(private http: HttpClient) { }

  getAccountCode(): Observable<any> {
    return this.http.get<any>(this.url);
    }
}
