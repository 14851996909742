import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-order-delivered',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,LoadingSpinnerComponent],
  templateUrl: './order-delivered.component.html',
  styleUrl: './order-delivered.component.css'
})
export class OrderDeliveredComponent {
  orderNo!: string;  // The order number passed in the route
  loading= false;
  deliveredItems: any;  // To store delivered items

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.loading= true;
    // Get the order number from the route
    this.orderNo = this.route.snapshot.paramMap.get('orderNo')!;
    
    
    
    // Fetch the delivered items
    this.getDeliveredItems();
  }

  
  private apiUrl = environment.apiEndpoint;
  getDeliveredItems() {
    const deliveredItemsEndpoint = `${this.apiUrl}orders/api/v1/api/v1/pod/${this.orderNo}/`;
    this.http.get(deliveredItemsEndpoint).subscribe((data: any) => {
      if(data){

        this.deliveredItems = data; 
        this.loading=false;
      }else{
        alert("Something went wrong");
        this.loading=false;
      }
    });
  }
  close() {
    this.router.navigate(['/facility/order']);  
  }

}
