<app-top-navbar></app-top-navbar>
<div class="content-area ">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/receipt-details">Purchase Requisition</a></li>
      <li class="breadcrumb-item active" aria-current="page">List</li>
    </ol>
  </nav>
  <!-- <p>Accounts > Receipt</p>      -->
  <!-- Filters for Date Range, Facility, and Order Number -->
  <div class="filters">
    <form (ngSubmit)="applyFilters()">
      <!-- Filter Fields -->
      <div class="row">
        <div class="form-group col-md-6">
          <label for="ordername">Order Name</label>
          <input type="text" id="ordername" [(ngModel)]="filters.ordername" name="ordername" class="form-control">
        </div>
        <div class="form-group col-md-6">
          <label for="programid">Program</label>
          <input type="text" id="programid" [(ngModel)]="filters.programid" name="programid" class="form-control">
        </div>
        <div class="form-group col-md-6">
          <label for="startDate">Start Date</label>
          <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate" class="form-control">
        </div>
        <div class="form-group col-md-6">
          <label for="endDate">End Date</label>
          <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate" class="form-control">
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Apply Filters</button>
      <button type="button" class="btn btn-secondary" (click)="clearFilters()">Clear</button>
    </form>
  </div>


  <div class="d-flex justify-content-between mb-2">
    <!-- Left Side: Export Buttons -->
    <div class="d-flex">
      <!-- PDF Export Button -->
      <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
        <i class="fas fa-file-pdf"></i>
      </button>

      <!-- XLS Export Button -->
      <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
        <i class="fas fa-file-excel"></i>
      </button>

      <!-- CSV Export Button -->
      <button class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
        <i class="fas fa-file-csv"></i>
      </button>
    </div>

    <!-- Right Side: Send All to Facility Button -->
    <div>
      <button (click)="NavigateToCreate()" class="btn btn-success" style="background-color: #005590;">New
        Requisition</button>
    </div>
  </div>

  <table class="table table-striped purchase-table">
    <thead>
      <tr >
        <th>S/N</th>
        <th>Order Name</th>
        <th>Program</th>
        <th>Order Reference Number</th>
        <th>Approval Reference Number</th>
        <th>Amount</th>
        <th>Currency</th>
        <th>Grant Name</th>
        <th>Approval Status</th>
        <!-- <th>FinYear</th>
        <th>A/C From</th>
        <th>A/C To</th> -->
        <th>Date</th>
        <!-- <th>Currency</th> -->
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of disbursements;let i = index" [ngClass]="{'bg-red-200': !item.approvedby, 'bg-green-200': item.approvedby}">
        <td scope="row">{{ i + 1 }}</td>
        <td>{{ item.ordername }}</td>
        <td>{{ item.programid }}</td>
        <!-- <td>{{ item.receiptdate | date }}</td> -->
        <td>{{ item.orderletterrefno }}</td>
        <td>{{ item.approvalletterrefno }}</td>
        <td>{{ item.amount }}</td>
        <td>{{ item.currency }}</td>
        <td>{{ item.grantcycleid }}</td>
        <!-- <td>{{ item.finyear }}</td>
        <td>{{ item.fromaccountno }}</td>
        <td>{{ item.toaccountno }}</td> -->
        <td>
          <span *ngIf="!item.approvedby">Pending</span>
          <span *ngIf="item.approvedby">Approved</span>
        </td>
        <td>{{ item.transdate }}</td>
        <td>
          <button (click)="viewDetails(item.id)" aria-label="View">
            <i class="fas fa-eye" data-bs-toggle="tooltip" title="Details"
                style="cursor: pointer;"></i>
            <!-- <i class="fas fa-eye"></i> -->
        </button>
        <button (click)="openApproveModel(item.id)" aria-label="Forward">

          <i class="fas fa-check-double" data-bs-toggle="tooltip" title="Approve"
          style="cursor: pointer;"></i>
        </button>
          <!-- <button  aria-label="Edit">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button  aria-label="Forward">
                    <i class="fas fa-share"></i>
                  </button> -->
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Pagination controls -->
  <div class="pagination-controls">
    <button class="btn btn-warning" (click)="goToPreviousPage()" [disabled]="currentPage === 1">Previous</button>
    <span>Page {{ currentPage }} of {{ totalPages }}</span>
    <button class="btn btn-success" (click)="goToNextPage()" [disabled]="currentPage === totalPages">Next</button>
  </div>
</div>


<!-- Modal for approve form-->
<div class="modal " id="approveModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="approveModalLabel">Approve Disbursement</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeApproveModel()"></button>
      </div>
      <div class="modal-body">
       
        <form [formGroup]="approveForm" (ngSubmit)="approveDisbursement()">
          <div class="row mb-3">
            <div class="col-md-12">
              <label for="userid" class="form-label">approved By
                  <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" formControlName="approvedby" readonly>
            </div>
        
            <div class="col-md-12">
              <label>Approve Date<span class="text-danger">*</span></label>
              <input type="date" class="form-control" formControlName="approveddate">
            </div>
          </div>
        
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
              (click)="closeApproveModel()">Close</button>
            <button type="submit" class="btn btn-primary" [disabled]="approveForm.invalid">Approve</button>
          </div>
        </form>
        

      </div>
    </div>
  </div>
</div>