import { Component, OnInit } from '@angular/core';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-order-status',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, FormsModule],
  templateUrl: './order-status.component.html',
  styleUrl: './order-status.component.css'
})
export class OrderStatusComponent implements OnInit {
  fromDate: string = '';
  toDate: string = '';
  reportGenerated: boolean = false;
  reportData: any[] = [];

  constructor() { }
  ngOnInit(): void {
       // Dummy data for demonstration purposes
       this.reportData = [
        {
          countryShipTo: 'Tanzania, United Republic of',
          grantName: 'TZA-M-MOF',
          requisitionNumber: '11335',
          poNumber: '4162',
          itemDescription: 'Artesunate 60',
          quantity: '713,502',
          expectedDeliveryDate: '27/08/2021',
          uom: 'Unit',
          itemValue: '1,034,577.90'
        },
        {
          countryShipTo: 'Tanzania, United Republic of',
          grantName: 'TZA-M-MOF',
          requisitionNumber: '11335',
          poNumber: '4162',
          itemDescription: 'Artesunate 60',
          quantity: '237,834',
          expectedDeliveryDate: '08/06/2021',
          uom: 'Unit',
          itemValue: '344,859.30'
        }
      ];
  }

  generateReport() {
    if (this.fromDate && this.toDate) {
      // Here you can call your API to fetch data based on the date range
      // For now, we just display the report with dummy data
      this.reportGenerated = true;
    } else {
      alert('Please select a valid date range.');
    }
    
  }

  downloadPDF() {
    const doc = new jsPDF();

     // Report heading
     doc.setFontSize(12);
     doc.setFont("helvetica", "bold");
     doc.text('MEDICAL STORES DEPARTMENT (MSD)', 105, 10, { align: 'center' });
     doc.text('Report Title: ORDER STATUS FOR PRODUCT COMING THROUGH WAMBO', 105, 20, { align: 'center' });
     doc.text(`From: ${this.fromDate || '...'} To: ${this.toDate || '...'}`, 105, 30, { align: 'center' });
     doc.text('Purpose: Tracking all WAMBO Orders and Create MSD Purchase Order Numbers', 105, 40, { align: 'center' });
 


    // doc.text('Order Status Report', 10, 10);
    (doc as any).autoTable({
      margin: { top: 50 },
      head: [['Country Ship To', 'Grant Name', 'Requisition Number', 'PO Number', 'Item Description', 'Quantity', 'Expected Delivery Date', 'UoM', 'Item Value']],
      body: this.reportData.map(item => [
        item.countryShipTo,
        item.grantName,
        item.requisitionNumber,
        item.poNumber,
        item.itemDescription,
        item.quantity,
        item.expectedDeliveryDate,
        item.uom,
        item.itemValue,
      ]),
    });

    doc.save('order-status-report.pdf');
  }
}
