<!-- <app-layouts></app-layouts> -->


    <app-top-navbar></app-top-navbar>
   
    <div class="content-area">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="order/externalpo">VP Orders</a></li>
          <li class="breadcrumb-item active" aria-current="page">External Orders</li>
        </ol>
      </nav>
        <!-- <p>Orders > External POs</p> -->

          <!-- Filters for Date Range, Facility, and Order Number -->
          <div class="filters">
            <form (ngSubmit)="applyFilters()">
                <div class="row">
                    <!-- Facility Filter -->
                    <!-- <div class="form-group col-md-6">
                        <label for="facility">Facility</label>
                        <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility" class="form-control">
                    </div> -->
                    <!-- Order Number Filter -->
                    <div class="form-group col-md-6">
                        <label for="orderNo">Order Number</label>
                        <input type="text" id="orderNo" [(ngModel)]="filters.orderNo" name="orderNo" class="form-control">
                    </div>
                </div>
                <div class="row">
                  <!-- Facility Filter -->
                  <div class="form-group col-md-6">
                      <label for="facility">External System</label>
                      <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility" class="form-control">
                  </div>
                  <!-- Order Number Filter -->
                  <div class="form-group col-md-6">
                      <label for="orderNo">Surpplier</label>
                      <input type="text" id="orderNo" [(ngModel)]="filters.supplier" name="supplier" class="form-control">
                  </div>
              </div>
                
                <div class="row">
                    <!-- Start Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="startDate">Start Date</label>
                        <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate" class="form-control">
                    </div>
                    <!-- End Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="endDate">End Date</label>
                        <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate" class="form-control">
                    </div>
                </div>
        
                <!-- Submit Button -->
                <div class="row">
                    <div class="form-group col-md-12">
                        <button type="submit" class="btn btn-primary">Apply Filters</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="product-container">
          <div class="d-flex justify-content-between mb-2">
            <!-- Left Side: Export Buttons -->
            <div class="d-flex">
              <!-- PDF Export Button -->
              <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
                <i class="fas fa-file-pdf"></i>
              </button>
          
              <!-- XLS Export Button -->
              <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
                <i class="fas fa-file-excel"></i>
              </button>
          
              <!-- CSV Export Button -->
              <button class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
                <i class="fas fa-file-csv"></i>
              </button>
            </div>
          
            <!-- Right Side: Send All to Facility Button -->
            <div>
              <!-- <button class="btn btn-primary" style="background-color: #005590;">Send All to Facility</button> -->
            </div>
          </div>
            <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
            <table class="table table-striped purchase-table" *ngIf="!loading">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Ext Sytem</th>
                  <th>Order No</th>
                  <th>Supplier</th>
                  <th>Order Date</th>
                  <th>Due Date</th>
                  <!-- <th>Source</th> -->
                  <th>Total</th>
                  <th>Currency</th>
                  <th>Items</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ext of extPos; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ ext.extsystemid }}</td>
                  <td>{{ ext.orderno }}</td>
                  <td>{{ ext.supplierid }}</td>
                  <td>{{ ext.orderdate }}</td>
                  <td>{{ ext.duedate }}</td>
                  <!-- <td>{{ ext.sourceid }}</td> -->
                  <td>{{ ext.totalvalue }}</td>
                  <td>{{ ext.currency }}</td>
                  <td>{{ ext.formattedItems }}</td> 
                  <td>
                    <button (click)="viewExternalOrderDetails(ext.orderno,ext.extsystemid)" aria-label="View"> 
                      <i class="fas fa-eye" data-bs-toggle="tooltip" title="ExtOrder Details"
                          style="cursor: pointer;"></i>
                      <!-- <i class="fas fa-eye"></i> -->
                  </button>
                    <!-- <i class="fa fa-eye"></i> -->
                    <!-- <button  aria-label="Edit">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button  aria-label="Delete">
                      <i class="fas fa-trash"></i>
                    </button> -->
                    <!-- <i class="fa fa-trash"></i> -->
                   
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
           <!-- Pagination Controls -->
        <div class="pagination-controls d-flex align-items-center">
          <button (click)="loadPreviousPage()" [disabled]="currentPage === 1"
              class="btn btn-warning">Previous</button>
          <!-- Display current page and total pages -->
          <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
          <button (click)="loadNextPage()" class="btn btn-success">Next</button>
      </div>
    </div>

     <!-- Modal -->
 <div class="modal " id="newExtpo" >
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="addUserModalLabel">Create Ext Po</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"(click)="closeModel()"></button>
          </div>
          <div class="modal-body">
            <form [formGroup]="addExtpoForm" (ngSubmit)="onSubmit()">
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="extsystemid" class="form-label">External System ID</label>
                  <input type="text" class="form-control" formControlName="extsystemid">
                </div>
                <div class="col-md-6">
                  <label for="orderno" class="form-label">Order No</label>
                  <input type="number" class="form-control" formControlName="orderno">
                </div>
              </div>
            
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="requistionno" class="form-label">Requisition No</label>
                  <input type="text" class="form-control" formControlName="requistionno">
                </div>
                <div class="col-md-6">
                  <label for="orderdate" class="form-label">Order Date</label>
                  <input type="date" class="form-control" formControlName="orderdate">
                </div>
              </div>
            
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="duedate" class="form-label">Due Date</label>
                  <input type="date" class="form-control" formControlName="duedate">
                </div>
                <div class="col-md-6">
                  <label for="sourceid" class="form-label">Source ID</label>
                  <input type="text" class="form-control" formControlName="sourceid">
                </div>
              </div>
            
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="grantcycleid" class="form-label">Grant Cycle ID</label>
                  <input type="text" class="form-control" formControlName="grantcycleid">
                </div>
                <div class="col-md-6">
                  <label for="programid" class="form-label">Program ID</label>
                  <input type="text" class="form-control" formControlName="programid">
                </div>
              </div>
            
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="supplierid" class="form-label">Supplier ID</label>
                  <input type="text" class="form-control" formControlName="supplierid">
                </div>
                <div class="col-md-6">
                  <label for="totalvalue" class="form-label">Total Value</label>
                  <input type="number" class="form-control" formControlName="totalvalue">
                </div>
              </div>
            
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="currency" class="form-label">Currency</label>
                  <input type="text" class="form-control" formControlName="currency">
                </div>
                <div class="col-md-6">
                  <label for="destcountry" class="form-label">Destination Country</label>
                  <input type="text" class="form-control" formControlName="destcountry">
                </div>
              </div>
            
              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="status" class="form-label">Status</label>
                  <input type="text" class="form-control" formControlName="status">
                </div>
                <div class="col-md-6">
                  <label for="erporderno" class="form-label">ERP Order No</label>
                  <input type="number" class="form-control" formControlName="erporderno">
                </div>
              </div>
            
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModel()">Close</button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
            
          </div>
      </div>
  </div>
</div>

