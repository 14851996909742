<app-top-navbar></app-top-navbar>

<div class="content-area">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/users/profile">User</a></li>
            <li class="breadcrumb-item active" aria-current="page">details</li>

        </ol>
    </nav>
    <div class="w-full bg-blue-100 border-blue-100 border-y-4 rounded-lg">
        <div class="d-flex justify-between items-center bg-inherit rounded-t-lg">
            <h3 class="text-xl font-m edium p-2 text-uppercase font-semibold bg-gray-200 w-full">user Details</h3>
            <a href="/users/list" class="hover:shadow-sm hover:text-gray-700"><i class="fa fa-times-circle fa-2xl px-1 py-2"></i></a>
        </div>
        <div class="rounded-lg shadow p-3 my-2 bg-white">
            <div class="flex justify-between">
               <div class="flex flex-col sm:flex-row">
                <div class="text-lg pr-1 font-semibold text-gray-600 w-full sm:w-auto" style="font-size: 0.9rem">Fullname:</div>
                <div class="text-lg w-full sm:w-auto" style="font-size: 0.9rem"> {{ user.first_name }} {{ user.last_name }} </div>
               </div>
               <div class="flex">
                <p class="text-sm text-gray-500 font-semibold" style="font-size: 0.9rem">Created on:</p>
                <p class="text-sm pl-1" style="font-size: 0.9rem">This Year</p>
               </div>
            </div>
              <div class="line-container">
                <hr class="line animate-pulse">
              </div>
              <div class="grid grid-cols-4 gap-4 pb-3">
                <div class="col-span-1">
                    <p class="font-semibold text-sm" style="font-size: 0.8rem">Institution</p>
                    <p class="text-sm" style="font-size: 0.8rem">{{ user.institution }}</p>
                  </div>
                  <div class="col-span-1">
                    <p class="font-semibold text-sm" style="font-size: 0.8rem">Phone</p>
                    <p class="text-sm" style="font-size: 0.8rem">{{user.phone}}</p>
                  </div>
                <div class="col-span-1">
                    <p class="font-semibold text-sm" style="font-size: 0.8rem">Email</p>
                    <p class="text-sm" style="font-size: 0.8rem">{{user.email }}</p>
                  </div>
                
              </div>
              <div class="grid grid-cols-4 gap-4 pb-3">
                <div class="col-span-1">
                  <p class="font-semibold text-sm" style="font-size: 0.8rem">Username	</p>
                  <p class="text-sm" style="font-size: 0.8rem">{{user.email }}</p>
                </div>
                <div class="col-span-1">
                    <p class="font-semibold text-sm" style="font-size: 0.8rem">Role</p>
                    <p class="text-sm" style="font-size: 0.8rem">{{user.category}}</p>
                  </div>
                <!-- <div class="col-span-1">
                    <p class="font-semibold text-sm" style="font-size: 0.8rem">Directorate</p>
                    <p class="text-sm" style="font-size: 0.8rem">{{ $directorate->name??'' }} ({{$directorate->description??'null'}})</p>
                  </div> -->
              </div>
    </div>
    
</div>