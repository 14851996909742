
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigurationService } from '../../../Setting/service/configuration.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
declare var $: any;
import { environment } from '../../../../../environments/environment';
import { AccountingService } from '../../accounting.service';


@Component({
  selector: 'app-purchase-requisition-create',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, ReactiveFormsModule, FormsModule, NgSelectModule],
  templateUrl: './purchase-requisition-create.component.html',
  styleUrl: './purchase-requisition-create.component.css'
})
export class PurchaseRequisitionCreateComponent implements OnInit, AfterViewInit {
  private apiUrl = environment.apiEndpoint;
  purchaseReqForm: FormGroup;
  programs: any = [];
  sources: any = [];
  accounts: any = [];
  // filteredPrograms:any = [];
  selectedProgram: any = [];
  productSearchTerm: string = '';
  products: any[] = []; // To hold the list of products from the API
  filteredGrants: Array<{ grantcycleid: string, grantname: string }> = [];

  constructor(private fb: FormBuilder, private route: Router, private http: HttpClient, private service: ConfigurationService, private toastr: ToastrService, private requisition: AccountingService) {
    this.purchaseReqForm = this.fb.group({
      disbursementDetails: this.fb.group({
        transdate: [new Date().toISOString().split('T')[0]],
        // transdate: ['', Validators.required],
        programid: ['', Validators.required],
        sourceid: ['', Validators.required],
        remark: ['', Validators.required],
        // finyear: ['', Validators.required],
        grantcycleid: ['', Validators.required],
        approvalletterrefno: ['', Validators.required],
        orderletterrefno: ['', Validators.required],
        // fromaccountno: ['', Validators.required],
        // toaccountno: ['', Validators.required],
        amount: ['', Validators.required],
        ordername: ['', Validators.required],
        approvalletterlink: [null],
        orderletterlink: [null],
        currency: ['', Validators.required]
        // transdate: ['', Validators.required],
        // programid: ['', Validators.required],
        // sourceid: ['', Validators.required],
        // remark: ['', Validators.required],
        // finyear: ['', Validators.required],
        // grantid: ['', Validators.required],
        // approvalletterrefno: ['', Validators.required],
        // fromaccountno: ['', Validators.required],
        // toaccountno: ['', Validators.required],
        // amount: ['', Validators.required],
        // approvalletterlink: [null],
        // orderletterlink: [null]
      }),
      // productDetails: this.fb.array([this.createProductGroup()])
      productDetails: this.fb.array([
        this.fb.group({
          productcode: ['', Validators.required],
          duedate: ['', Validators.required],
          quantity: [0, Validators.required],
          uom: ['', Validators.required],
          unitprice: [0, Validators.required],
          value: [0, Validators.required],
          // value: {value:'', disabled: true}
        })
      ])
    });
  }
  ngAfterViewInit(): void {
    $('.select2').select2({
      placeholder: 'Select',
      allowClear: true,
      width: '100%',

    }).on('select2:select', (event: any) => {
      this.onSourceSelectChange(event);

      this.initializeSelect2();
    });
    // (window as any).$ = require('jquery');
    // $('.selectpicker').selectpicker();
    $('#sourceid').select2({
      placeholder: 'Select Source',
      allowClear: true,
      width: '100%',
    }).on('select2:select', (event: any) => {
      this.onSourceidChange(event); // Call only for Source ID
    });

    // Initialize Select2 for Program ID
    $('#programid').select2({
      placeholder: 'Select Program',
      allowClear: true,
      width: '100%',
    }).on('select2:select', (event: any) => {
      this.onProgramChange(event); // Call only for Program ID
    });
  }

  ngOnInit(): void {
    this.loadDropdownData();
    this.loadProductData();
    // Subscribe to valueChanges for each product row to calculate value dynamically
    this.productDetails.controls.forEach((productControl, index) => {
      // Subscribe to quantity and unitprice changes
      productControl.get('quantity')!.valueChanges.subscribe(() => this.calculateValue(index));
      productControl.get('unitprice')!.valueChanges.subscribe(() => this.calculateValue(index));
      productControl.get('productcode')!.valueChanges.subscribe(() => this.onProductCodeChange(index)); // Handle UoM population
    });
    // Watch for changes in product details
    // this.productDetails.controls.forEach((productControl, index) => {
    //   productControl.get('productcode')!.valueChanges.subscribe(() => this.onProductCodeChange(index));
    //   productControl.get('quantity')!.valueChanges.subscribe(() => this.calculateValue(index));
    //   productControl.get('unitprice')!.valueChanges.subscribe(() => this.calculateValue(index));
    // });
    // this.productDetails.controls.forEach((productControl, index) => {
    //   productControl.get('quantity')!.valueChanges.subscribe(() => this.calculateValue(index));
    //   productControl.get('unitprice')!.valueChanges.subscribe(() => this.calculateValue(index));
    // });
  }

  // Method to fetch product data from the API
  loadProductData(): void {
    this.http.get<any>(`${this.apiUrl}configurations/api/v1/product/`).subscribe((response) => {
      console.log('products from endpoint', this.products)
      this.products = response.results;
    });
  }

  get productDetails(): FormArray {
    return this.purchaseReqForm.get('productDetails') as FormArray;
  }
  calculateValue(index: number) {
    const productControl = this.productDetails.at(index);
    const quantity = productControl.get('quantity')!.value || 0; // Get quantity value (default to 0 if empty)
    const unitprice = productControl.get('unitprice')!.value || 0; // Get unit price value (default to 0 if empty)
    const value = quantity * unitprice; // Calculate value as quantity * unitprice

    // Update the value field with the calculated result
    productControl.get('value')!.setValue(value.toFixed(2), { emitEvent: false });
    // const productControl = this.productDetails.at(index);
    // const quantity = productControl.get('quantity')!.value || 0;
    // const unitprice = productControl.get('unitprice')!.value || 0;
    // const value = quantity * unitprice;


    // productControl.get('value')!.setValue(value.toFixed(2), { emitEvent: false });
  }
  // Method to filter products based on the search term
  filteredProducts() {
    if (!this.productSearchTerm) {
      return this.products;
    }
    return this.products.filter(product =>
      product.productcode.toLowerCase().includes(this.productSearchTerm.toLowerCase())
      //  ||
      // product.productname.toLowerCase().includes(this.productSearchTerm.toLowerCase())
    );
  }
  // Create product form group
  createProductGroup(): FormGroup {
    return this.fb.group({
      productcode: ['', Validators.required],
      duedate: ['', Validators.required],
      quantity: ['', Validators.required],
      uom: ['', Validators.required],
      unitprice: ['', Validators.required],
      value: ['', Validators.required]
    });
  }

  addProduct(): void {
    // const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    // productDetails.push(this.createProductGroup());
    const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    productDetails.push(this.createProductGroup()); // Add new product row

    // After adding a product, subscribe to quantity and unitprice changes for the new row
    const newIndex = productDetails.length - 1;
    const productControl = productDetails.at(newIndex);
    productControl.get('quantity')!.valueChanges.subscribe(() => this.calculateValue(newIndex));
    productControl.get('unitprice')!.valueChanges.subscribe(() => this.calculateValue(newIndex));
    productControl.get('productcode')!.valueChanges.subscribe(() => this.onProductCodeChange(newIndex)); // UoM population for new row
    // const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    // productDetails.push(this.createProductGroup());


    // const newIndex = productDetails.length - 1;
    // const productControl = productDetails.at(newIndex);
    // productControl.get('productcode')!.valueChanges.subscribe(() => this.onProductCodeChange(newIndex));
  }

  removeProduct(index: number): void {
    const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    productDetails.removeAt(index);
  }

  // Load dropdown data for program, sources, accounts, etc.
  loadDropdownData(): void {
    //Fetch programs from endpoint
    this.service.getPrograms().subscribe((data: any) => {

      this.programs = data.results;
      // this.filteredPrograms = this.programs;
    });

    //Fetch sources from endpoint
    this.service.getSource().subscribe((data: any) => {
      this.sources = data.results;
    });

    //Fetch accounts from endpoint
    this.service.getBankAccount().subscribe((data: any) => {
      this.accounts = data.results;
    });
  }

  // Method to handle product code change
  onProductCodeChange(index: number): void {
    // const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    // const selectedProductCode = productDetails.at(index).get('productcode')!.value;


    // const selectedProduct = this.products.find(p => p.productcode === selectedProductCode);

    // if (selectedProduct) {

    //   productDetails.at(index).patchValue({ 
    //     uom: selectedProduct.uom,

    //   });
    // }
    const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    const selectedProductCode = productDetails.at(index).get('productcode')!.value;

    // Find the selected product based on product code
    const selectedProduct = this.products.find(p => p.productcode === selectedProductCode);

    if (selectedProduct) {
      // Update the UoM field based on the selected product
      productDetails.at(index).patchValue({
        uom: selectedProduct.uom
      });
    }
  }
  // Handle file upload change
  onFileChange(event: Event, fieldName: string) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Assuming you're working with FormData, add the file to the form data
      this.purchaseReqForm.patchValue({
        [fieldName]: file
      });

      //to mark the control as dirty or touched
      this.purchaseReqForm.get(fieldName)?.markAsDirty();
    }
  }


  onSubmit() {
    console.log('Disbursement before validation');
    console.log('disbursement form', this.purchaseReqForm.get('disbursementDetails')!.value);
    if (this.purchaseReqForm.valid) {
      const disbursementDetails = this.purchaseReqForm.get('disbursementDetails')!.value;

      // Build the payload object directly
      const payload = {
        transdate: disbursementDetails.transdate,
        programid: disbursementDetails.programid,
        sourceid: disbursementDetails.sourceid,
        remark: disbursementDetails.remark,
        // finyear: disbursementDetails.finyear,
        grantcycleid: disbursementDetails.grantcycleid,
        approvalletterrefno: disbursementDetails.approvalletterrefno,
        orderletterrefno: disbursementDetails.orderletterrefno,
        // fromaccountno: disbursementDetails.fromaccountno,
        // toaccountno: disbursementDetails.toaccountno,
        amount: disbursementDetails.amount,
        ordername: disbursementDetails.ordername,
        currency: disbursementDetails.currency,
        approvalletterlink: disbursementDetails.approvalletterlink,
        orderletterlink: disbursementDetails.orderletterlink,
        items: this.purchaseReqForm.get('productDetails')!.value
      };

      console.log('Payload:', payload);
      this.requisition.createDisbursement(payload).subscribe(
        (response) => {
          this.toastr.success('Disbursement created successfully');
          this.route.navigate(['/account/purchase-requisition']);
        }, (error: HttpErrorResponse) => {


          // Check if the error object contains the error message
          if (error.error && error.error.error) {
            // Display the error message from the backend
            this.toastr.error(error.error.error);
          } else {
            // Fallback to a generic error message
            this.toastr.error('An unexpected error occurred. Please try again.');
          }

          console.error('Disbursement creation failed', error);
        })

    }
    else {
      console.log(this.purchaseReqForm.errors);
      this.toastr.error('Invalid Form fields,try again');
    }
  }


  // Method to handle source change event
  onSourceChange(event: Event): void {
    const selectedSourceId = (event.target as HTMLSelectElement).value;
    const selectedSource = this.sources.find((source: { sourceid: string; }) => source.sourceid === selectedSourceId);

    if (selectedSource) {
      this.filteredGrants = selectedSource.grantcycles;
    } else {
      this.filteredGrants = [];
    }

    this.purchaseReqForm.patchValue({ grantname: '' });
  }
  // Method to handle source change event for Select2
  onSourceSelectChange(event: any): void {
    const selectedSourceId = event.params.data.id; // Adjust to get sourceid from Select2's event
    const selectedSource = this.sources.find(
      (source: { sourceid: string }) => source.sourceid === selectedSourceId
    );

    if (selectedSource) {
      this.filteredGrants = selectedSource.grantcycles;
    } else {
      this.filteredGrants = [];
    }

    // Clear the selected grant cycle when the source changes
    this.purchaseReqForm.patchValue({ grantname: '' });
    // this.purchaseReqForm.patchValue({ grantcycleid: '' });
  }
  // Method to initialize Select2 and bind the change event
  initializeSelect2(): void {
    $('.select2').select2().on('select2:select', (event: any) => {
      const target = event.target as HTMLSelectElement;
      const index = $(target).closest('tr').index(); // Get the row index
      const selectedProductCode = target.value; // Get the selected product code

      // Update the Angular form control with the selected value
      const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
      productDetails.at(index).patchValue({ productcode: selectedProductCode });

      // Call the change handler to populate UoM
      this.onProductCodeChange(index);
    });
    // $('.select2').select2().on('select2:select', (event: any) => {
    //   const target = event.target as HTMLSelectElement;
    //   const index = $(target).closest('tr').index();
    //   const selectedProductCode = target.value; 


    //   const productDetails = this.purchaseReqForm.get('productDetails') as FormArray;
    //   productDetails.at(index).patchValue({ productcode: selectedProductCode });


    //   this.onProductCodeChange(index);

    // });
  }




  // Function to select a program from the dropdown
  selectProgram(program: any): void {
    this.selectedProgram = program;
    // this.filteredPrograms = this.programs; 
  }


  // Manually capture program and source selection changes
  onProgramChange(event: any) {
    const programId = event.target.value;
    console.log('Selected Program ID:', programId);
    this.purchaseReqForm.get('disbursementDetails.programid')?.setValue(programId);
  }

  onSourceidChange(event: any) {
    const sourceId = event.target.value;
    console.log('Selected Source ID:', sourceId);
    this.purchaseReqForm.get('disbursementDetails.sourceid')?.setValue(sourceId);
  }

  onCancel() {
    this.route.navigate(['/account/purchase-requisition']);
  }
}

