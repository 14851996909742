import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { Partnumber } from './number.model';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ExtProdDialogComponent } from './ext-prod-dialog/ext-prod-dialog.component';
import { ProductService } from '../product/services/product.service';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-part-number',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,SidebarComponent,CommonModule,MainDashboardComponent,FormsModule,LoadingSpinnerComponent],
  templateUrl: './part-number.component.html',
  styleUrl: './part-number.component.css'
})
export class PartNumberComponent  implements OnInit {

  selectedExternalSystem!: string;
  productCategory!: string;
  numbers: Partnumber[] = [];
  extproducts: any[]= [];
  loading = true;

  constructor(private router: Router,private http: HttpClient,public dialog: MatDialog, private extprodservice:ProductService){}


  NavigateToCreate(){
    this.router.navigate(['partnumber/request'])
  }
  ngOnInit(): void {
    this.loading=true;
    this.listExtproduct();
    // Sample data
    // this.numbers = [
    //     {
    //       partnumber: 'WMB01010101',
    //       wambonumber: 'WB-82765439',
    //       description: 'LAMINVADG 300MG TABLETS', 
    //       category: 'AB',
    //       uom: '30TB',
    //       extsystemid: 'WAMBO',
    //       status: 'Active'
    //     },
    //     {
    //         partnumber: '2002',
    //         wambonumber: 'WB-796523415',
    //         description: 'DRINBGFDS 20DT MG',
    //         category: 'A',
    //         uom: '30TB',
    //         extsystemid: 'GDF',
    //         status: 'Active'
    //     },
       
    // ];
}
listExtproduct(){
  this.extprodservice.getExtProducts().subscribe((data:any)=>{
    if(data){
      this.extproducts = data.results;
      this.loading=false;
    }else{
      alert('No data Found!')
    }
  });
}

mapPartno(partno: any){
  const dialogRef = this.dialog.open(ExtProdDialogComponent, {
    width: '300px',
    data: { uom: partno.partnumber },
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result if needed, e.g., refresh data or show a success message
      // console.log('Updated Internal UOM:', result);
      this.ngOnInit(); // Refresh data after updating
    }
  });

}
downloadPartNumbers() {
  if (this.selectedExternalSystem && this.productCategory) {
    const category = encodeURIComponent(this.productCategory);
    const endpoint = `https://api.msd.atomatiki.tech/integrations/api/v1/${this.selectedExternalSystem}/part-numbers/?category=${category}`;
    
    
    this.http.get(endpoint).subscribe(data => {
      console.log('Download data:', data);
      alert('New Part Numbers Downloaded Successfull')
      location.reload();
      // Handle the data (e.g., download it as a file or display it)
    }, error => {
      console.error('Error fetching part numbers:', error);
    });
  } else {
    alert('Please select an external system and enter a product category.');
  }
}

}
