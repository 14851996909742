import { Component, NgModule } from '@angular/core';
import { LayoutsComponent } from '../../../../pages/layouts/layouts.component';
import { FormsModule, NgModel } from '@angular/forms';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-new-receipt',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,FormsModule],
  templateUrl: './new-receipt.component.html',
  styleUrl: './new-receipt.component.css'
})
export class NewReceiptComponent {
  grantCode: string = '';
  grantCycle: string = '';
  program: string = '';
  msdLegalNumber: string = '';
  purpose: string = '';

  referenceNumber: string = '';
  receiptDate: string = '';
  receivedFrom: string = '';
  bankAccount: string = '';
  paymentMethod: string = '';
  amount: string = '';
  currency: string = '';
  comments: string = '';

  onSubmit() {
    // Handle form submission
    console.log("Form Submitted", this);
  }
}
