<!-- <app-layouts></app-layouts> -->
<div class="sidebar">
    <div class="mt-5">
        <ul>
            <li [ngClass]="{ 'active': activeRoute('/switchboard') }">
                <!-- <button mat-icon-button>
                    <mat-icon style="color: white;">menu</mat-icon>
                    </button> -->
                <a routerLink="/switchboard" routerLinkActive="active">
                    <i class="fa fa-th-large"></i> Apps
                </a>
            </li>
            <li>
                <a routerLink="/dashboard" routerLinkActive="active">
                    <i class="fa fa-chart-bar"></i> Dashboard <i class="fa"></i>
                </a>
            </li>

            <li>
                <a hrefs="#" (click)="toggleDropdown()">
                    <i class="fa fa-cog"></i> Settings <i class="fa fa-caret-down"></i>
                </a>
                <ul *ngIf="dropdownOpen" class="dropdown">
                    <li [ngClass]="{ 'active': activeRoute('/partnumber') }">
                        <a routerLink="/partnumber" routerLinkActive="active">
                            <i class=""></i> Part Numbers
                        </a>
                    </li>
                    <li [ngClass]="{ 'active': activeRoute('/facilities') }">
                        <a routerLink="/facilities" routerLinkActive="active">
                            <i class=""></i> Health Facilities
                        </a>
                    </li>
                    <li [ngClass]="{ 'active': activeRoute('/product') }">
                        <a routerLink="/product" routerLinkActive="active">
                            <i class=""></i> Products
                        </a>
                    </li>
                    <li [ngClass]="{ 'active': activeRoute('/accountingcode') }">
                        <a routerLink="/accountingcode" routerLinkActive="active">
                            <i class=""></i> Accounting Codes
                        </a>
                    </li>
                    <li [ngClass]="{ 'active': activeRoute('/configuration') }">
                        <a routerLink="/configuration" routerLinkActive="active">
                            <i class=""></i> Configurations
                        </a>
                    </li>

                </ul>
            </li>
            <li>
                <a hrefs="#" (click)="toggleDropdown2()">
                    <i class="fa fa-shopping-cart"></i> Orders <i class="fa fa-caret-down"></i>
                </a>
                <ul *ngIf="dropdownOpen2" class="dropdown">

                    <li [ngClass]="{ 'active': activeRoute('/externalpo') }">
                        <a routerLink="/externalpo" routerLinkActive="active">
                            <i class=""></i> External Po
                        </a>
                    </li>
                    <li [ngClass]="{ 'active': activeRoute('/purchaseOrder') }">
                        <a routerLink="/purchaseOrder" routerLinkActive="active">
                            <i class=""></i> Purchasing Order
                        </a>
                    </li>
                    <li [ngClass]="{ 'active': activeRoute('/dummyPo') }">
                        <a routerLink="/dummyPo" routerLinkActive="active">
                            <i class=""></i> Dummy Purchasing Order
                        </a>
                    </li>


                </ul>
        </li>
        <li>
            <a hrefs="#" (click)="toggleDropdown3()">
                <i class="fa fa-file-invoice-dollar"></i> Accounts <i class="fa fa-caret-down"></i>
            </a>
            <ul *ngIf="dropdownOpen3" class="dropdown">

                <li [ngClass]="{ 'active': activeRoute('/externalpo') }">
                    <a routerLink="/externalpo" routerLinkActive="active">
                        <i class=""></i> Voucher
                    </a>
                </li>
                <li [ngClass]="{ 'active': activeRoute('/receipt') }">
                    <a routerLink="/receipt" routerLinkActive="active">
                        <i class=""></i> Receipt
                    </a>
                </li>
                <li [ngClass]="{ 'active': activeRoute('/dummypo') }">
                    <a routerLink="/dummypo" routerLinkActive="active">
                        <i class=""></i> Disbursement
                    </a>
                </li>
                <li [ngClass]="{ 'active': activeRoute('/dummypo') }">
                    <a routerLink="/dummypo" routerLinkActive="active">
                        <i class=""></i> Budget
                    </a>
                </li>


            </ul>
        </li>
        </ul>

    </div>

</div>
<router-outlet></router-outlet>

<!-- <div class="sidebar">
        <ul>
            <li><a href="#"><i class="fa fa-cog"></i> Settings</a></li>
            <li><a href="#"><i class="fa fa-list"></i> Part Numbers</a></li>
            <li><a href="#"><i class="fa fa-hospital"></i> Health Facilities</a></li>
            <li><a href="#"><i class="fa fa-cubes"></i> Products</a></li>
            <li><a href="#"><i class="fa fa-file-invoice-dollar"></i> Accounting Codes</a></li>
            <li><a href="#"><i class="fa fa-wrench"></i> Configurations</a></li>
            <li><a href="#"><i class="fa fa-th-large"></i> Apps</a></li>
        </ul>
    </div> -->