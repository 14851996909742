<!-- <app-layouts></app-layouts> -->

    <!-- <app-sidebar></app-sidebar> -->
   <div class="d"> 
        <app-top-navbar></app-top-navbar>
        <div class="content-area ">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/configuration/product">Settings</a></li>
                <li class="breadcrumb-item active" aria-current="page">Products</li>
            </ol>
        </nav>
            <!-- <p>Settings > Products</p> -->
           
            <div class="product-container">
              <div class="filters">
                <form >
                    <div class="row">
                        <!-- Facility Filter -->
                        <div class="form-group col-md-6">
                            <label for="facility">Category</label>
                            <input type="text" id="facility"  name="facility"
                                class="form-control">
                        </div>
                        <!-- Order Number Filter -->
                        <div class="form-group col-md-6">
                            <label for="orderNo">Item Code</label>
                            <input type="text" id="orderNo" name="orderNo"
                                class="form-control">
                        </div>
                    </div>
          
                    <div class="row">
                        <!-- Start Date Filter -->
                         
                        <!-- <div class="form-group col-md-6">
                            <label for="startDate">Start Date</label>
                            <input type="date" id="startDate" name="startDate"
                                class="form-control">
                        </div> -->
                        <!-- End Date Filter -->
                        <!-- <div class="form-group col-md-6">
                            <label for="endDate">End Date</label>
                            <input type="date" id="endDate"  name="endDate"
                                class="form-control">
                        </div> -->
                    </div>
          
                    <!-- Submit Button -->
                    <div class="row">
                        <div class="form-group col-md-12">
                            <button type="submit" class="btn btn-primary">Apply Filters</button>
                        </div>
                    </div>
                </form>
            </div>
                <div class="us">
                  <button class="btn btn-primary" style="background-color: #005590;">Add New Product</button>
                  <button class="btn btn-primary">Send Products to eLMIS</button>
                </div>
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <table class="table table-striped purchase-table">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Item Code</th>
                      <th scope="col">Description</th>
                      <th scope="col">Category</th>
                      <th scope="col">UoM</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of products; let i = index">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>{{ product.productcode }}</td>
                      <td>{{ product.productname }}</td>
                      <td>{{ product.productcategory }}</td>
                      <td>{{ product.uom }}</td>
                      <td>{{ product.price }}</td>
                      <td>{{ product.isactive ? 'Active' : 'Inactive' }}</td>
                      <td>
                      
                        <button aria-label="Search">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button  aria-label="Edit">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button aria-label="Forward">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table> 
                <div class="pagination-controls">
                  <button class="btn btn-warning" (click)="goToPreviousPage()" [disabled]="currentPage === 1">Previous</button>
                  <span>Page {{ currentPage }} of {{ totalPages }}</span>
                  <button class="btn btn-success" (click)="goToNextPage()" [disabled]="currentPage === totalPages">Next</button>
                </div>
              </div> 
           
        </div>
    
      </div>