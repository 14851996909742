import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-payment',
  standalone: true,
  imports: [],
  templateUrl: './actual-payment.component.html',
  styleUrl: './actual-payment.component.css'
})
export class ActualPaymentComponent {

}
