import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideToastr, ToastrModule } from 'ngx-toastr';
import { loadingInterceptor } from './core/interceptors/loading.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
     provideRouter(routes),
      provideClientHydration(),
       provideHttpClient(),
       provideHttpClient(withInterceptors([loadingInterceptor])),
       provideAnimationsAsync(),
       provideToastr(),
       importProvidersFrom([BrowserAnimationsModule])
     // Add ToastrModule configuration here
    // { provide: ToastrModule, useFactory: () => ToastrModule.forRoot() }, // Configure ToastrModule globally
    ]
};
