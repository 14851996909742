<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <p>User Management > Users</p>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <!-- Search input on the left -->
            <input type="text" class="form-control w-50" placeholder="Search..." />

            <!-- Add New User button on the right -->
            <!-- <button class="btn btn-primary me-2" (click)="exportData()">Export</button> -->
            <button class="btn btn-success"  >Add New Permission</button>

        </div>

        <!-- User Table -->
        <table class="table table-striped table-bordered user-table">
            <thead>
                <tr>
                    <th>S/N</th>
                    <th>Class Name</th>
                    <th>Permission</th>
                   
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ user.class }}</td>
                    <td>{{ user.permission }}</td>
                    <!-- <td>{{ user.username }}</td>
                    <td>{{ user.role }}</td> -->
                    <td>
                        <!-- Action Buttons -->
                        <i class="fas fa-eye" data-bs-toggle="tooltip" title="View" style="cursor: pointer;"></i>
                        <i class="fas fa-edit mx-2" data-bs-toggle="tooltip" title="Edit" style="cursor: pointer;"></i>
                        <!-- <i class="fas fa-key" data-bs-toggle="tooltip" title="Add Permission"
                            style="cursor: pointer;"></i>
                        <i class="fas fa-lock mx-2" data-bs-toggle="tooltip" title="Change Password"
                            style="cursor: pointer;"></i> -->


                    </td>
                </tr>
            </tbody>
        </table>

       
    </div>
</div>