<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/purchase-requisition">Accounts</a></li>
                <li class="breadcrumb-item"><a href="/purchase-requisition">Requisition</a></li>
                <li class="breadcrumb-item active" aria-current="page">Create</li>
            </ol>
        </nav>
        <div class="container">
            <form [formGroup]="purchaseReqForm" (ngSubmit)="onSubmit()">
                <div class="Column">
                    <!-- Disbursement Details -->
                    <div class="col-md-12">
                        <h4>Requisition  Details</h4>
                        <div formGroupName="disbursementDetails">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>Order Name<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="ordername">
                                </div>
                              
                                <div class="col-md-6">
                                    <label>Remark<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="remark">
                                </div>

                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>Requisition Date<span class="text-danger">*</span></label>
                                    <input type="date" class="form-control" formControlName="transdate">
                                </div>
                              
                                <div class="col-md-6">
                                    <label>Program<span class="text-danger">*</span></label>
                                  
                                    <select  id="programid" class="form-select select2" formControlName="programid">
                                      <option value="">Select Program</option>
                                      <option *ngFor="let program of programs" [value]="program.programid">
                                        {{program.programid}}-{{ program.programname }}
                                      </option>
                                    </select>
                                  </div>
                                
                      
                            </div>
                          
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>Approval Letter Reference No <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="approvalletterrefno">
                                </div>
                                <div class="col-md-6">
                                    <label>Order Letter Reference No <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="orderletterrefno">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>Amount<span class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="amount">
                                </div>
                                <div class=" col-md-6">
                                    <label>Attachment (Approval Letter)<span class="text-danger">*</span></label>
                                    <input type="file" class="form-control"
                                        (change)="onFileChange($event, 'approvalletterlink')">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>Attachment (Order Letter)<span class="text-danger">*</span></label>
                                    <input type="file" class="form-control"
                                        (change)="onFileChange($event, 'orderletterlink')">
                                </div>
                                <div class="col-md-6">
                                    <label>Currency<span class="text-danger">*</span></label>
                                    <select class="form-select" formControlName="currency">

                                        <option value="">Select Currency</option>
                                        <option value="TZS">TZS</option>
                                        <option value="USD">USD </option>
                                    </select>
                                </div>

                            </div>

                            <div class="row mb-3">
                                <!-- Source Dropdown -->
                                <div class="col-md-6">
                                    <label>Source Of Fund<span class="text-danger">*</span></label>
                                    <select id="sourceid" class="form-select select2" formControlName="sourceid" >
                                        <option *ngFor="let source of sources" [value]="source.sourceid">
                                            {{ source.sourcename }}
                                        </option>
                                    </select>
                                </div>
                            
                                <!-- Grant Name Dropdown -->
                                <div class="col-md-6">
                                    <label>Grant Name<span class="text-danger">*</span></label>
                                    <select class="form-select" formControlName="grantcycleid" >
                                        <option *ngFor="let grant of filteredGrants" [value]="grant.grantcycleid">
                                            {{ grant.grantname }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="row mb-3">
                               
                                
                            </div>
                        </div>
                    </div>

                    <!-- Product Details -->
                    <div class="col-md-12">
                        <h4>Product Details</h4>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Product Code</th>
                                   
                                    <th>Due Date</th>
                                    <th>Quantity</th>
                                    <th>UoM</th>
                                    <th>Unit Price</th>
                                    <th>Value</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="productDetails">
                                <tr *ngFor="let product of productDetails.controls; let i = index" [formGroupName]="i">
                                    <td style="width: 30%;">
                                      
                                      
                                        <select class="form-select select2 " formControlName="productcode">
                                            <option value="">Select Product Code</option>
                                            <option *ngFor="let product of products" [value]="product.productcode">
                                              {{ product.productcode }} - {{ product.productname }}
                                            </option>
                                          </select>
                                    </td>
                                    
                                    <td>
                                        <input type="date" class="form-control" formControlName="duedate">
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" formControlName="quantity">
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" formControlName="uom" >
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" formControlName="unitprice">
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" formControlName="value">
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-danger" (click)="removeProduct(i)">Remove</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- Button to add another product -->
                        <button type="button" class="btn btn-primary" (click)="addProduct()">
                            <i class="fa fa-plus"></i> Add Product
                        </button>
                    </div>
                </div>

                <!-- Submit button -->
                <div class="text-right mt-3">
                    <button (click)="onCancel()" class="btn btn-warning">Cancel</button>
                    <button type="submit" class="btn btn-success ms-2">Create</button>
                </div>
            </form>
        </div>
    </div>
</div>