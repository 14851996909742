<form [formGroup]="searchForm" (ngSubmit)="onSearch()">

    <div class="row mb-3">
      <div class="col-md-6">
        <label for="mofCode" class="form-label">Search MoF Epicor Grant Code</label>
        <input type="text" class="form-control" formControlName="mofCode" placeholder="Enter MoF Grant Code">
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Search</button>
    <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
  </form>
  
  <div *ngIf="mofGrantCode">
    <h5>MoF Grant Code Details:</h5>
    <p><strong>Code:</strong> {{ mofGrantCode.mofcode }}</p>
    <p><strong>Description:</strong> {{ mofGrantCode.codedesc }}</p>
    <p><strong>TransRef:</strong> {{ mofGrantCode.transref }}</p>
  </div>
  
  <div *ngIf="msdGrantCode">
    <h5>MSD Grant Code Details:</h5>
    <p><strong>TransCode:</strong> {{ msdGrantCode.transcode }}</p>
    <p><strong>Description:</strong> {{ msdGrantCode.codedesc }}</p>
  </div>

<!-- Mapping Form: Display after both codes are retrieved -->
<div *ngIf="mofGrantCode && msdGrantCode">
    <h5>Map MoF Grant Code to MSD Grant Code</h5>
    <form [formGroup]="mappingForm" (ngSubmit)="onMapCodes()">
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="mappedMofCode" class="form-label">MoF Epicor Grant Code</label>
          <input type="text" class="form-control" formControlName="mappedMofCode" [value]="mofGrantCode.mofcode" >
        </div>
        <div class="col-md-6">
          <label for="mappedMsdCode" class="form-label">MSD ERP Grant Code</label>
          <input type="text" class="form-control" formControlName="mappedMsdCode" [value]="msdGrantCode.transcode" >
        </div>
      </div>
  
      <button type="submit" class="btn btn-success">Map Grant Codes</button>
    </form>
  </div>
  