<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="order/externalpo">VP Orders</a></li>
                <li class="breadcrumb-item"><a href="/order/externalpo">External Orders</a></li>
                <li class="breadcrumb-item active" aria-current="page">Details</li>
            </ol>
        </nav>

        <div class="order-details-container">
            <div class="order-info">
                <h6>External Order Details</h6>
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->


                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <!-- <pre>{{ orderDetails | json }}</pre> -->
                <div *ngIf="orderDetails?.length > 0; else noData">
                    <div *ngFor="let detail of orderDetails">
                        <p><strong>External System:</strong> {{ detail.extsystemid }}</p>
                        <p><strong>Order Number:</strong> {{ detail.orderno }}</p>
                        <p><strong>Order Date:</strong> {{ detail.orderdate }}</p>
                        <p><strong>Items Ordered:</strong></p>
                        <table class="table">
                            <thead>
                                <tr>

                                    <th>Part Number</th>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Value</th>
                                    <th>Unit Price</th>
                                    <th>UOM</th>
                                    <th>External UOM</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of detail.items">
                                    <td>{{ item.externalpartno }}</td>
                                    <td>{{ item.productcode }}</td>
                                    <td>{{ item.productname }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.value }}</td>
                                    <td>{{ item.unitprice }} {{ orderDetails?.currency }}</td>
                                    <td>{{ item.uom }}</td>
                                    <td>{{ item.externaluom }}</td>
                                    <td>{{ item.status }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Template to show if no data is found -->
                <ng-template #noData>
                    <p class="text-center">No data found</p>
                </ng-template>

            </div>

        </div>
        <div class="close-button">
            <button (click)="close()" class="btn btn-primary">Close</button>
        </div>
    </div>
</div>