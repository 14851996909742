<!-- <app-layouts></app-layouts> -->

    <!-- <app-sidebar></app-sidebar> -->
   
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <p>Settings > Part Numbers</p>
        <div class="container">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Enter commodity category" aria-label="Commodity Category">
              <button class="btn btn-dark" type="button">Get GDF Part Numbers</button>
            </div>
            <hr>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col">GDF Part Number</th>
                  <th scope="col">MSD Part Number</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of partNumbers; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.wamboPartNumber }}</td>
                  <td>  <input *ngIf="item.editable" [(ngModel)]="item.msdPartNumber" class="form-control" type="text" placeholder="Enter MSD part number">
                    <span *ngIf="!item.editable">{{ item.msdPartNumber }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-success" type="button"style="background-color:#005590">Submit</button>
          </div>
          
    </div>

