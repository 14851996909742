import { Injectable, NgZone, RendererFactory2, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, merge, fromEvent, of, animationFrameScheduler } from 'rxjs';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Get CSS computed property of the given element
 * @param {?} element
 * @param {?=} property
 * @return {?}
 */
function getStyleComputedProperty(element, property) {
  if (element.nodeType !== 1) {
    return [];
  }
  // NOTE: 1 DOM access here
  /** @type {?} */
  const window = element.ownerDocument.defaultView;
  /** @type {?} */
  const css = window.getComputedStyle(element, null);
  return property ? css[( /** @type {?} */property)] : css;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Returns the parentNode or the host of the element
 * @param {?} element
 * @return {?}
 */
function getParentNode(element) {
  if (element.nodeName === 'HTML') {
    return element;
  }
  return element.parentNode || element.host;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @return {?}
 */
function getScrollParent(element) {
  // Return body, `getScroll` will take care to get the correct `scrollTop` from it
  if (!element) {
    return document.body;
  }
  switch (element.nodeName) {
    case 'HTML':
    case 'BODY':
      return element.ownerDocument.body;
    case '#document':
      return element.body;
    default:
  }
  // Firefox want us to check `-x` and `-y` variations as well
  const {
    overflow,
    overflowX,
    overflowY
  } = getStyleComputedProperty(element);
  if (/(auto|scroll|overlay)/.test(String(overflow) + String(overflowY) + String(overflowX))) {
    return element;
  }
  return getScrollParent(getParentNode(element));
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
const isIE11 = isBrowser && !!(( /** @type {?} */window).MSInputMethodContext && ( /** @type {?} */document).documentMode);
/** @type {?} */
const isIE10 = isBrowser && !!(( /** @type {?} */window).MSInputMethodContext && /MSIE 10/.test(( /** @type {?} */navigator).userAgent));
/**
 * @param {?=} version
 * @return {?}
 */
function isIE(version) {
  if (version === 11) {
    return isIE11;
  }
  if (version === 10) {
    return isIE10;
  }
  return isIE11 || isIE10;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @return {?}
 */
function getOffsetParent(element) {
  if (!element) {
    return document.documentElement;
  }
  /** @type {?} */
  const noOffsetParent = isIE(10) ? document.body : null;
  // NOTE: 1 DOM access here
  /** @type {?} */
  let offsetParent = element.offsetParent || null;
  // Skip hidden elements which don't have an offsetParent
  /** @type {?} */
  let sibling;
  while (offsetParent === noOffsetParent && element.nextElementSibling && sibling !== element.nextElementSibling) {
    sibling = element.nextElementSibling;
    offsetParent = sibling.offsetParent;
  }
  /** @type {?} */
  const nodeName = offsetParent && offsetParent.nodeName;
  if (!nodeName || nodeName === 'BODY' || nodeName === 'HTML') {
    return sibling ? sibling.ownerDocument.documentElement : document.documentElement;
  }
  // .offsetParent will return the closest TH, TD or TABLE in case
  if (['TH', 'TD', 'TABLE'].indexOf(offsetParent.nodeName) !== -1 && getStyleComputedProperty(offsetParent, 'position') === 'static') {
    return getOffsetParent(offsetParent);
  }
  return offsetParent;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @return {?}
 */
function isOffsetContainer(element) {
  const {
    nodeName
  } = element;
  if (nodeName === 'BODY') {
    return false;
  }
  return nodeName === 'HTML' || getOffsetParent(element.firstElementChild) === element;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Finds the root node (document, shadowDOM root) of the given element
 * @param {?} node
 * @return {?}
 */
function getRoot(node) {
  if (node.parentNode !== null) {
    return getRoot(node.parentNode);
  }
  return node;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element1
 * @param {?} element2
 * @return {?}
 */
function findCommonOffsetParent(element1, element2) {
  // This check is needed to avoid errors in case one of the elements isn't defined for any reason
  if (!element1 || !element1.nodeType || !element2 || !element2.nodeType) {
    return document.documentElement;
  }
  // Here we make sure to give as "start" the element that comes first in the DOM
  /* tslint:disable-next-line: no-bitwise */
  /** @type {?} */
  const order = element1.compareDocumentPosition(element2) & Node.DOCUMENT_POSITION_FOLLOWING;
  /** @type {?} */
  const start = order ? element1 : element2;
  /** @type {?} */
  const end = order ? element2 : element1;
  // Get common ancestor container
  /** @type {?} */
  const range = document.createRange();
  range.setStart(start, 0);
  range.setEnd(end, 0);
  const {
    commonAncestorContainer
  } = range;
  // Both nodes are inside #document
  if (element1 !== commonAncestorContainer && element2 !== commonAncestorContainer || start.contains(end)) {
    if (isOffsetContainer(commonAncestorContainer)) {
      return commonAncestorContainer;
    }
    return getOffsetParent(commonAncestorContainer);
  }
  // one of the nodes is inside shadowDOM, find which one
  /** @type {?} */
  const element1root = getRoot(element1);
  if (element1root.host) {
    return findCommonOffsetParent(element1root.host, element2);
  } else {
    return findCommonOffsetParent(element1, getRoot(element2).host);
  }
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Helper to detect borders of a given element
 */
/**
 * @param {?} styles
 * @param {?} axis
 * @return {?}
 */
function getBordersSize(styles, axis) {
  /** @type {?} */
  const sideA = axis === 'x' ? 'Left' : 'Top';
  /** @type {?} */
  const sideB = sideA === 'Left' ? 'Right' : 'Bottom';
  return parseFloat(styles[( /** @type {?} */`border${sideA}Width`)]) + parseFloat(styles[( /** @type {?} */`border${sideB}Width`)]);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} axis
 * @param {?} body
 * @param {?} html
 * @param {?} computedStyle
 * @return {?}
 */
function getSize(axis, body, html, computedStyle) {
  return Math.max(( /** @type {?} */body)[`offset${axis}`], ( /** @type {?} */body)[`scroll${axis}`], ( /** @type {?} */html)[`client${axis}`], ( /** @type {?} */html)[`offset${axis}`], ( /** @type {?} */html)[`scroll${axis}`], isIE(10) ? parseInt(( /** @type {?} */html)[`offset${axis}`], 10) + parseInt(computedStyle[( /** @type {?} */`margin${axis === 'Height' ? 'Top' : 'Left'}`)], 10) + parseInt(computedStyle[( /** @type {?} */`margin${axis === 'Height' ? 'Bottom' : 'Right'}`)], 10) : 0);
}
/**
 * @param {?} document
 * @return {?}
 */
function getWindowSizes(document) {
  /** @type {?} */
  const body = document.body;
  /** @type {?} */
  const html = document.documentElement;
  /** @type {?} */
  const computedStyle = isIE(10) && getComputedStyle(html);
  return {
    height: getSize('Height', body, html, computedStyle),
    width: getSize('Width', body, html, computedStyle)
  };
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Gets the scroll value of the given element in the given side (top and left)
 * @param {?} element
 * @param {?=} side
 * @return {?}
 */
function getScroll(element, side = 'top') {
  /** @type {?} */
  const upperSide = side === 'top' ? 'scrollTop' : 'scrollLeft';
  /** @type {?} */
  const nodeName = element.nodeName;
  if (nodeName === 'BODY' || nodeName === 'HTML') {
    /** @type {?} */
    const html = element.ownerDocument.documentElement;
    /** @type {?} */
    const scrollingElement = element.ownerDocument.scrollingElement || html;
    return scrollingElement[upperSide];
  }
  return element[upperSide];
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} offsets
 * @return {?}
 */
function getClientRect(offsets) {
  return Object.assign(Object.assign({}, offsets), {
    right: offsets.left + offsets.width,
    bottom: offsets.top + offsets.height
  });
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @return {?}
 */
function getBoundingClientRect(element) {
  /** @type {?} */
  let rect = {};
  // IE10 10 FIX: Please, don't ask, the element isn't
  // considered in DOM in some circumstances...
  // This isn't reproducible in IE10 compatibility mode of IE11
  try {
    if (isIE(10)) {
      rect = element.getBoundingClientRect();
      /** @type {?} */
      const scrollTop = getScroll(element, 'top');
      /** @type {?} */
      const scrollLeft = getScroll(element, 'left');
      rect.top += scrollTop;
      rect.left += scrollLeft;
      rect.bottom += scrollTop;
      rect.right += scrollLeft;
    } else {
      rect = element.getBoundingClientRect();
    }
  } catch (e) {
    return undefined;
  }
  /** @type {?} */
  const result = {
    left: rect.left,
    top: rect.top,
    width: rect.right - rect.left,
    height: rect.bottom - rect.top
  };
  // subtract scrollbar size from sizes
  /** @type {?} */
  const sizes = element.nodeName === 'HTML' ? getWindowSizes(element.ownerDocument) : {};
  /** @type {?} */
  const width = sizes.width || element.clientWidth || result.right - result.left;
  /** @type {?} */
  const height = sizes.height || element.clientHeight || result.bottom - result.top;
  /** @type {?} */
  let horizScrollbar = element.offsetWidth - width;
  /** @type {?} */
  let vertScrollbar = element.offsetHeight - height;
  // if an hypothetical scrollbar is detected, we must be sure it's not a `border`
  // we make this check conditional for performance reasons
  if (horizScrollbar || vertScrollbar) {
    /** @type {?} */
    const styles = getStyleComputedProperty(element);
    horizScrollbar -= getBordersSize(styles, 'x');
    vertScrollbar -= getBordersSize(styles, 'y');
    result.width -= horizScrollbar;
    result.height -= vertScrollbar;
  }
  return getClientRect(result);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} rect
 * @param {?} element
 * @param {?=} subtract
 * @return {?}
 */
function includeScroll(rect, element, subtract = false) {
  /** @type {?} */
  const scrollTop = getScroll(element, 'top');
  /** @type {?} */
  const scrollLeft = getScroll(element, 'left');
  /** @type {?} */
  const modifier = subtract ? -1 : 1;
  rect.top += scrollTop * modifier;
  rect.bottom += scrollTop * modifier;
  rect.left += scrollLeft * modifier;
  rect.right += scrollLeft * modifier;
  return rect;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} children
 * @param {?} parent
 * @param {?=} fixedPosition
 * @return {?}
 */
function getOffsetRectRelativeToArbitraryNode(children, parent, fixedPosition = false) {
  /** @type {?} */
  const isIE10 = isIE(10);
  /** @type {?} */
  const isHTML = parent.nodeName === 'HTML';
  /** @type {?} */
  const childrenRect = getBoundingClientRect(children);
  /** @type {?} */
  const parentRect = getBoundingClientRect(parent);
  /** @type {?} */
  const scrollParent = getScrollParent(children);
  /** @type {?} */
  const styles = getStyleComputedProperty(parent);
  /** @type {?} */
  const borderTopWidth = parseFloat(styles.borderTopWidth);
  /** @type {?} */
  const borderLeftWidth = parseFloat(styles.borderLeftWidth);
  // In cases where the parent is fixed, we must ignore negative scroll in offset calc
  if (fixedPosition && isHTML) {
    parentRect.top = Math.max(parentRect.top, 0);
    parentRect.left = Math.max(parentRect.left, 0);
  }
  /** @type {?} */
  let offsets = getClientRect({
    top: childrenRect.top - parentRect.top - borderTopWidth,
    left: childrenRect.left - parentRect.left - borderLeftWidth,
    width: childrenRect.width,
    height: childrenRect.height
  });
  offsets.marginTop = 0;
  offsets.marginLeft = 0;
  // Subtract margins of documentElement in case it's being used as parent
  // we do this only on HTML because it's the only element that behaves
  // differently when margins are applied to it. The margins are included in
  // the box of the documentElement, in the other cases not.
  if (!isIE10 && isHTML) {
    /** @type {?} */
    const marginTop = parseFloat(styles.marginTop);
    /** @type {?} */
    const marginLeft = parseFloat(styles.marginLeft);
    offsets.top -= borderTopWidth - marginTop;
    offsets.bottom -= borderTopWidth - marginTop;
    offsets.left -= borderLeftWidth - marginLeft;
    offsets.right -= borderLeftWidth - marginLeft;
    // Attach marginTop and marginLeft because in some circumstances we may need them
    offsets.marginTop = marginTop;
    offsets.marginLeft = marginLeft;
  }
  if (isIE10 && !fixedPosition ? parent.contains(scrollParent) : parent === scrollParent && scrollParent.nodeName !== 'BODY') {
    offsets = includeScroll(offsets, parent);
  }
  return offsets;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @param {?=} excludeScroll
 * @return {?}
 */
function getViewportOffsetRectRelativeToArtbitraryNode(element, excludeScroll = false) {
  /** @type {?} */
  const html = element.ownerDocument.documentElement;
  /** @type {?} */
  const relativeOffset = getOffsetRectRelativeToArbitraryNode(element, html);
  /** @type {?} */
  const width = Math.max(html.clientWidth, window.innerWidth || 0);
  /** @type {?} */
  const height = Math.max(html.clientHeight, window.innerHeight || 0);
  /** @type {?} */
  const scrollTop = !excludeScroll ? getScroll(html) : 0;
  /** @type {?} */
  const scrollLeft = !excludeScroll ? getScroll(html, 'left') : 0;
  /** @type {?} */
  const offset = {
    top: scrollTop - Number(relativeOffset.top) + Number(relativeOffset.marginTop),
    left: scrollLeft - Number(relativeOffset.left) + Number(relativeOffset.marginLeft),
    width,
    height
  };
  return getClientRect(offset);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @return {?}
 */
function isFixed(element) {
  /** @type {?} */
  const nodeName = element.nodeName;
  if (nodeName === 'BODY' || nodeName === 'HTML') {
    return false;
  }
  if (getStyleComputedProperty(element, 'position') === 'fixed') {
    return true;
  }
  return isFixed(getParentNode(element));
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @return {?}
 */
function getFixedPositionOffsetParent(element) {
  // This check is needed to avoid errors in case one of the elements isn't defined for any reason
  if (!element || !element.parentElement || isIE()) {
    return document.documentElement;
  }
  /** @type {?} */
  let el = element.parentElement;
  while (el && getStyleComputedProperty(el, 'transform') === 'none') {
    el = el.parentElement;
  }
  return el || document.documentElement;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} target
 * @param {?} host
 * @param {?=} padding
 * @param {?=} boundariesElement
 * @param {?=} fixedPosition
 * @return {?}
 */
function getBoundaries(target, host, padding = 0, boundariesElement, fixedPosition = false) {
  // NOTE: 1 DOM access here
  // NOTE: 1 DOM access here
  /** @type {?} */
  let boundaries = {
    top: 0,
    left: 0
  };
  /** @type {?} */
  const offsetParent = fixedPosition ? getFixedPositionOffsetParent(target) : findCommonOffsetParent(target, host);
  // Handle viewport case
  if (boundariesElement === 'viewport') {
    boundaries = getViewportOffsetRectRelativeToArtbitraryNode(offsetParent, fixedPosition);
  } else {
    // Handle other cases based on DOM element used as boundaries
    /** @type {?} */
    let boundariesNode;
    if (boundariesElement === 'scrollParent') {
      boundariesNode = getScrollParent(getParentNode(host));
      if (boundariesNode.nodeName === 'BODY') {
        boundariesNode = target.ownerDocument.documentElement;
      }
    } else if (boundariesElement === 'window') {
      boundariesNode = target.ownerDocument.documentElement;
    } else {
      boundariesNode = boundariesElement;
    }
    /** @type {?} */
    const offsets = getOffsetRectRelativeToArbitraryNode(boundariesNode, offsetParent, fixedPosition);
    // In case of HTML, we need a different computation
    if (boundariesNode.nodeName === 'HTML' && !isFixed(offsetParent)) {
      const {
        height,
        width
      } = getWindowSizes(target.ownerDocument);
      boundaries.top += offsets.top - offsets.marginTop;
      boundaries.bottom = Number(height) + Number(offsets.top);
      boundaries.left += offsets.left - offsets.marginLeft;
      boundaries.right = Number(width) + Number(offsets.left);
    } else {
      // for all the other DOM elements, this one is good
      boundaries = offsets;
    }
  }
  // Add paddings
  boundaries.left += padding;
  boundaries.top += padding;
  boundaries.right -= padding;
  boundaries.bottom -= padding;
  return boundaries;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} __0
 * @return {?}
 */
function getArea({
  width,
  height
}) {
  return width * height;
}
/**
 * @param {?} placement
 * @param {?} refRect
 * @param {?} target
 * @param {?} host
 * @param {?=} allowedPositions
 * @param {?=} boundariesElement
 * @param {?=} padding
 * @return {?}
 */
function computeAutoPlacement(placement, refRect, target, host, allowedPositions = ['top', 'bottom', 'right', 'left'], boundariesElement = 'viewport', padding = 0) {
  if (placement.indexOf('auto') === -1) {
    return placement;
  }
  /** @type {?} */
  const boundaries = getBoundaries(target, host, padding, boundariesElement);
  /** @type {?} */
  const rects = {
    top: {
      width: boundaries.width,
      height: refRect.top - boundaries.top
    },
    right: {
      width: boundaries.right - refRect.right,
      height: boundaries.height
    },
    bottom: {
      width: boundaries.width,
      height: boundaries.bottom - refRect.bottom
    },
    left: {
      width: refRect.left - boundaries.left,
      height: boundaries.height
    }
  };
  /** @type {?} */
  const sortedAreas = Object.keys(rects).map(
  /**
  * @param {?} key
  * @return {?}
  */
  key => Object.assign(Object.assign({
    key
  }, rects[key]), {
    area: getArea(rects[key])
  })).sort(
  /**
  * @param {?} a
  * @param {?} b
  * @return {?}
  */
  (a, b) => b.area - a.area);
  /** @type {?} */
  let filteredAreas = sortedAreas.filter(
  /**
  * @param {?} __0
  * @return {?}
  */
  ({
    width,
    height
  }) => {
    return width >= target.clientWidth && height >= target.clientHeight;
  });
  filteredAreas = filteredAreas.filter(
  /**
  * @param {?} position
  * @return {?}
  */
  position => {
    return allowedPositions.some(
    /**
    * @param {?} allowedPosition
    * @return {?}
    */
    allowedPosition => {
      return allowedPosition === position.key;
    });
  });
  /** @type {?} */
  const computedPlacement = filteredAreas.length > 0 ? filteredAreas[0].key : sortedAreas[0].key;
  /** @type {?} */
  const variation = placement.split(' ')[1];
  // for tooltip on auto position
  target.className = target.className.replace(/bs-tooltip-auto/g, `bs-tooltip-${computedPlacement}`);
  return computedPlacement + (variation ? `-${variation}` : '');
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} data
 * @return {?}
 */
function getOffsets(data) {
  return {
    width: data.offsets.target.width,
    height: data.offsets.target.height,
    left: Math.floor(data.offsets.target.left),
    top: Math.round(data.offsets.target.top),
    bottom: Math.round(data.offsets.target.bottom),
    right: Math.floor(data.offsets.target.right)
  };
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Get the opposite placement of the given one
 * @param {?} placement
 * @return {?}
 */
function getOppositePlacement(placement) {
  /** @type {?} */
  const hash = {
    left: 'right',
    right: 'left',
    bottom: 'top',
    top: 'bottom'
  };
  return placement.replace(/left|right|bottom|top/g,
  /**
  * @param {?} matched
  * @return {?}
  */
  matched => ( /** @type {?} */hash)[matched]);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Get the opposite placement variation of the given one
 * @param {?} variation
 * @return {?}
 */
function getOppositeVariation(variation) {
  if (variation === 'right') {
    return 'left';
  } else if (variation === 'left') {
    return 'right';
  }
  return variation;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Get the outer sizes of the given element (offset size + margins)
 * @param {?} element
 * @return {?}
 */
function getOuterSizes(element) {
  /** @type {?} */
  const window = element.ownerDocument.defaultView;
  /** @type {?} */
  const styles = window.getComputedStyle(element);
  /** @type {?} */
  const x = parseFloat(styles.marginTop || 0) + parseFloat(styles.marginBottom || 0);
  /** @type {?} */
  const y = parseFloat(styles.marginLeft || 0) + parseFloat(styles.marginRight || 0);
  return {
    width: Number(element.offsetWidth) + y,
    height: Number(element.offsetHeight) + x
  };
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} target
 * @param {?} host
 * @param {?=} fixedPosition
 * @return {?}
 */
function getReferenceOffsets(target, host, fixedPosition = null) {
  /** @type {?} */
  const commonOffsetParent = fixedPosition ? getFixedPositionOffsetParent(target) : findCommonOffsetParent(target, host);
  return getOffsetRectRelativeToArbitraryNode(host, commonOffsetParent, fixedPosition);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} target
 * @param {?} hostOffsets
 * @param {?} position
 * @return {?}
 */
function getTargetOffsets(target, hostOffsets, position) {
  /** @type {?} */
  const placement = position.split(' ')[0];
  // Get target node sizes
  /** @type {?} */
  const targetRect = getOuterSizes(target);
  // Add position, width and height to our offsets object
  /** @type {?} */
  const targetOffsets = {
    width: targetRect.width,
    height: targetRect.height
  };
  // depending by the target placement we have to compute its offsets slightly differently
  /** @type {?} */
  const isHoriz = ['right', 'left'].indexOf(placement) !== -1;
  /** @type {?} */
  const mainSide = isHoriz ? 'top' : 'left';
  /** @type {?} */
  const secondarySide = isHoriz ? 'left' : 'top';
  /** @type {?} */
  const measurement = isHoriz ? 'height' : 'width';
  /** @type {?} */
  const secondaryMeasurement = !isHoriz ? 'height' : 'width';
  ( /** @type {?} */targetOffsets)[mainSide] = hostOffsets[mainSide] + hostOffsets[measurement] / 2 - targetRect[measurement] / 2;
  ( /** @type {?} */targetOffsets)[secondarySide] = placement === secondarySide ? hostOffsets[secondarySide] - targetRect[secondaryMeasurement] : ( /** @type {?} */hostOffsets)[getOppositePlacement(secondarySide)];
  return targetOffsets;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Helper used to know if the given modifier is enabled.
 * @param {?} options
 * @param {?} modifierName
 * @return {?}
 */
function isModifierEnabled(options, modifierName) {
  return options && options.modifiers && options.modifiers[modifierName] && options.modifiers[modifierName].enabled;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Tells if a given input is a number
 * @param {?} n
 * @return {?}
 */
function isNumeric(n) {
  return n !== '' && !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} data
 * @param {?=} renderer
 * @return {?}
 */
function updateContainerClass(data, renderer) {
  /** @type {?} */
  const target = data.instance.target;
  /** @type {?} */
  let containerClass = target.className;
  if (data.placementAuto) {
    containerClass = containerClass.replace(/bs-popover-auto/g, `bs-popover-${data.placement}`);
    containerClass = containerClass.replace(/bs-tooltip-auto/g, `bs-tooltip-${data.placement}`);
    containerClass = containerClass.replace(/\sauto/g, ` ${data.placement}`);
    if (containerClass.indexOf('popover') !== -1 && containerClass.indexOf('popover-auto') === -1) {
      containerClass += ' popover-auto';
    }
    if (containerClass.indexOf('tooltip') !== -1 && containerClass.indexOf('tooltip-auto') === -1) {
      containerClass += ' tooltip-auto';
    }
  }
  containerClass = containerClass.replace(/left|right|top|bottom/g, `${data.placement.split(' ')[0]}`);
  if (renderer) {
    renderer.setAttribute(target, 'class', containerClass);
    return;
  }
  target.className = containerClass;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} element
 * @param {?} styles
 * @param {?=} renderer
 * @return {?}
 */
function setStyles(element, styles, renderer) {
  Object.keys(styles).forEach(
  /**
  * @param {?} prop
  * @return {?}
  */
  prop => {
    /** @type {?} */
    let unit = '';
    // add unit if the value is numeric and is one of the following
    if (['width', 'height', 'top', 'right', 'bottom', 'left'].indexOf(prop) !== -1 && isNumeric(styles[prop])) {
      unit = 'px';
    }
    if (renderer) {
      renderer.setStyle(element, prop, `${String(styles[prop])}${unit}`);
      return;
    }
    element.style[prop] = String(styles[prop]) + unit;
  });
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} data
 * @return {?}
 */
function arrow(data) {
  /** @type {?} */
  let targetOffsets = data.offsets.target;
  // if arrowElement is a string, suppose it's a CSS selector
  /** @type {?} */
  const arrowElement = data.instance.target.querySelector('.arrow');
  // if arrowElement is not found, don't run the modifier
  if (!arrowElement) {
    return data;
  }
  /** @type {?} */
  const isVertical = ['left', 'right'].indexOf(data.placement.split(' ')[0]) !== -1;
  /** @type {?} */
  const len = isVertical ? 'height' : 'width';
  /** @type {?} */
  const sideCapitalized = isVertical ? 'Top' : 'Left';
  /** @type {?} */
  const side = sideCapitalized.toLowerCase();
  /** @type {?} */
  const altSide = isVertical ? 'left' : 'top';
  /** @type {?} */
  const opSide = isVertical ? 'bottom' : 'right';
  /** @type {?} */
  const arrowElementSize = getOuterSizes(arrowElement)[len];
  /** @type {?} */
  const placementVariation = data.placement.split(' ')[1];
  // top/left side
  if (data.offsets.host[opSide] - arrowElementSize < ( /** @type {?} */targetOffsets)[side]) {
    ( /** @type {?} */targetOffsets)[side] -= ( /** @type {?} */targetOffsets)[side] - (data.offsets.host[opSide] - arrowElementSize);
  }
  // bottom/right side
  if (Number(( /** @type {?} */data).offsets.host[side]) + Number(arrowElementSize) > ( /** @type {?} */targetOffsets)[opSide]) {
    ( /** @type {?} */targetOffsets)[side] += Number(( /** @type {?} */data).offsets.host[side]) + Number(arrowElementSize) - Number(( /** @type {?} */targetOffsets)[opSide]);
  }
  targetOffsets = getClientRect(targetOffsets);
  // Compute the sideValue using the updated target offsets
  // take target margin in account because we don't have this info available
  /** @type {?} */
  const css = getStyleComputedProperty(data.instance.target);
  /** @type {?} */
  const targetMarginSide = parseFloat(css[`margin${sideCapitalized}`]);
  /** @type {?} */
  const targetBorderSide = parseFloat(css[`border${sideCapitalized}Width`]);
  // compute center of the target
  /** @type {?} */
  let center;
  if (!placementVariation) {
    center = Number(( /** @type {?} */data).offsets.host[side]) + Number(data.offsets.host[len] / 2 - arrowElementSize / 2);
  } else {
    /** @type {?} */
    const targetBorderRadius = parseFloat(css.borderRadius);
    /** @type {?} */
    const targetSideArrowOffset = Number(targetMarginSide + targetBorderSide + targetBorderRadius);
    center = side === placementVariation ? Number(( /** @type {?} */data).offsets.host[side]) + targetSideArrowOffset : Number(( /** @type {?} */data).offsets.host[side]) + Number(data.offsets.host[len] - targetSideArrowOffset);
  }
  /** @type {?} */
  let sideValue = center - ( /** @type {?} */targetOffsets)[side] - targetMarginSide - targetBorderSide;
  // prevent arrowElement from being placed not contiguously to its target
  sideValue = Math.max(Math.min(targetOffsets[len] - arrowElementSize, sideValue), 0);
  data.offsets.arrow = {
    [side]: Math.round(sideValue),
    [altSide]: '' // make sure to unset any eventual altSide value from the DOM node
  };
  data.instance.arrow = arrowElement;
  return data;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} data
 * @return {?}
 */
function flip(data) {
  data.offsets.target = getClientRect(data.offsets.target);
  if (!isModifierEnabled(data.options, 'flip')) {
    data.offsets.target = Object.assign(Object.assign({}, data.offsets.target), getTargetOffsets(data.instance.target, data.offsets.host, data.placement));
    return data;
  }
  /** @type {?} */
  const boundaries = getBoundaries(data.instance.target, data.instance.host, 0,
  // padding
  'viewport', false // positionFixed
  );
  /** @type {?} */
  let placement = data.placement.split(' ')[0];
  /** @type {?} */
  let variation = data.placement.split(' ')[1] || '';
  /** @type {?} */
  const offsetsHost = data.offsets.host;
  /** @type {?} */
  const target = data.instance.target;
  /** @type {?} */
  const host = data.instance.host;
  /** @type {?} */
  const adaptivePosition = computeAutoPlacement('auto', offsetsHost, target, host, data.options.allowedPositions);
  /** @type {?} */
  const flipOrder = [placement, adaptivePosition];
  /* tslint:disable-next-line: cyclomatic-complexity */
  flipOrder.forEach(
  /**
  * @param {?} step
  * @param {?} index
  * @return {?}
  */
  (step, index) => {
    if (placement !== step || flipOrder.length === index + 1) {
      return data;
    }
    placement = data.placement.split(' ')[0];
    // using floor because the host offsets may contain decimals we are not going to consider here
    /** @type {?} */
    const overlapsRef = placement === 'left' && Math.floor(data.offsets.target.right) > Math.floor(data.offsets.host.left) || placement === 'right' && Math.floor(data.offsets.target.left) < Math.floor(data.offsets.host.right) || placement === 'top' && Math.floor(data.offsets.target.bottom) > Math.floor(data.offsets.host.top) || placement === 'bottom' && Math.floor(data.offsets.target.top) < Math.floor(data.offsets.host.bottom);
    /** @type {?} */
    const overflowsLeft = Math.floor(data.offsets.target.left) < Math.floor(boundaries.left);
    /** @type {?} */
    const overflowsRight = Math.floor(data.offsets.target.right) > Math.floor(boundaries.right);
    /** @type {?} */
    const overflowsTop = Math.floor(data.offsets.target.top) < Math.floor(boundaries.top);
    /** @type {?} */
    const overflowsBottom = Math.floor(data.offsets.target.bottom) > Math.floor(boundaries.bottom);
    /** @type {?} */
    const overflowsBoundaries = placement === 'left' && overflowsLeft || placement === 'right' && overflowsRight || placement === 'top' && overflowsTop || placement === 'bottom' && overflowsBottom;
    // flip the variation if required
    /** @type {?} */
    const isVertical = ['top', 'bottom'].indexOf(placement) !== -1;
    /** @type {?} */
    const flippedVariation = isVertical && variation === 'left' && overflowsLeft || isVertical && variation === 'right' && overflowsRight || !isVertical && variation === 'left' && overflowsTop || !isVertical && variation === 'right' && overflowsBottom;
    if (overlapsRef || overflowsBoundaries || flippedVariation) {
      if (overlapsRef || overflowsBoundaries) {
        placement = flipOrder[index + 1];
      }
      if (flippedVariation) {
        variation = getOppositeVariation(variation);
      }
      data.placement = placement + (variation ? ` ${variation}` : '');
      data.offsets.target = Object.assign(Object.assign({}, data.offsets.target), getTargetOffsets(data.instance.target, data.offsets.host, data.placement));
    }
  });
  return data;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} targetElement
 * @param {?} hostElement
 * @param {?} position
 * @param {?} options
 * @return {?}
 */
function initData(targetElement, hostElement, position, options) {
  /** @type {?} */
  const hostElPosition = getReferenceOffsets(targetElement, hostElement);
  if (!position.match(/^(auto)*\s*(left|right|top|bottom)*$/) && !position.match(/^(left|right|top|bottom)*(?: (left|right|top|bottom))?\s*(start|end)*$/)) {
    /* tslint:disable-next-line: no-parameter-reassignment */
    position = 'auto';
  }
  /** @type {?} */
  const placementAuto = !!position.match(/auto/g);
  // support old placements 'auto left|right|top|bottom'
  /** @type {?} */
  let placement = position.match(/auto\s(left|right|top|bottom)/) ? position.split(' ')[1] || 'auto' : position;
  // Normalize placements that have identical main placement and variation ("right right" => "right").
  /** @type {?} */
  const matches = placement.match(/^(left|right|top|bottom)* ?(?!\1)(left|right|top|bottom)?/);
  if (matches) {
    placement = matches[1] + (matches[2] ? ` ${matches[2]}` : '');
  }
  // "left right", "top bottom" etc. placements also considered incorrect.
  if (['left right', 'right left', 'top bottom', 'bottom top'].indexOf(placement) !== -1) {
    placement = 'auto';
  }
  /** @type {?} */
  const targetOffset = getTargetOffsets(targetElement, hostElPosition, placement);
  placement = computeAutoPlacement(placement, hostElPosition, targetElement, hostElement, options ? options.allowedPositions : undefined);
  return {
    options,
    instance: {
      target: targetElement,
      host: hostElement,
      arrow: null
    },
    offsets: {
      target: targetOffset,
      host: hostElPosition,
      arrow: null
    },
    positionFixed: false,
    placement,
    placementAuto
  };
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} data
 * @return {?}
 */
function preventOverflow(data) {
  if (!isModifierEnabled(data.options, 'preventOverflow')) {
    return data;
  }
  // NOTE: DOM access here
  // resets the targetOffsets's position so that the document size can be calculated excluding
  // the size of the targetOffsets element itself
  /** @type {?} */
  const transformProp = 'transform';
  /** @type {?} */
  const targetStyles = data.instance.target.style;
  // assignment to help minification
  const {
    top,
    left,
    [transformProp]: transform
  } = targetStyles;
  targetStyles.top = '';
  targetStyles.left = '';
  targetStyles[transformProp] = '';
  /** @type {?} */
  const boundaries = getBoundaries(data.instance.target, data.instance.host, 0,
  // padding
  'scrollParent', false // positionFixed
  );
  // NOTE: DOM access here
  // restores the original style properties after the offsets have been computed
  targetStyles.top = top;
  targetStyles.left = left;
  targetStyles[transformProp] = transform;
  /** @type {?} */
  const order = ['left', 'right', 'top', 'bottom'];
  /** @type {?} */
  const check = {
    /**
     * @param {?} placement
     * @return {?}
     */
    primary(placement) {
      /** @type {?} */
      let value = ( /** @type {?} */data).offsets.target[placement];
      if (( /** @type {?} */data).offsets.target[placement] < boundaries[placement] && !false // options.escapeWithReference
      ) {
        value = Math.max(( /** @type {?} */data).offsets.target[placement], boundaries[placement]);
      }
      return {
        [placement]: value
      };
    },
    /**
     * @param {?} placement
     * @return {?}
     */
    secondary(placement) {
      /** @type {?} */
      const mainSide = placement === 'right' ? 'left' : 'top';
      /** @type {?} */
      let value = data.offsets.target[mainSide];
      if (( /** @type {?} */data).offsets.target[placement] > boundaries[placement] && !false // escapeWithReference
      ) {
        value = Math.min(data.offsets.target[mainSide], boundaries[placement] - (placement === 'right' ? data.offsets.target.width : data.offsets.target.height));
      }
      return {
        [mainSide]: value
      };
    }
  };
  /** @type {?} */
  let side;
  order.forEach(
  /**
  * @param {?} placement
  * @return {?}
  */
  placement => {
    side = ['left', 'top'].indexOf(placement) !== -1 ? 'primary' : 'secondary';
    data.offsets.target = Object.assign(Object.assign({}, data.offsets.target), ( /** @type {?} */check)[side](placement));
  });
  return data;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @param {?} data
 * @return {?}
 */
function shift(data) {
  /** @type {?} */
  const placement = data.placement;
  /** @type {?} */
  const basePlacement = placement.split(' ')[0];
  /** @type {?} */
  const shiftVariation = placement.split(' ')[1];
  if (shiftVariation) {
    const {
      host,
      target
    } = data.offsets;
    /** @type {?} */
    const isVertical = ['bottom', 'top'].indexOf(basePlacement) !== -1;
    /** @type {?} */
    const side = isVertical ? 'left' : 'top';
    /** @type {?} */
    const measurement = isVertical ? 'width' : 'height';
    /** @type {?} */
    const shiftOffsets = {
      start: {
        [side]: host[side]
      },
      end: {
        [side]: host[side] + host[measurement] - target[measurement]
      }
    };
    data.offsets.target = Object.assign(Object.assign({}, target), {
      [side]: side === shiftVariation ? ( /** @type {?} */shiftOffsets).start[side] : ( /** @type {?} */shiftOffsets).end[side]
    });
  }
  return data;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class Positioning {
  /**
   * @param {?} hostElement
   * @param {?} targetElement
   * @param {?=} round
   * @return {?}
   */
  position(hostElement, targetElement, round = true) {
    return this.offset(hostElement, targetElement, false);
  }
  /**
   * @param {?} hostElement
   * @param {?} targetElement
   * @param {?=} round
   * @return {?}
   */
  offset(hostElement, targetElement, round = true) {
    return getReferenceOffsets(targetElement, hostElement);
  }
  /**
   * @param {?} hostElement
   * @param {?} targetElement
   * @param {?} position
   * @param {?=} appendToBody
   * @param {?=} options
   * @return {?}
   */
  positionElements(hostElement, targetElement, position, appendToBody, options) {
    /** @type {?} */
    const chainOfModifiers = [flip, shift, preventOverflow, arrow];
    return chainOfModifiers.reduce(
    /**
    * @param {?} modifiedData
    * @param {?} modifier
    * @return {?}
    */
    (modifiedData, modifier) => modifier(modifiedData), initData(targetElement, hostElement, position, options));
  }
}
/** @type {?} */
const positionService = new Positioning();
/**
 * @param {?} hostElement
 * @param {?} targetElement
 * @param {?} placement
 * @param {?=} appendToBody
 * @param {?=} options
 * @param {?=} renderer
 * @return {?}
 */
function positionElements(hostElement, targetElement, placement, appendToBody, options, renderer) {
  /** @type {?} */
  const data = positionService.positionElements(hostElement, targetElement, placement, appendToBody, options);
  /** @type {?} */
  const offsets = getOffsets(data);
  setStyles(targetElement, {
    'will-change': 'transform',
    top: '0px',
    left: '0px',
    transform: `translate3d(${offsets.left}px, ${offsets.top}px, 0px)`
  }, renderer);
  if (data.instance.arrow) {
    setStyles(data.instance.arrow, data.offsets.arrow, renderer);
  }
  updateContainerClass(data, renderer);
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @record
 */
function PositioningOptions() {}
if (false) {
  /**
   * The DOM element, ElementRef, or a selector string of an element which will be moved
   * @type {?|undefined}
   */
  PositioningOptions.prototype.element;
  /**
   * The DOM element, ElementRef, or a selector string of an element which the element will be attached to
   * @type {?|undefined}
   */
  PositioningOptions.prototype.target;
  /**
   * A string of the form 'vert-attachment horiz-attachment' or 'placement'
   * - placement can be "top", "bottom", "left", "right"
   * not yet supported:
   * - vert-attachment can be any of 'top', 'middle', 'bottom'
   * - horiz-attachment can be any of 'left', 'center', 'right'
   * @type {?|undefined}
   */
  PositioningOptions.prototype.attachment;
  /**
   * A string similar to `attachment`. The one difference is that, if it's not provided,
   * `targetAttachment` will assume the mirror image of `attachment`.
   * @type {?|undefined}
   */
  PositioningOptions.prototype.targetAttachment;
  /**
   * A string of the form 'vert-offset horiz-offset'
   * - vert-offset and horiz-offset can be of the form "20px" or "55%"
   * @type {?|undefined}
   */
  PositioningOptions.prototype.offset;
  /**
   * A string similar to `offset`, but referring to the offset of the target
   * @type {?|undefined}
   */
  PositioningOptions.prototype.targetOffset;
  /**
   * If true component will be attached to body
   * @type {?|undefined}
   */
  PositioningOptions.prototype.appendToBody;
}
class PositioningService {
  /**
   * @param {?} ngZone
   * @param {?} rendererFactory
   * @param {?} platformId
   */
  constructor(ngZone, rendererFactory, platformId) {
    this.update$$ = new Subject();
    this.positionElements = new Map();
    this.isDisabled = false;
    if (isPlatformBrowser(platformId)) {
      ngZone.runOutsideAngular(
      /**
      * @return {?}
      */
      () => {
        this.triggerEvent$ = merge(fromEvent(window, 'scroll', {
          passive: true
        }), fromEvent(window, 'resize', {
          passive: true
        }), /* tslint:disable-next-line: deprecation */
        of(0, animationFrameScheduler), this.update$$);
        this.triggerEvent$.subscribe(
        /**
        * @return {?}
        */
        () => {
          if (this.isDisabled) {
            return;
          }
          this.positionElements
          /* tslint:disable-next-line: no-any */.forEach(
          /**
          * @param {?} positionElement
          * @return {?}
          */
          positionElement => {
            positionElements(_getHtmlElement(positionElement.target), _getHtmlElement(positionElement.element), positionElement.attachment, positionElement.appendToBody, this.options, rendererFactory.createRenderer(null, null));
          });
        });
      });
    }
  }
  /**
   * @param {?} options
   * @return {?}
   */
  position(options) {
    this.addPositionElement(options);
  }
  /**
   * @return {?}
   */
  get event$() {
    return this.triggerEvent$;
  }
  /**
   * @return {?}
   */
  disable() {
    this.isDisabled = true;
  }
  /**
   * @return {?}
   */
  enable() {
    this.isDisabled = false;
  }
  /**
   * @param {?} options
   * @return {?}
   */
  addPositionElement(options) {
    this.positionElements.set(_getHtmlElement(options.element), options);
  }
  /**
   * @return {?}
   */
  calcPosition() {
    this.update$$.next();
  }
  /**
   * @param {?} elRef
   * @return {?}
   */
  deletePositionElement(elRef) {
    this.positionElements.delete(_getHtmlElement(elRef));
  }
  /**
   * @param {?} options
   * @return {?}
   */
  setOptions(options) {
    this.options = options;
  }
}
PositioningService.decorators = [{
  type: Injectable
}];
/** @nocollapse */
PositioningService.ctorParameters = () => [{
  type: NgZone
}, {
  type: RendererFactory2
}, {
  type: Number,
  decorators: [{
    type: Inject,
    args: [PLATFORM_ID]
  }]
}];
if (false) {
  /**
   * @type {?}
   * @private
   */
  PositioningService.prototype.options;
  /**
   * @type {?}
   * @private
   */
  PositioningService.prototype.update$$;
  /**
   * @type {?}
   * @private
   */
  PositioningService.prototype.positionElements;
  /**
   * @type {?}
   * @private
   */
  PositioningService.prototype.triggerEvent$;
  /**
   * @type {?}
   * @private
   */
  PositioningService.prototype.isDisabled;
}
/**
 * @param {?} element
 * @return {?}
 */
function _getHtmlElement(element) {
  // it means that we got a selector
  if (typeof element === 'string') {
    return document.querySelector(element);
  }
  if (element instanceof ElementRef) {
    return element.nativeElement;
  }
  return element;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { Positioning, PositioningService, positionElements };
