<header class="container-fluid bg-light">
    <div class="row align-items-center">
      <!-- Left Logo -->
      <div class="col-2 text-center">
        <img src="assets/tz_logo.png" alt="Left Logo" class="img-fluid">
      </div>
  
      <!-- Carousel (Middle Content) -->
    <div class="col-8">
        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
          <div class="carousel-inner">
            <!-- Slide 1 -->
            <div class="carousel-item active">
              <div class="row text-center">
                <div class="col">
                  <i class="fas fa-award"></i>
                  <span class="carousel-text">Mon to Fri 7:30–15:30</span>
                </div>
                <div class="col">
                  <i class="fas fa-ribbon"></i>
                  <span class="carousel-text">Keko Mwanga, DSM, TZ</span>
                </div>
                <div class="col">
                  <i class="fas fa-clock"></i>
                  <span class="carousel-text">info&#64;msd.go.tz</span>
                </div>
              </div>
            </div>
  
            <!-- Slide 2 -->
            <div class="carousel-item">
              <div class="row text-center">
                <div class="col">
                  <i class="fas fa-envelope"></i>
                  <span class="carousel-text">+255 222 860890</span>
                </div>
                <div class="col">
                    <i class="fas fa-ribbon"></i>
                    <span class="carousel-text">Keko Mwanga, DSM, TZ</span>
                  </div>
                  <div class="col">
                    <i class="fas fa-clock"></i>
                    <span class="carousel-text">info&#64;msd.go.tz</span>
                  </div>
              </div>
            </div>
  
            <!-- Add more carousel-item blocks as needed -->
          </div>
        </div>
      </div>
  
      <!-- Right Logo -->
      <div class="col-2 text-center">
        <img src="assets/msd.png" alt="Right Logo" class="img-fluid">
      </div>
    </div>
  </header>
  