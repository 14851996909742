import { Component, OnInit } from '@angular/core';
import { Facility } from './facility.model';
import { CommonModule } from '@angular/common';
import { TopNavbarComponent } from '../../shared/top-navbar/top-navbar.component';
import { HeaderComponent } from '../../shared/header/header.component';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';
import { LayoutsComponent } from '../../pages/layouts/layouts.component';
import { FacilityService } from './service/facility.service';
import { LoadingSpinnerComponent } from '../../shared/loading/loading-spinner/loading-spinner.component';
import { MatDialog } from '@angular/material/dialog';
// import { constructor } from 'assert';
import { FacilityFormComponent } from './facility-form/facility-form.component';

@Component({
  selector: 'app-facility',
  standalone: true,
  imports: [CommonModule, TopNavbarComponent, SidebarComponent, LayoutsComponent, LoadingSpinnerComponent],
  templateUrl: './facility.component.html',
  styleUrl: './facility.component.css'
})


export class FacilityComponent implements OnInit {

  facilities: any;
  loading = true;  // loading state
  currentPage = 1;
  totalPages = 0;
  pageSize = 10


  constructor(private facilityservice: FacilityService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.fetchFacilities(this.currentPage);
  }
  fetchFacilities(page: number) {
    this.facilityservice.getFacility(page).subscribe((data: any) => {

      //   if(data){

      //     this.facilities = data.results;
      //     this.loading = false;
      //     console.log('facility data:', this.facilities);
      //   }else{
      //     alert('No data Found!')
      //   }

      //  })
      if (data) {
        // Flatten the facilities data, adding councilname to each facility
        const flattenedFacilities = data.results.flatMap((council: any) =>
          council.facilities.map((facility: any) => ({
            ...facility,
            councilname: council.councilname
          }))
        );

        this.totalPages = Math.ceil(flattenedFacilities.length / this.pageSize);
        this.facilities = flattenedFacilities.slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        );

        this.loading = false;
        console.log('Flattened facility data:', this.facilities);
      } else {
        alert('No data found!');
      }
    });

  }
  // Pagination actions
  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      // this.fetchUsers();
      this.fetchFacilities(this.currentPage);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      // this.fetchUsers();
      this.fetchFacilities(this.currentPage);
    }
  }
  openAddFacilityDialog() {
    const dialogRef = this.dialog.open(FacilityFormComponent, {
      width: '800%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Facility was successfully added');
      }
    });
  }
}




