import { Component, Input, input, OnInit, Signal, signal } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MenuItem } from '../custom-sidenav/custom-sidenav.component';
import { MenuService } from './menu.service';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [MatListModule,MatIconModule,RouterModule,MatIcon,CommonModule],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.css'
})
export class MenuItemComponent {
 
  // item = input.required<MenuItem>();
  // collapsed = input(true);

  // nestedMenuOpen = signal(false);
  

  // toggledNested(){
  //   if(!this.item().subItems){
  //     return;
  //   }
  //   this.nestedMenuOpen.set(!this.nestedMenuOpen());
  // }
 
  @Input() item!: MenuItem;
  @Input() collapsed: boolean = true;

  nestedMenuOpen = false;

  constructor(private menuService: MenuService) {
    this.menuService.currentMenu$.subscribe(menuId => {
      this.nestedMenuOpen = (menuId === this.item.label);
    });
  }

  toggledNested() {
    if (!this.item.subItems) {
      return;
    }
    
    if (this.nestedMenuOpen) {
      this.menuService.closeMenu(); 
    } else {
      this.menuService.openMenu(this.item.label); 
    }
  }

}
