import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  private apiUrl = environment.apiEndpoint;
  // private url = 'https://api.msd.atomatiki.tech/' 
  // private url = 'http://172.16.7.25:5003/configurations/api/v1/facility-list/' 
  // private url = 'https://45.55.198.41:5002/configurations/api/v1/facility/'

  constructor(private http: HttpClient) { }

  getFacility(page: number): Observable<any> {
    const token = localStorage.getItem('access_token');
    console.log("token append to disbursement:", token);

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.get<any>(`${this.apiUrl}configurations/api/v1/facility-list/?page=${page}`, { headers });
  }

}
