import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

interface User {
  email: string;
  password: string;
  role: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = 'https://your-api-url.com/login'; //moses API will come here

  private users: User[] = [
    { email: 'kigombola@gmail.com', password: 'admin123', role: 'admin' },
    { email: 'gody@gmail.com', password: 'user123', role: 'user' }
  ];

  private isAuthenticated = false;
  private userRole: string | null = null;

  login(email: string, password: string): Observable<boolean> {
    const user = this.users.find(u => u.email === email && u.password === password);
    if (user) {
      this.isAuthenticated = true;
      this.userRole = user.role;
      localStorage.setItem('role', user.role);
      return of(true);
    } else {
      this.isAuthenticated = false;
      return of(false);
    }
  }

  logout(): void {
    this.isAuthenticated = false;
    this.userRole = null;
    localStorage.removeItem('role');
  }

  getRole(): string | null {
    return this.userRole || localStorage.getItem('role');
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }

  // private isAuthenticated = false;
  // private userRole: string | null = null;

  // constructor(private http: HttpClient) {}

  // login(email: string, password: string): Observable<boolean> {
  //   return this.http.post<{ token: string, role: string }>(this.apiUrl, { email, password })
  //     .pipe(
  //       tap(response => {
  //         if (response.token) {
  //           localStorage.setItem('token', response.token);
  //           this.isAuthenticated = true;
  //           this.userRole = response.role;
  //         }
  //       }),
  //       tap(() => this.isAuthenticated)
  //     );
  // }

  // logout(): void {
  //   localStorage.removeItem('token');
  //   this.isAuthenticated = false;
  //   this.userRole = null;
  // }

  // getRole(): string | null {
  //   return this.userRole;
  // }

  // isLoggedIn(): boolean {
  //   return this.isAuthenticated;
  // }
}
