<app-top-navbar></app-top-navbar>

<div class="content-area">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/account/purchase-requisition">Disbursements</a></li>
            <li class="breadcrumb-item active" aria-current="page">details</li>
        </ol>
    </nav>

    <div class="max-w-screen-xl mx-auto p-4">
        <div class="bg-white rounded-lg shadow-lg p-6">
            <div class="mt-4 text-center">
                <!-- <h2 class="text-xl font-semibold">{{ user.first_name }} {{ user.last_name }}</h2>
                <p class="text-gray-500">{{ user.category }} ({{ user.institution }})</p> -->
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-semibold text-center">Requisition Details</h3>
                <ul class="mt-2 space-y-2">
                    <!-- <pre>{{user| json}}</pre> -->
                    <li>
                        <span class="text-gray-500"><strong>Order Name:</strong></span> <span
                            class="text-blue-600">{{disbursements.ordername }} </span>
                    </li>
                    <li>
                        <span class="text-gray-500"><strong>Amount:</strong></span> <span
                            class="text-blue-600">{{disbursements.amount }} {{disbursements.currency}} </span>
                    </li>
                    <li>
                        <span class="text-gray-500"><strong>Remark:</strong></span> <span class="text-gray-700">{{disbursements.remark}}</span>
                    </li>
                    <li>
                        <span class="text-gray-500"><strong>Source Of Fund:</strong></span> <span class="text-gray-700">{{disbursements.sourceid}}</span>
                    </li>
                    <li>
                        <span class="text-gray-500"><strong>Program:</strong></span> <span
                            class="text-gray-700">{{disbursements.programid}}</span>
                    </li>

                </ul>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-semibold text-center">Product Details</h3>
               
               
                <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product Code</th>
                        <th>Due Date</th>
                        <th>Quantity</th>
                        <th>Unit of Measure</th>
                        <th>Unit Price</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of products; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.productcode }}</td>
                        <td>{{ item.duedate | date:'yyyy-MM-dd' }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.uom }}</td>
                        <td>{{ item.unitprice }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </table>
                <!-- <ul class="mt-2 space-y-2">
                    <li *ngFor="let item of disbursements.items">
                        <span class="text-gray-500"><strong>Product Code:</strong></span>
                        <span class="text-blue-600">{{ item.productcode }}</span>
                    </li>
                    <li *ngFor="let item of disbursements.items">
                        <span class="text-gray-500"><strong>Product Name:</strong></span>
                        <span class="text-gray-700">{{ item.productname }}</span>
                    </li>
                    <li *ngFor="let item of disbursements.items">
                        <span class="text-gray-500"><strong>UoM:</strong></span>
                        <span class="text-gray-700">{{ item.uom }}</span>
                    </li>
                    <li *ngFor="let item of disbursements.items">
                        <span class="text-gray-500"><strong>Quantity:</strong></span>
                        <span class="text-gray-700">{{ item.quantity }}</span>
                    </li>
                    <li *ngFor="let item of disbursements.items">
                        <span class="text-gray-500"><strong>Due Date:</strong></span>
                        <span class="text-gray-700">{{ item.duedate }}</span>
                    </li>
                </ul> -->
            </div>
         
        </div>
       
    </div>
</div>