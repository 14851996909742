import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';

import { Order, Stock } from '../facility-order/orders.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-facility-out-ofstock',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,SidebarComponent,CommonModule,MainDashboardComponent,LoadingSpinnerComponent,FormsModule],
  templateUrl: './facility-out-ofstock.component.html',
  styleUrl: './facility-out-ofstock.component.css'
})
export class FacilityOutOfstockComponent implements OnInit {
  loading = true;
  items: any;
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10; // 10 items per page as returned by the API
  totalItems: number = 0;
  filters = {
    facility: '',
    orderNo: '',
    startDate: '',
    endDate: ''
  };
  
  constructor(private router: Router, private http:  HttpClient){}

  ngOnInit(): void {
    this.loading = true;
   this.loadOutstock();
} 
private apiUrl = environment.apiEndpoint;
loadOutstock() {
  console.log('out of stock');
  const endpoint = `${this.apiUrl}orders/api/v1/out_of_stock_list?page=${this.currentPage}`; 
  const params: any = {};

  if (this.filters.facility) {
    params.facilitycode = this.filters.facility;
  }
  if (this.filters.orderNo) {
    params.orderno = this.filters.orderNo;
  }
  if (this.filters.startDate && this.filters.endDate) {
    params.startDate = this.filters.startDate;
    params.endDate = this.filters.endDate;
  }
    // Retrieve the token from localStorage
    const token = localStorage.getItem('access_token');

    // Set up the headers with Authorization token
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Append token to the Authorization header
    });
 
    console.log('token passed in header', headers);
  this.http.get(endpoint, { params, headers }).subscribe((data: any) => {
    this.totalItems = data.count; 
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.items = data;
    console.log('triggered outof stock:', this.items);
    this. loading = false;
    // this.items = data.results.map((order: any) => {

      // Format the items as a comma-separated string
      // order.formattedItems = order.items.map((item: any) => item.productcode).join(', ');
      // this. loading = false;
      // return order;
    // });
  });
}
applyFilters() {
  if (this.filters.orderNo) {
    // Fetch based on order number if provided
    const orderEndpoint = `https://api.msd.atomatiki.tech/orders/api/v1/api/v1/facility_orders/${this.filters.orderNo}/`;
    this.http.get(orderEndpoint).subscribe((data: any) => {
      this.items = [data]; // If the response is a single order, put it in an array
    });
  } else {
    // If order number is not provided, use other filters and fetch data
    this.currentPage = 1; // Reset to the first page when filters are applied
    this.loadOutstock();     // Reload orders with filters
  }
  // this.currentPage = 1; 
  // this.loadOrders();
}

loadNextPage() {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
    this.loadOutstock();
  }
  // this.currentPage++;
  // this.loadOutstock();
}

loadPreviousPage() {
  if (this.currentPage > 1) {
    this.currentPage--;
    this.loadOutstock();
  }
  
}
viewOrderDetails(orderNo: string) {
  this.router.navigate(['/outstock-details', orderNo]);  // Navigate to order details page
}

}
