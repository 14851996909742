import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-dummy-po',
  standalone: true,
  imports: [CommonModule,LayoutsComponent,TopNavbarComponent,SidebarComponent,FormsModule,MatDialogModule],
  templateUrl: './dummy-po.component.html',
  styleUrl: './dummy-po.component.css'
})
export class DummyPoComponent {

  poNumber: string = '';
  poDetails: any = null;

  constructor(private dialog: MatDialog){}

  getPoDetails() {
    // Replace with actual service call to fetch PO details
    this.poDetails = {
      requisitionNumber: '45453',
      poNumber: '3454546',
      orderDate: '12/06/2024',
      partNumber: '10010007',
      itemDescription: 'ABACAVIR SULPHATE 600MG',
      expectedDeliveryDate: '20/06/2024',
      deliveryStatus: 'pending',
      uom: '30TB',
      quantity: 200,
      expectedUnitPrice: 5000,
      actualUnitPrice: 4500
    };
  }

  createDummyPo() {
    // Handle the logic for creating a dummy PO
    this.dialog.open(SuccessDialogComponent);
    console.log('Dummy PO created');
  }

}
