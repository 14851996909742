<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/report/accounts">Accounts Report </a></li>
            <li class="breadcrumb-item active" aria-current="page">global fund</li>
        </ol>
    </nav>
    <div class="container mt-5">
        <h5>Global Fund Receipt</h5>
        
        <!-- Date range input section -->
        <div class="row">
            <div class="col-md-4">
                <label>From Date</label>
                <input type="date" [(ngModel)]="fromDate" class="form-control">
            </div>
            <div class="col-md-4">
                <label>To Date</label>
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>
            <div class="col-md-4">
                <button class="btn btn-primary mt-4" (click)="generateReport()">Generate Report</button>
            </div>
        </div>
        
        <!-- Display Report Section (including Heading) -->
        <div *ngIf="reportGenerated" class="mt-5">
            <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF" (click)="downloadPDF()">
                <i class="fas fa-file-pdf"></i>
              </button>
            <!-- Report Header -->
            <div class="text-center">
                <h6><strong>MEDICAL STORES DEPARTMENT (MSD)</strong></h6>
                <p><strong>Report Title:</strong> GLOBAL FUND RECEIPT </p>
                <p><strong>Purpose:</strong> To show The Receipt by MSD from the global fund during the Period</p>
                <p><strong>From:</strong> {{ fromDate || '...' }} <strong>To:</strong> {{ toDate || '...' }}</p>
            </div>
            
            <!-- Report Table -->
            <table class="table table-bordered mt-4">
                <thead>
                    <tr>
                        <th>S/NO</th>
                        <th>Receipt Date</th>
                        <th>Grant Name</th>
                        <th>Program </th>
                        <th>Referrence Number </th>
                        <th>Description</th>
                        <th>Currency  </th>
                        <th>Amount</th>
                        <th>Remarks </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of reportData">
                        <td>{{ item.sno }}</td>
                        <td>{{ item.receiptdate }}</td>
                        <td>{{ item.grantname }}</td>
                        <td>{{ item.program }}</td>
                        <td>{{ item.referenceno }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.currency }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.remarks }}</td>
                    </tr>
                </tbody>
            </table>
            
            <!-- Button to Download PDF -->
            <!-- <button class="btn btn-success mt-3" (click)="downloadPDF()">Download PDF</button> -->
        </div>
    </div>
    
  
</div>