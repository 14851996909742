<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/audit/orders">Audit Trail</a></li>
                <li class="breadcrumb-item"><a href="/audit/orders">Orders</a></li>
                <li class="breadcrumb-item active" aria-current="page">Details</li>
            </ol>
        </nav>
        <!-- <h5>Facilities > Orders </h5> -->
        <div class="order-details-container">
            <div class="order-info">
                <h6>Order Details</h6>
                <p><strong>Facility:</strong> {{ orderDetails?.facilitycode }}</p>
                <p><strong>Order Number:</strong> {{ orderDetails?.orderno }}</p>
                <p><strong>Order Date:</strong> {{ orderDetails?.orderdate }}</p>
                <p><strong>Items Ordered:</strong></p>
                <!-- <app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <table class="table">
                    <thead>
                        <tr>

                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                            <th>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Check if there are items, and then loop through them -->
                        <ng-container *ngIf="orderDetails?.items?.length > 0; else noData">
                          <tr *ngFor="let item of orderDetails?.items">
                            <td>{{ item.productcode }}</td>
                            <td>{{ item.productname }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.uom }}</td>
                            <td>{{ item.unitprice }} {{ orderDetails?.currency }}</td>
                            <td>{{ item.amount }}</td>
                            <td>{{ item.remark }}</td>
                          </tr>
                        </ng-container>
                      
                        <!-- Template to show if no data is found -->
                        <ng-template #noData>
                          <tr>
                            <td colspan="7" class="text-center">No data found</td>
                          </tr>
                        </ng-template>
                      </tbody>
                </table>
                <!-- <ul>
                <li *ngFor="let item of orderDetails?.items">
                  {{ item.productcode }} - {{ item.quantity }} {{ item.uom }} - {{ item.unitprice }} {{ orderDetails.currency }}
                </li>
              </ul> -->
            </div>

            <!-- <div class="delivered-items">
              <h6>Delivered Items</h6>
              <p><strong>Facility:</strong> {{ deliveredItems?.facilitycode }}</p>
              <p><strong>Order Number:</strong> {{ deliveredItems?.orderno }}</p>
              <p><strong>Delivery Date:</strong> {{ deliveredItems?.receiveddate }}</p>
              <p><strong>Delivered Status:</strong> {{ deliveredItems?.deliverysatatus }}</p>
              <p><strong>Delivered By:</strong> {{ deliveredItems?.deliveredby }}</p>
              <p><strong>Items Delivered:</strong></p>
              <table class="table">
                <thead>
                  <tr>
                    <th>Product Code</th>
                    <th>Shipped Quantity</th>
                    <th>Received Quantity</th>
                    <th>Returned Quantity</th>
                    <th>UOM</th>
                    <th>Product</th>
                    <th>Replace Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let podItem of deliveredItems?.poditems">
                    <td>{{ podItem.productcode }}</td>
                    <td>{{ podItem.shippedquantity }}</td>
                    <td>{{ podItem.receivedquantity }}</td>
                    <td>{{ podItem.returnedquantity }}</td>
                    <td>{{ podItem.uom }}</td>
                    <td>{{ podItem.replacedproductdesc }}</td>
                    <td>{{ podItem.replacereason }}</td>
                  </tr>
                </tbody>
              </table>
            
            </div> -->
            <!-- <div class="close-button">
                <button (click)="close()" class="btn btn-secondary">Close</button>
              </div> -->
        </div>
        <div class="close-button">
            <button (click)="close()" class="btn btn-primary">Close</button>
        </div>
    </div>
</div>