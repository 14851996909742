import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-facility-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
  templateUrl: './facility-form.component.html',
  styleUrl: './facility-form.component.css'
})
export class FacilityFormComponent implements OnInit {
  facilityForm: FormGroup;
  councils: any[] = [];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public dialogRef: MatDialogRef<FacilityFormComponent>
  ) {
    this.facilityForm = this.fb.group({
      facilityno: [null, Validators.required],
      facilitycode: ['', Validators.required],
      facilityname: ['', Validators.required],
      facilitytype: ['', Validators.required],
      hfregcode: ['', Validators.required],
      ownershipauthority: ['', Validators.required],
      ownershipcategory: ['', Validators.required],
      isactive: [true],
      council: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getCouncils();
  }

  getCouncils(): void {
    this.http
      .get<any[]>('https://api.msd.atomatiki.tech/configurations/council/council_list')
      .subscribe(
        (response) => {
          this.councils = response;  // Assuming the response is an array of council objects
        },
        (error) => {
          console.error('Error fetching councils:', error);
        }
      );
  }

  onSubmit() {
    if (this.facilityForm.valid) {
      const facilityData = this.facilityForm.value;

      this.http
        .post('https://api.msd.atomatiki.tech/configurations/facility/facility_create', facilityData)
        .subscribe(
          (response) => {
            console.log('Facility added successfully:', response);
            this.dialogRef.close(true);
          },
          (error) => {
            console.error('Error adding facility:', error);
          }
        );
    }
  }
}