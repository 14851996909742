<ng-container>
    <div class="switchboard">
        <app-top-navbar></app-top-navbar>
    <!-- <app-header></app-header> -->
    <div class="header">
      <h6>Medical Store Department Portal Services</h6>
    </div>
    <div class="switchboard-container">
        <div class="switchboard-card" *ngFor="let item of switchboardItems" (click)="navigateTo(item.route!, item.label)">
          <div class="card-content">
            <i [ngClass]="item.icon"></i>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
    <!-- <div class="switchboard-container">
        <div class="switchboard-card" *ngFor="let item of switchboardItems" (click)="navigateTo(item.route!)">
            <div class="card-content">
                <i [ngClass]="item.icon"></i>
                <span>{{ item.label }}</span>
            </div>
        </div>
    </div> -->
    
  </div>
</ng-container>