import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-consumption-details',
  standalone: true,
  imports: [LoadingSpinnerComponent,TopNavbarComponent,CommonModule],
  templateUrl: './consumption-details.component.html',
  styleUrl: './consumption-details.component.css'
})
export class ConsumptionDetailsComponent {
  facilityNo!: string;  // The facility number passed in the route
  monthid!: string;
  consumptionDetails: any; // To store order details
  // deliveredItems: any; 
  loading = false; 

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.loading = true;
    // Get the order number from the route
   
    this.facilityNo = this.route.snapshot.paramMap.get('facilityNo')!;
    this.monthid = this.route.snapshot.paramMap.get('monthid')!;
    console.log('initiated facilityno:',  this.facilityNo);
    
    // Fetch the order details
    this.getConsumptionDetails();
    
   
  }
  private apiUrl = environment.apiEndpoint;
  getConsumptionDetails() {
    console.log('consumption function triggered');
    const consumptionDetailsEndpoint = `${this.apiUrl}orders/api/v1/facility_consumption/${this.facilityNo}/${this.monthid}`;
    this.http.get(consumptionDetailsEndpoint).subscribe((data: any) => {
      if(data){

        this.consumptionDetails = data;
        console.log('consumption data here:', this.consumptionDetails);
        this.loading=false;
      }else{
        alert("Something went wrong");
        this.loading=false;
      }
    });
  }


  close() {
    this.router.navigate(['/facility/consumption']);  
  }

}
