<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="report/facilities">Facility Report </a></li>
            <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
    </nav>
    <div class="container mt-5">
        <h5 class="text-center">Facility Reports</h5>
        <div class="row justify-content-center">
            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="/report/facility-consumption" class="card shadow-sm text-center text-decoration-none text-dark">
                        <div class="card-body">
                            <i class="fa fa-bank"></i>
                            <!-- <img src="assets/order-status-icon.png" alt="Orders Status" class="img-fluid mb-3" /> -->
                            <h5 class="card-title">Facility Consumption</h5>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="/report/facility-distribution" class="card shadow-sm text-center text-decoration-none text-dark">

                        <div class="card-body">
                            <i class="fa fa-server"></i>
                            <!-- <img src="assets/goods-msd-icon.png" alt="Goods Delivered " class="img-fluid mb-3" /> -->
                            <h5 class="card-title">Distribution to Facility</h5>
                        </div>
                    </a>
                </div>
            </div>


        </div>

    </div>

</div>