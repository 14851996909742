<app-top-navbar></app-top-navbar>

<div class="content-area">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/users/profile">User</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
    </nav>

    <div class="max-w-screen-xl mx-auto p-4">
        <div class="bg-white rounded-lg shadow-lg p-6">
            <div class="mt-4 text-center">
                <h2 class="text-xl font-semibold">{{ user.first_name }} {{ user.last_name }}</h2>
                <p class="text-gray-500">{{ user.category }} ({{ user.institution }})</p>
            </div>
            <div class="mt-6">
                <h3 class="text-lg font-semibold">Profile Information</h3>
                <ul class="mt-2 space-y-2">
                    <!-- <pre>{{user| json}}</pre> -->
                    <li>
                        <span class="text-gray-500">Email:</span> <a href="mailto:johndoe@example.com"
                            class="text-blue-600">{{user.email }}</a>
                    </li>
                    <li>
                        <span class="text-gray-500">Phone:</span> <span class="text-gray-700">{{user.phone}}</span>
                    </li>
                    <li>
                        <span class="text-gray-500">Role:</span> <span class="text-gray-700">{{user.category}}</span>
                    </li>
                    <li>
                        <span class="text-gray-500">Institution:</span> <span
                            class="text-gray-700">{{user.institution}}</span>
                    </li>

                </ul>
            </div>
            <!-- <div class="mt-6">
            <h3 class="text-lg font-semibold">Authentication</h3>
          
        </div> -->
            <!-- <div class="w-40">

            <a (click)="editProfile()"
                class="font-semibold mt-2 rounded-md ring ring-slate-50 shadow-md hover:text-inherit bg-slate-50 hover:bg-slate-100 text-sm"><i
                    class="fa fa-edit fa-md"></i>Edit profile</a>
        </div> -->
        </div>
        <div class="mt-6">
            <h3 class="text-lg font-semibold">Authentication</h3>

            <!-- Toggle for TOTP Authentication -->
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="totpToggle" (change)="onToggleTOTP($event)">
                <label class="form-check-label" for="totpToggle">Enable TOTP Authentication</label>
            </div>

            <!-- Toggle for SMS Authentication -->
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="smsToggle" (change)="onToggleSMS($event)" >
                <label class="form-check-label" for="smsToggle">Enable SMS Authentication</label>
            </div>

            <!-- TOTP Section (QR code and token input) -->
            <div *ngIf="showTOTP" class="mt-3">
                <p>Scan the QR code below with your authenticator app:</p>
                <!-- <img [src]="qrCode" alt="QR Code" *ngIf="qrCode"> -->
                <img [src]="qrCode" alt="QR Code" *ngIf="qrCode">

                <div class="form-group mt-3">
                    <label for="totpToken">Enter Token from Authenticator</label>
                    <input type="text" id="totpToken" class="form-control" [(ngModel)]="totpToken">
                </div>
                <button class="btn btn-primary mt-2" (click)="verifyTOTP()">Verify TOTP</button>
            </div>

            <!-- SMS Section (Phone number input and verification) -->
            <div *ngIf="showSMS" class="mt-3">
                <div class="form-group">
                    <label for="phoneNumber">Phone Number</label>
                    <input type="text" id="phoneNumber" class="form-control" [(ngModel)]="phoneNumber">
                </div>
                <button class="btn btn-primary mt-2" (click)="enableSMS()">Send</button>
            </div>

            <!-- Token Verification Section (Displayed after phone number is verified) -->
            <div *ngIf="showTokenInput" class="mt-3">
                <div class="form-group">
                    <label for="smsToken">Enter the Token you received</label>
                    <input type="text" id="smsToken" class="form-control" [(ngModel)]="smsToken">
                </div>
                <button class="btn btn-primary mt-2" (click)="verifyTokenSMS()">Verify Token</button>
            </div>
        </div>


        <!-- Change Password Section -->
        <div class="mt-8">
            <h3 class="text-lg font-semibold">Change Password</h3>
            <form (submit)="changePassword()">
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <div class="mb-4">
                    <label for="currentPassword" class="form-label">Current Password</label>
                    <input type="password" class="form-control" id="currentPassword" [(ngModel)]="currentPassword"
                        name="currentPassword">
                </div>

                <div class="mb-4">
                    <label for="newPassword" class="form-label">New Password</label>
                    <input type="password" class="form-control" id="newPassword" [(ngModel)]="newPassword"
                        name="newPassword">
                </div>

                <div class="mb-4">
                    <label for="confirmPassword" class="form-label">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="confirmPassword"
                        name="confirmPassword">
                </div>

                <div class="mt-6">
                    <button type="submit" class="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-800">Change
                        Password</button>
                </div>
            </form>
        </div>


    </div>
</div>