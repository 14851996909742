<div class="parent">
    <div class="container" id="container">

        <div class="form-container sign-in">
            <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
            <form [formGroup]="verifyForm" (ngSubmit)="verify2fa()">
              <img src="assets/msd.png" />
          
              
              <div class="password-container">
               
                <input type="text" id="token" formControlName="verifytoken" placeholder="Enter your Token..." /> 
              
              </div>
          
             
          
              <div class="row">
                <button type="submit" >Verify</button>
              </div>
            </form>
          </div>
          
        <div class="toggle-container">
            <!-- <div class="toggle"> -->
            <!-- <div class="toggle-panel toggle-left">
                    <h1>Welcome Back!</h1>
                    <p>Enter your personal details to use all of portal features</p>
                    <button class="hidden" id="login">Sign In</button>
                </div> -->
            <!-- <div class="toggle-panel toggle-right"> -->
            <!-- <h1>MSD PORTAL</h1> -->
            <!-- <p>Login with your personal details to use all of portal features</p> -->
            <!-- <button class="hidden" id="register">Sign Up</button> -->
            <!-- </div> -->
            <!-- </div> -->
        </div>

    </div>

</div>


