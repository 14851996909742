import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GrantCodeService {
  private mofCodeUrl = 'https://api.msd.atomatiki.tech/configurations/api/v1/mofcode'; // MoF Epicor base URL
  private msdCodeUrl = 'https://api.msd.atomatiki.tech/configurations/api/v1/transref'; // MSD ERP base URL

  constructor(private http: HttpClient) {}

  // Retrieve MoF Epicor grant code by mofcode
  getMofGrantCode(mofCode: string): Observable<any> {
    return this.http.get(`${this.mofCodeUrl}/${mofCode}`);
  }

  // Retrieve MSD ERP grant code by transref 
//   getMsdGrantCode(transref: number): Observable<any> {
    
//     return this.http.get(`${this.msdCodeUrl}/${transref}`);
//   }
getMsdGrantCode(mofcodeautonum: number): Observable<any> {
    
    return this.http.get(`${this.msdCodeUrl}/${mofcodeautonum}`);
  }

  // Save the mapped grant codes
  saveMappedCodes(transrefautonum: number, mappedData: any): Observable<any> {
    return this.http.post(`${this.msdCodeUrl}/${transrefautonum}`, mappedData);
  }
}
