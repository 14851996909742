import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemselectionService {

  private selectedItem!: string;

  setSelectedItem(item: string) {
    this.selectedItem = item;
  }

  getSelectedItem(): string {
    return this.selectedItem;
  }
}
