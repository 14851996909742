import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { UomDialogComponent } from '../../external-uom/uom-dialog/uom-dialog.component';

@Component({
  selector: 'app-ext-prod-dialog',
  standalone: true,
  imports: [CommonModule,MatDialogModule,
    MatInputModule,
    MatButtonModule,FormsModule],
  templateUrl: './ext-prod-dialog.component.html',
  styleUrl: './ext-prod-dialog.component.css'
})
export class ExtProdDialogComponent {
  msdPartno: string;

  constructor(
    public dialogRef: MatDialogRef<UomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient
  ) {
    this.msdPartno = ''; // Initialize internal UOM
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
