<app-top-navbar></app-top-navbar>
<div class="content-area ">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/receipt-details">Receipt</a></li>
        <li class="breadcrumb-item active" aria-current="page">List</li>
    </ol>
</nav>
    <!-- <p>Accounts > Receipt</p>      -->
       <!-- Filters for Date Range, Facility, and Order Number -->
    <div class="filters">
      <form (ngSubmit)="applyFilters()">
          <div class="row">
              <!-- Receipt Filter -->
              <div class="form-group col-md-6">
                  <label for="facility">Receipt Number</label>
                  <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility"
                      class="form-control">
              </div>
              <!-- A/C Number Filter -->
              <div class="form-group col-md-6">
                  <label for="orderNo">Bank A/C Number</label>
                  <input type="text" id="orderNo" [(ngModel)]="filters.orderNo" name="orderNo"
                      class="form-control">
              </div>
          </div>

          <div class="row">
              <!-- Start Date Filter -->
              
              <div class="form-group col-md-6">
                  <label for="startDate">Start Date</label>
                  <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate"
                      class="form-control">
              </div>
              <!-- End Date Filter -->
              <div class="form-group col-md-6">
                  <label for="endDate">End Date</label>
                  <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate"
                      class="form-control">
              </div>
          </div>

          <!-- Submit Button -->
          <div class="row">
              <div class="form-group col-md-12">
                  <button type="submit" class="btn btn-primary">Apply Filters</button>
              </div>
          </div>
      </form>
  </div>
   
        
        <div class="d-flex justify-content-between mb-2">
          <!-- Left Side: Export Buttons -->
          <div class="d-flex">
            <!-- PDF Export Button -->
            <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
              <i class="fas fa-file-pdf"></i>
            </button>
        
            <!-- XLS Export Button -->
            <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
              <i class="fas fa-file-excel"></i>
            </button>
        
            <!-- CSV Export Button -->
            <button class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
              <i class="fas fa-file-csv"></i>
            </button>
          </div>
        
          <!-- Right Side: Send All to Facility Button -->
          <div>
            <!-- <button (click)="NavigateToCreate()" class=" btn btn-success"  style="background-color: #005590;">Create New Receipt</button> -->
          </div>
        </div>
        
        <table class="table table-striped purchase-table">
          <thead>
            <tr>
                <th>S/N</th>
              <th>Receipt Number</th>
              <th>Banck A/C Number</th>
              <th>Receipt Date</th>
              <th>Period(FinYear)</th>
              <th>Payment Method</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of receiptDetails;let i = index">
              <td scope="row">{{ i + 1 }}</td>
              <td>{{ item.receiptno }}</td>
              <td>{{ item.bankaccountno }}</td>
              <td>{{ item.receiptdate | date }}</td>
              <td>{{ item.finyear }}</td>
              <td>{{ item.paymethod }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.currency }}</td>
              <td>
                <button  aria-label="view">
                  <i class="fas fa-eye"></i>
                </button>
                <!-- <button  aria-label="Search">
                    <i class="fas fa-search"></i>
                  </button>
                  <button  aria-label="Edit">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button  aria-label="Forward">
                    <i class="fas fa-share"></i>
                  </button> -->
              </td>
            </tr>
          </tbody>
        </table>
             <!-- Pagination controls -->
    <div class="pagination-controls">
      <button class="btn btn-warning" (click)="goToPreviousPage()" [disabled]="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button class="btn btn-success" (click)="goToNextPage()" [disabled]="currentPage === totalPages">Next</button>
    </div>
      </div>
      
