<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/report/orders">Orders Report </a></li>
            <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
    </nav>
    <div class="container mt-5">
        <h5 class="text-center">Orders Reports</h5>
        <div class="row justify-content-center">
            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="/report/order-status" class="card shadow-sm text-center text-decoration-none text-dark">
                        <div class="card-body">
                            <i class="fas fa-clock"></i>
                            <!-- <img src="assets/order-status-icon.png" alt="Orders Status" class="img-fluid mb-3" /> -->
                            <h5 class="card-title">Orders Status</h5>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="/report/proof-delivery" class="card shadow-sm text-center text-decoration-none text-dark">

                        <div class="card-body">
                            <i class="fas fa-ambulance"></i>
                            <!-- <img src="assets/goods-msd-icon.png" alt="Goods Delivered " class="img-fluid mb-3" /> -->
                            <h5 class="card-title">Proof of Delivery</h5>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="report/summary-requisition"
                        class="card shadow-sm text-center text-decoration-none text-dark">

                        <div class="card-body">
                            <i class="fas fa-archive"></i>
                            <!-- <img src="assets/goods-facilities-icon.png" alt="Goods Delivered" class="img-fluid mb-3" /> -->
                            <h5 class="card-title">summary Per Requisition</h5>
                        </div>
                    </a>
                </div>
            </div>


            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="/report/procurement-status"
                        class="card shadow-sm text-center text-decoration-none text-dark">

                        <div class="card-body">
                            <i class="	fas fa-bars"></i>
                            <!-- <img src="assets/orders-reconciliation-icon.png" alt="Orders Reconc"
                                class="img-fluid mb-3" /> -->
                            <h5 class="card-title">Procurement Status</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row justify-content-left">
            <div class="col-md-5 col-lg-3 mb-4">
                <div class="card shadow-sm text-center">
                    <a href="/report/summaryStatus-program"
                        class="card shadow-sm text-center text-decoration-none text-dark">

                        <div class="card-body">
                            <i class="fas fa-archive"></i>
                            <!-- <img src="assets/goods-facilities-icon.png" alt="Goods Delivered" class="img-fluid mb-3" /> -->
                            <h5 class="card-title">summary Status Per Program</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>