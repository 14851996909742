import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LayoutsComponent } from './pages/layouts/layouts.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SwitchboardComponent } from './pages/switchboard/switchboard.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { Component } from '@angular/core';
import { FacilityComponent } from './modules/facility/facility.component';
import { PartNumberComponent } from './modules/Setting/part-number/part-number.component';
import { ConfigurationComponent } from './modules/Setting/configuration/configuration.component';
import { AccountingCodeComponent } from './modules/Setting/accounting-code/accounting-code.component';
import { ProductComponent } from './modules/Setting/product/product.component';
import { ExternalPoService } from './modules/orders/external_Po/service/external-po.service';
import { ExternalpoComponent } from './modules/orders/external_Po/externalpo/externalpo.component';
import { NewReceiptComponent } from './modules/accounts/receipt/new-receipt/new-receipt.component';
import { RequestPartNumberComponent } from './modules/Setting/part-number/request-part-number/request-part-number.component';
import { DummyPoComponent } from './modules/orders/dummy-po/dummy-po.component';
import { PurchaseOrderComponent } from './modules/orders/purchase-order/purchase-order.component';
import { AuthGuard } from './pages/login/Service/auth.guard';
import { MainDashboardComponent } from './modules/main-dashboard/main-dashboard.component';
// import { BudgetDetailsComponent } from './modules/accounts/budget/budget-details/budget-details.component.ts';
import { GdfPartnumberComponent } from './modules/Setting/part-number/GDF_part_number/gdf-partnumber/gdf-partnumber.component';
import { GdfRequestPartNumberComponent } from './modules/Setting/part-number/GDF_part_number/gdf-request-part-number/gdf-request-part-number.component';
import { FacilityOrderComponent } from './modules/facilitymodule/facility-order/facility-order.component';
import { FacilityConsumptionComponent } from './modules/facilitymodule/facility-consumption/facility-consumption.component';
import { FacilityDeliveredComponent } from './modules/facilitymodule/facility-delivered/facility-delivered.component';
import { FacilityOutOfstockComponent } from './modules/facilitymodule/facility-out-ofstock/facility-out-ofstock.component';
import { UsersComponent } from './modules/userManagement/users/users/users.component';
import { CreateBudgetComponent } from './modules/accounts/budget/create-budget/create-budget.component';
import { PermissionComponent } from './modules/userManagement/permission/permission.component';
import { RoleComponent } from './modules/userManagement/role/role.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { OrderDetailsComponent } from './modules/facilitymodule/facility-order/order-details/order-details.component';
import { GrantCodeMappingComponent } from './modules/Setting/accounting-code/grant-code-mapping/grant-code-mapping.component';
import { ReceiptDetailsComponent } from './modules/accounts/receipt/receipt-details/receipt-details.component';
import { BudgetDetailsComponent } from './modules/accounts/budget/budget-details/budget-details.component';
import { OrderDeliveredComponent } from './modules/facilitymodule/facility-order/order-delivered/order-delivered.component';
import { ConsumptionDetailsComponent } from './modules/facilitymodule/facility-consumption/consumption-details/consumption-details.component';
import { ExternalPoDetailsComponentOnInit } from './modules/orders/external_Po/externalpo/external-po-details/external-po-details.component';
import { PoDetailsComponent } from './modules/orders/purchase-order/po-details/po-details.component';
import { ExternalUomComponent } from './modules/Setting/external-uom/external-uom.component';
import { PurchaseRequisitionComponent } from './modules/accounts/purchase-requisition/purchase-requisition.component';
import { PurchaseRequisitionCreateComponent } from './modules/accounts/purchase-requisition/purchase-requisition-create/purchase-requisition-create.component';
import { PaymentVoucherComponent } from './modules/accounts/payment-voucher/payment-voucher.component';
import { MsdAccountCodesComponent } from './modules/Setting/accounting-code/msd-account-codes/msd-account-codes.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ConfirmPasswordlinkComponent } from './pages/confirm-passwordlink/confirm-passwordlink.component';
import { AuthenticatorComponent } from './modules/userManagement/authenticator/authenticator.component';
import { CategoryComponent } from './modules/userManagement/category/category.component';
import { InstitutionComponent } from './modules/userManagement/institution/institution.component';
import { AuditordersComponent } from './modules/AuditTrail/auditorders/auditorders.component';
import { AuditaccountsComponent } from './modules/AuditTrail/auditaccounts/auditaccounts.component';
import { AuditconfigurationsComponent } from './modules/AuditTrail/auditconfigurations/auditconfigurations.component';
import { ViewusersComponent } from './modules/userManagement/users/viewusers/viewusers.component';
import { OrderReportScreenComponent } from './modules/Reports/Orders/order-report-screen/order-report-screen.component';
import { OrderStatusComponent } from './modules/Reports/Orders/order-status/order-status.component';
import { StockReportComponent } from './modules/Reports/stock-report/stock-report.component';
import { FacilityReportComponent } from './modules/Reports/facility-report/facility-report.component';
import { ProductReportComponent } from './modules/Reports/product-report/product-report.component';
import { AcoountsreportsComponent } from './modules/Reports/acoountsreports/acoountsreports.component';
import { RequisitionViewComponent } from './modules/accounts/purchase-requisition/requisition-view/requisition-view.component';
import { FacilityConsumptionReportComponent } from './modules/Reports/facility-report/facility-consumption-report/facility-consumption-report.component';
import { DistributionTofacilityReportComponent } from './modules/Reports/facility-report/distribution-tofacility-report/distribution-tofacility-report.component';
import { PpReportComponent } from './modules/Reports/product-report/pp-report/pp-report.component';
import { PpReceivedComponent } from './modules/Reports/product-report/pp-received/pp-received.component';
import { GlobalFundReceiptComponent } from './modules/Reports/acoountsreports/global-fund-receipt/global-fund-receipt.component';
import { ActualPaymentComponent } from './modules/Reports/acoountsreports/actual-payment/actual-payment.component';
import { LcPaymentComponent } from './modules/Reports/acoountsreports/lc-payment/lc-payment.component';
import { BurningRateComponent } from './modules/Reports/acoountsreports/burning-rate/burning-rate.component';
import { ChargedAgainstGRNComponent } from './modules/Reports/acoountsreports/charged-against-grn/charged-against-grn.component';
import { SummaryChargedComponent } from './modules/Reports/acoountsreports/summary-charged/summary-charged.component';
import { TransferGFAccountComponent } from './modules/Reports/acoountsreports/transfer-gf-account/transfer-gf-account.component';
import { ImplementorTransferComponent } from './modules/Reports/acoountsreports/implementor-transfer/implementor-transfer.component';
import { PodComponent } from './modules/Reports/Orders/pod/pod.component';
import { SummaryPerRequisitionComponent } from './modules/Reports/Orders/summary-per-requisition/summary-per-requisition.component';
import { ProcurementStatusComponent } from './modules/Reports/Orders/procurement-status/procurement-status.component';
import { StatusSummaryPerProgramComponent } from './modules/Reports/Orders/status-summary-per-program/status-summary-per-program.component';
import { AuditordersDetailsComponent } from './modules/AuditTrail/auditorders/auditorders-details/auditorders-details.component';
// import { ExternalPoDetailsComponent } from './modules/orders/external_Po/externalpo/external-po-details.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'reset',
        component: ResetPasswordComponent
    },
    // {
    //     path: 'confirmpassword',
    //     component:ConfirmPasswordlinkComponent
    // },
    {
        path: 'confirmpassword/:uid64/:token',
        component: ConfirmPasswordlinkComponent
    },
    {
        path: '2faverification',
        component: AuthenticatorComponent
    },
    {
        path: 'layout',
        component: LayoutsComponent,

    },
    {
        path: '',
        component: MainDashboardComponent,
        children: [

            {
                path: 'configuration/facilities',
                component: FacilityComponent
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                // canActivate: [AuthGuard] 
            },
            {
                path: 'configuration/partnumber',
                component: PartNumberComponent

            },
            {
                path: 'configuration/gdfpartnumber',
                component: GdfPartnumberComponent

            },
            {
                path: 'partnumber/request',
                component: RequestPartNumberComponent
            },
            {
                path: 'partnumber/gdfrequest',
                component: GdfRequestPartNumberComponent
            },
            // {
            //     path:'configuration',
            //     component:ConfigurationComponent
            // },
            {
                path: 'configuration/accountingcode',
                component: AccountingCodeComponent
            },
            {
                path: 'configuration/msdaccountingcode',
                component: MsdAccountCodesComponent
            },
            {
                path: 'configuration/externaluom',
                component: ExternalUomComponent
            },
            {
                path: 'code-mapping',
                component: GrantCodeMappingComponent
            },
            {
                path: 'configuration/product',
                component: ProductComponent
            },
            {
                path: 'order/externalpo',
                component: ExternalpoComponent
            },
            {
                path: 'externalorder-details/:orderNo/:extsystemid',
                component: ExternalPoDetailsComponentOnInit
            },
            {
                path: 'order/dummyPo',
                component: DummyPoComponent
            },
            {
                path: 'order/purchaseOrder',
                component: PurchaseOrderComponent
            },
            {
                path: 'po-details/:orderNo',
                component: PoDetailsComponent
            },
            {
                path: 'account/purchase-requisition',
                component: PurchaseRequisitionComponent
            },
            {
                path: 'account/payment-voucher',
                component: PaymentVoucherComponent
            },
            {
                path: 'account/receipt',
                component: NewReceiptComponent
            },
            {
                path: 'account/receipt-details',
                component: ReceiptDetailsComponent
            },
            {
                path: 'account/budget',
                component: BudgetDetailsComponent
            },
            {
                path: 'requisition-create',
                component: PurchaseRequisitionCreateComponent
            },
            {
                path: 'requisition-details/:id',
                component: RequisitionViewComponent
            },
            {
                path: 'budget-create',
                component: CreateBudgetComponent
            },
            {
                path: 'facility/order',
                component: FacilityOrderComponent
            },
            {
                path: 'order-details/:orderNo',
                component: OrderDetailsComponent
            },
            {
                path: 'auditorder-details/:orderNo',
                component: AuditordersDetailsComponent
            },
            {
                path: 'order-delivered/:orderNo',
                component: OrderDeliveredComponent
            },
            {
                path: 'facility/consumption',
                component: FacilityConsumptionComponent
            },
            {
                path: 'consumption-details/:facilityNo/:monthid',
                component: ConsumptionDetailsComponent
            },
            {
                path: 'facility/delivery',
                component: FacilityDeliveredComponent
            },
            {
                path: 'facility/outstock',
                component: FacilityOutOfstockComponent
            },
            {
                path: 'users/list',
                component: UsersComponent
            },
            {
                path: 'users/details/:id',
                component: ViewusersComponent
            },
            {
                path: 'users/profile',
                component: ProfileComponent
            },

            {
                path: 'users/permission',
                component: PermissionComponent
            },
            {
                path: 'users/role',
                component: RoleComponent
            },
            {
                path: 'users/category',
                component: CategoryComponent
            },
            {
                path: 'users/institution',
                component: InstitutionComponent
            },
            {
                path: 'audit/orders',
                component: AuditordersComponent
            },
            {
                path: 'audit/accounts',
                component: AuditaccountsComponent
            },
            {
                path: 'audit/configurations',
                component: AuditconfigurationsComponent
            },
            {
                path: 'report/orders',
                component: OrderReportScreenComponent
            },
            {
                path: 'report/order-status',
                component: OrderStatusComponent
            },
            {
                path: 'report/proof-delivery',
                component: PodComponent
            },
            {
                path: 'report/summary-requisition',
                component: SummaryPerRequisitionComponent
            },
            {
                path: 'report/procurement-status',
                component: ProcurementStatusComponent
            },
            {
                path: 'report/summaryStatus-program',
                component: StatusSummaryPerProgramComponent
            },
            {
                path: 'report/stocks',
                component: StockReportComponent
            },
            {
                path: 'report/facilities',
                component: FacilityReportComponent
            },
            {
                path: 'report/facility-consumption',
                component: FacilityConsumptionReportComponent
            },
            {
                path: 'report/facility-distribution',
                component: DistributionTofacilityReportComponent
            },
            {
                path: 'report/products',
                component: ProductReportComponent
            },
            {
                path: 'report/pp-report',
                component: PpReportComponent
            },
            {
                path: 'report/ppreceived-report',
                component: PpReceivedComponent
            },
            {
                path: 'report/accounts',
                component: AcoountsreportsComponent,
            },
            {
                path: 'report/globalFundReceipt',
                component: GlobalFundReceiptComponent
            },
            {
                path: 'report/actualPayment',
                component: ActualPaymentComponent
            },
            {
                path: 'report/LCPayment',
                component: LcPaymentComponent
            },
            {
                path: 'report/burningRate',
                component: BurningRateComponent
            },
            {
                path: 'report/chargedGRN',
                component: ChargedAgainstGRNComponent
            },
            {
                path: 'report/summaryCharged',
                component: SummaryChargedComponent
            },
            {
                path: 'report/transferGF',
                component: TransferGFAccountComponent
            },
            {
                path: 'report/implementorTransfer',
                component: ImplementorTransferComponent
            },


        ]
    },

    {
        path: 'switchboard',
        component: SwitchboardComponent,
    },
    {
        path: "main",
        component: MainDashboardComponent
    },
    // {
    //     path:'dashboard',
    //     component: DashboardComponent,

    // },

];
