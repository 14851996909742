import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';
import { Router } from '@angular/router';
import { Order } from './orders.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from '../../../shared/loading/loading-spinner/loading-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-facility-order',
  standalone: true,
  imports: [LayoutsComponent, TopNavbarComponent, SidebarComponent, CommonModule, MainDashboardComponent, FormsModule, LoadingSpinnerComponent],
  templateUrl: './facility-order.component.html',
  styleUrl: './facility-order.component.css'
})
export class FacilityOrderComponent implements OnInit {
  loading = true;
  // orders: Order[] = [];
  filteredOrders: any[] = [];  // Filtered data
  searchTerm = '';
  orders: any[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10; // 10 items per page as returned by the API
  totalItems: number = 0;
  filters = {
    facility: '',
    orderNo: '',
    startDate: '',
    endDate: ''
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }
  private apiUrl = environment.apiEndpoint;

  NavigateToCreate() {
    this.router.navigate(['partnumber/gdfrequest'])
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadOrders();

  }
  loadOrders2333() {
    const endpoint = `https://api.msd.atomatiki.tech/orders/api/v1/facility_order_list?page=${this.currentPage}`;
    const params: any = {};


    if (this.filters.facility) {
      params.facilitycode = this.filters.facility;
    }
    if (this.filters.orderNo) {
      params.orderno = this.filters.orderNo;
    }
    if (this.filters.startDate && this.filters.endDate) {
      params.startDate = this.filters.startDate;
      params.endDate = this.filters.endDate;
    }

    // Retrieve the token from localStorage
    const token = localStorage.getItem('access_token');

    // Set up the headers with Authorization token
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Append token to the Authorization header
    });
    console.log('headers:' + token);
    this.http.get(endpoint, { params, headers }).subscribe((data: any) => {
      this.totalItems = data.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      this.orders = data.results.map((order: any) => {
        // Format the items as a comma-separated string
        order.formattedItems = order.items.map((item: any) => item.productcode).join(', ');
        this.loading = false;
        return order;
      });
    });
  }
  loadOrders() {
    const endpoint = `${this.apiUrl}orders/api/v1/facility_order_list?page=${this.currentPage}`;
    const params: any = {};
  
    // Add your filter logic
    if (this.filters.facility) {
      params.facilitycode = this.filters.facility;
    }
    if (this.filters.orderNo) {
      params.orderno = this.filters.orderNo;
    }
    if (this.filters.startDate && this.filters.endDate) {
      params.startDate = this.filters.startDate;
      params.endDate = this.filters.endDate;
    }
  
    // Retrieve the token from localStorage
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Append token to the Authorization header
    });
  
    // Make the GET request with headers
    this.http.get(endpoint, { headers }).subscribe(
      (data: any) => {
        this.totalItems = data.count;
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.orders = data.results.map((order: any) => {
          // Get only the first 3 items
          const items = order.items.map((item: any) => item.productcode);
          const firstThreeItems = items.slice(0, 3).join(', ');
          const hasMoreItems = items.length > 3;
  
          order.formattedItems = firstThreeItems;
          order.moreItems = hasMoreItems; // Add the flag for "more items"
          this.loading = false;
          return order;
        });
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        if (error.error && error.error.detail) {
          this.toastr.error(error.error.detail);
        } else {
          this.toastr.error('Internal Server Error');
        }
        console.error('Retrieve order failed', error);
      }
    );
  }
  
  

  applyFilters() {
    this.filteredOrders = this.orders.filter(order => {
      // Check if each filter field matches or is empty
      return (
        (!this.filters.facility || order.facilitycode.includes(this.filters.facility)) &&
        (!this.filters.orderNo || order.orderno.includes(this.filters.orderNo)) &&
        (!this.filters.startDate || new Date(order.orderdate) >= new Date(this.filters.startDate)) &&
        (!this.filters.endDate || new Date(order.orderdate) <= new Date(this.filters.endDate))
      );
    });
    if (this.filters.orderNo) {
      // Fetch based on order number if provided
      const orderEndpoint = `${this.apiUrl}orders/api/v1/facility_orders/${this.filters.orderNo}/`;
      // const orderEndpoint = `https://api.msd.atomatiki.tech/orders/api/v1/api/v1/facility_orders/${this.filters.orderNo}/`;
      this.http.get(orderEndpoint).subscribe((data: any) => {
        this.orders = [data]; // If the response is a single order, put it in an array
      });
    } 
    if(this.filters.startDate) {
      const formatDate = (date: string): string => {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`; 
    };
    
    const startDateFormatted = formatDate(this.filters.startDate);
    const endDateFormatted = formatDate(this.filters.endDate);
      const url = `${this.apiUrl}orders/api/v1/facility_orders/${startDateFormatted}/${endDateFormatted}/`;
      this.http.get(url).subscribe(
        (data: any)=>{
        this.orders = data;
        console.log('date range filter:', this.orders);

            // If no orders are found, show a message
            if (this.orders.length === 0) {
              this.toastr.info('No orders found for the selected filters.');
          }
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.toastr.error('No facility orders found within the specified date range.');
        console.error('Retrieve order failed', error);
    })
         
    }
    else{
       
      this.currentPage = 1; // Reset to the first page when filters are applied
      this.loadOrders(); // Reload orders with filters
    }
    
    
  }

  applyFilter() {
    this.filteredOrders = this.orders.filter((order: { facilityname: string; facilityno: string; hfregcode: string; }) =>
      order.facilityname.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      order.facilityno.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      order.hfregcode.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  getDataToExport() {
    return this.filteredOrders.length ? this.filteredOrders : this.orders;
  }
  loadNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadOrders();
    }
    // this.currentPage++;
    // this.loadOrders(); 
  }

  loadPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadOrders();
    }

  }
  viewOrderDetails(orderNo: string) {
    this.router.navigate(['/order-details', orderNo]);  // Navigate to order details page
  }
  viewDelivered(orderNo: string) {
    this.router.navigate(['/order-delivered', orderNo]);  // Navigate to order details page
  }

  exportToPDF() {
    const doc = new jsPDF();
    const data = this.getDataToExport();

    doc.text('Order List', 10, 10);
    (doc as any).autoTable({
      head: [['S/N', 'Facility', 'Facility Name', 'Facility Number', 'Order Number', 'Order Date', 'Due Date', 'Order Items']],
      body: data.map((order, index) => [
        index + 1,
        order.facilitycode,
        order.facilityname,
        order.facilityno,
        order.orderno,
        new Date(order.orderdate).toLocaleDateString(),
        new Date(order.duedate).toLocaleDateString(),
        order.formattedItems
      ]),
      startY: 20
    });

    doc.save('orders.pdf');
  }

  exportToExcel() {
    const data = this.getDataToExport().map(order => ({
      'S/N': order.id,
      Facility: order.facilitycode,
      'Facility Name': order.facilityname,
      'Facility Number': order.facilityno,
      'Order Number': order.orderno,
      'Order Date': new Date(order.orderdate).toLocaleDateString(),
      'Due Date': new Date(order.duedate).toLocaleDateString(),
      'Order Items': order.formattedItems
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    XLSX.writeFile(workbook, 'orders.xlsx');
  }

  exportToCSV() {
    const data = this.getDataToExport().map(order => ({
      'S/N': order.id,
      Facility: order.facilitycode,
      'Facility Name': order.facilityname,
      'Facility Number': order.facilityno,
      'Order Number': order.orderno,
      'Order Date': new Date(order.orderdate).toLocaleDateString(),
      'Due Date': new Date(order.duedate).toLocaleDateString(),
      'Order Items': order.formattedItems
    }));

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'orders.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
