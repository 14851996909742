import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TopNavbarComponent } from '../../../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../../shared/loading/loading-spinner/loading-spinner.component';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-external-po-details',
  standalone: true,
  imports: [TopNavbarComponent,CommonModule,LoadingSpinnerComponent],
  templateUrl: './external-po-details.component.html',
  styleUrl: './external-po-details.component.css'
})
export class ExternalPoDetailsComponentOnInit {
  orderNo!: string;  // The order number passed in the route
  orderDetails: any; // To store order details
  extsystemid! : string
  loading = false; 

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.loading = true;
    // Get the order number from the route
    this.orderNo = this.route.snapshot.paramMap.get('orderNo')!;
    this.extsystemid= this.route.snapshot.paramMap.get('extsystemid')!;
    // Fetch the external order details
    this.getOrderDetails();
    
   
  }
  private apiUrl = environment.apiEndpoint;

  getOrderDetails() {
    console.log('external order function reached');
    const orderDetailsEndpoint = `${this.apiUrl}orders/api/v1/externalpos/${this.extsystemid}/${this.orderNo}`;
    this.http.get(orderDetailsEndpoint).subscribe((data: any) => {
      if(data){

        this.orderDetails = data;
        this.loading=false;
        console.log('external orders:', this.orderDetails);
      }else{
        alert("Something went wrong");
        this.loading=false;
      }
    });
  }

 
  close() {
    this.router.navigate(['/order/externalpo']);  
  }
}
