import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-voucher',
  standalone: true,
  imports: [],
  templateUrl: './payment-voucher.component.html',
  styleUrl: './payment-voucher.component.css'
})
export class PaymentVoucherComponent {

}
