import { Component } from '@angular/core';

@Component({
  selector: 'app-msd-account-codes',
  standalone: true,
  imports: [],
  templateUrl: './msd-account-codes.component.html',
  styleUrl: './msd-account-codes.component.css'
})
export class MsdAccountCodesComponent {

}
