import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LayoutsComponent } from '../../pages/layouts/layouts.component';
import { FooterComponent } from '../footer/footer.component';
import { ItemselectionService } from '../../pages/itemselection.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

export interface SidebarItem {
  label: string;
  icon: string;
  route: string;
}
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [ CommonModule,  
    RouterModule, MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule, 
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})



export class SidebarComponent implements OnInit {

  
  // menuItems = [
  //   {
  //     label: 'Settings',
  //     icon: 'fa fa-cog',
  //     isExpanded: false,
  //     defaultRoute: '/dashboard',
  //     children: [
  //       { label: 'Facility', route: '/facility', isActive: false },
  //       { label: 'Product', route: '/product', isActive: false }
  //     ]
  //   },
  //   {
  //     label: 'Order',
  //     icon: 'fa fa-shopping-cart',
  //     isExpanded: false,
  //     defaultRoute: '/dashboard',
  //     children: [
  //       { label: 'Health Facilities', route: '/health-facilities', isActive: false },
  //       { label: 'Orders', route: '/orders', isActive: false }
  //     ]
  //   }
  // ];

  // toggleExpand(item: any) {
  //   if (item.children && item.children.length) {
  //     item.isExpanded = !item.isExpanded;
  //   } else {
  //     this.navigateTo(item.defaultRoute);
  //   }
  // }

  // activateChild(parent: any, child: any) {
  //   // Deactivate all other children
  //   parent.children.forEach((c: any) => c.isActive = false);
  //   // Activate the selected child
  //   child.isActive = true;
  // }

  // navigateTo(route: string) {
  //   this.router.navigate([route]);
  // }

  // constructor(private router: Router) {}
  // ngOnInit(){

  // }
  sidebarItems: SidebarItem[] = [];

  constructor(private router: Router,private selectedItemService: ItemselectionService) {}

  ngOnInit() {
    const selectedItem = this.selectedItemService.getSelectedItem();
    this.sidebarItems = this.getSidebarItemsBasedOnSelected(selectedItem);
  }

  dropdownOpen: boolean = false;
  dropdownOpen2: boolean = false;
  dropdownOpen3: boolean = false;

  

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
   
  }
  toggleDropdown2(){
    this.dropdownOpen2 = !this.dropdownOpen2;
  }
  toggleDropdown3(){
    this.dropdownOpen3 = !this.dropdownOpen3;
  }

  activeRoute(route: string): boolean {
    return this.router.url === route;
  }
  getSidebarItemsBasedOnSelected(selectedItem: string) {
    const allItems = {
      'Setting/Configuration': [
        { label: 'partnumber', icon: 'fa fa-hospital', route: '/partnumber' },
        { label: 'Health Facilities', icon: 'fa fa-hospital', route: '/facilities' },
        { label: 'Product', icon: 'fa fa-cubes', route: '/product' },
        { label: 'Accounting Codes', icon: 'fa fa-cubes', route: '/accountingcode' },
        { label: 'Configuration', icon: 'fa fa-wrench', route: '/configuration' },
        { label: 'Apps', icon: 'fa fa-th-large', route: '/switchboard' },
      ],
      'Order': [
        { label: 'Health Facilities', icon: 'fa fa-hospital', route: '/facilities' }
      ],
      // other items here to follow 
    };

    return allItems[selectedItem as keyof typeof allItems] || [];
  }

}
