<div class="d">   
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <p>Facilities > Orders</p>
        
        <div class="container">
            <h2 class="text-center">Delivery Confirmation</h2>
          
            <!-- Search Section -->
            <div class="search-section">
              <input type="text" placeholder="Enter Order Number" class="form-control search-input" />
              <button class="btn btn-dark">Search</button>
            </div>
          
            <hr />
          
            <!-- Order Details -->
            <div class="order-details">
              <h4>Order Details</h4>
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>Facility Number</td>
                    <td>10010007</td>
                  </tr>
                  <tr>
                    <td>Order Number</td>
                    <td>3454546</td>
                  </tr>
                  <tr>
                    <td>Order Date</td>
                    <td>12/06/2024</td>
                  </tr>
                  <tr>
                    <td>Part Number</td>
                    <td>10010007</td>
                  </tr>
                  <tr>
                    <td>Item Description</td>
                    <td>ABACAVIR SULPHATE 600MG</td>
                  </tr>
                  <tr>
                    <td>Expected Delivery Date</td>
                    <td>20/06/2024</td>
                  </tr>
                  <tr>
                    <td>Delivery Status</td>
                    <td>pending</td>
                  </tr>
                  <tr>
                    <td>UOM</td>
                    <td>30TB</td>
                  </tr>
                  <tr>
                    <td>Quantity</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>Expected Unit Price</td>
                    <td>5,000</td>
                  </tr>
                  <tr>
                    <td>Actual Unit Price</td>
                    <td>4,500</td>
                  </tr>
                </tbody>
              </table>
            </div>
          
            <!-- Signature Section -->
            <div class="signature-section">
              <input type="text" placeholder="Sign here" class="form-control sign-input" />
              <button class="btn btn-success mt-2">Submit</button>
            </div>
          </div>
          
    </div>
</div>