<!-- <app-layouts></app-layouts> -->

    <!-- <app-sidebar></app-sidebar> -->
   
        <app-top-navbar></app-top-navbar>
        <div class="content-area ">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/configuration/product">Settings</a></li>
                <li class="breadcrumb-item active" aria-current="page">Accounting Codes</li>
            </ol>
        </nav>
            <!-- <p>Settings > Accounting Codes</p> -->
            <div class="product-container">
                <div class="product-actions">
                  <button class="btn btn-primary" style="background-color: #005590;" (click)="navigate()">Download new MoF codes</button>
                  <!-- <button class="btn btn-secondary">Grant code from MOF</button> -->
                </div>
                <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
                <table class="table table-striped purchase-table">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Grant Code</th>
                      <th scope="col">Description</th>
                      <th scope="col">Module</th>
                      <th scope="col">Program</th>
                      <th scope="col">Intervention</th>
                      <th scope="col">MSD code</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let code of codes; let i = index">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>{{ code.mofcode }}</td>
                      <td>{{ code.codedesc }}</td>
                      <td>{{ code.module }}</td>
                      <td>{{ code.programid }}</td>
                      <td>{{ code.intervention }}</td>
                      <td>{{ code.transref }}</td>
                      <td>
                        <!-- <i class="fa fa-eye"></i>
                        <i class="fa fa-edit"></i>
                        <i class="fa fa-trash"></i> -->
                        <button (click)="mapMsdcode(code)" aria-label="View">
                          <i class="fas fa-plus" data-bs-toggle="tooltip" title="map msdcode" style="cursor: pointer;"></i>
                        </button>
                        <!-- <button  aria-label="Edit">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button aria-label="Delete">
                          <i class="fas fa-trash"></i>
                        </button> -->
                      </td>
                    </tr>
                  </tbody>
                </table> 
              </div> 

        </div>
    
