<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/configuration/externaluom">External UOM</a></li>
                <li class="breadcrumb-item active" aria-current="page">List</li>
            </ol>
        </nav>

        <div class="facility-table">
            <div class="us" style=" align-items: center;">
                <!-- <h5 class="text-center">Orders Per Facility</h5> -->
            </div>
            <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
            <table class="table table-striped purchase-table">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>UOM</th>
                        <th>Internal UOM</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let uom of uoms; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ uom.uom }}</td>
                        <td>{{ uom.internaluom || 'N/A' }}</td>
                        <td>
                          <button *ngIf="!uom.internaluom"  (click)="mapUom(uom)"  class="btn btn-primary">
                            <!-- <i class="fas fa-plus"></i> Map -->
                            <i class="fas fa-plus mx-2" data-bs-toggle="tooltip" title="map uom" style="cursor: pointer;"></i>
                          </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


 <!-- Modal -->
 <!-- <div class="modal " id="mapuomModal" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="mapuomModalLabel">Add New User</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"(click)="closeModel()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="mapuomForm" (ngSubmit)="onSubmit()">
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="userType" class="form-label">User Type</label>
                            <select formControlName="userType" class="form-select">
                                <option value="">Select User Type</option>
                                <option value="internal">Internal User</option>
                                <option value="external">External User</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="affiliation" class="form-label">Affiliation</label>
                            <input type="text" class="form-control" formControlName="affiliation">
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label for="firstName" class="form-label">First Name</label>
                            <input type="text" class="form-control" formControlName="firstName">
                        </div>
                        <div class="col-md-4">
                            <label for="middleName" class="form-label">Middle Name</label>
                            <input type="text" class="form-control" formControlName="middleName">
                        </div>
                        <div class="col-md-4">
                            <label for="lastName" class="form-label">Last Name</label>
                            <input type="text" class="form-control" formControlName="lastName">
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="phone" class="form-label">Phone</label>
                            <input type="tel" class="form-control" formControlName="phone">
                        </div>
                        <div class="col-md-6">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" formControlName="email">
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="role" class="form-label">Role</label>
                            <select formControlName="role" class="form-select">
                                <option value="">Select Role</option>
                                <option value="admin">Super Admin</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                                <option value="viewer">Officer</option>
                            </select>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModel()">Close</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->