import { Component } from '@angular/core';

@Component({
  selector: 'app-burning-rate',
  standalone: true,
  imports: [],
  templateUrl: './burning-rate.component.html',
  styleUrl: './burning-rate.component.css'
})
export class BurningRateComponent {

}
