<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/facility/order">Facilities</a></li>
                <li class="breadcrumb-item active" aria-current="page">Orders</li>
            </ol>
        </nav>
        <!-- <h5>Facilities > Orders</h5> -->


        <!-- Filters for Date Range, Facility, and Order Number -->
        <div class="filters">
            <form (ngSubmit)="applyFilters()">
                <div class="row">
                    <!-- Facility Filter -->
                    <div class="form-group col-md-6">
                        <label for="facility">Facility Name</label>
                        <input type="text" class="form-control" placeholder="filter by facility name or hfregcode" [(ngModel)]="searchTerm"
                        (input)="applyFilter()" />
                        <!-- <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility"
                            class="form-control"  (input)="applyFilter()"> -->
                    </div>
                    <!-- Order Number Filter -->
                    <div class="form-group col-md-6">
                        <label for="orderNo">Order Number</label>
                        <input type="text" id="orderNo" [(ngModel)]="filters.orderNo" name="orderNo"
                            class="form-control">
                    </div>
                </div>

                <div class="row">
                    <!-- Start Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="startDate">Start Date</label>
                        <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate"
                            class="form-control">
                    </div>
                    <!-- End Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="endDate">End Date</label>
                        <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate"
                            class="form-control">
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="row">
                    <div class="form-group col-md-12">
                        <button type="submit" class="btn btn-primary">Apply Filters</button>
                    </div>
                </div>
            </form>
        </div>


        <div class="facility-table">
            <div class="d-flex justify-content-between mb-2">
                <!-- Left Side: Export Buttons -->
                <div class="d-flex">
                  <!-- PDF Export Button -->
                  <button (click)="exportToPDF()" class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
                    <i class="fas fa-file-pdf"></i>
                  </button>
              
                  <!-- XLS Export Button -->
                  <button (click)="exportToExcel()" class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
                    <i class="fas fa-file-excel"></i>
                  </button>
              
                  <!-- CSV Export Button -->
                  <button  (click)="exportToCSV()" class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
                    <i class="fas fa-file-csv"></i>
                  </button>
                </div>
              
                <!-- Right Side: Send All to Facility Button -->
                <div>
                  <!-- <button class="btn btn-primary" style="background-color: #005590;">Send All to Facility</button> -->
                </div>
              </div>
            <!-- <app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
         
            <table  class="table table-striped purchase-table">
                <thead>
                    <tr >
                        <th>S/N</th>
                        <th>Facility Code</th>
                        <th>Facility Name</th>
                        <th>Facility Number</th>
                        <th>Hfregcode</th>
                        <th>Order Number</th>
                        <th>Order Date</th>
                        <th>Status </th>
                        <th>Due Date</th>
                        <th>Order Items</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of orders; let i = index" >
                        <td>{{ i + 1 }}</td>
                        <td>{{ order.facilitycode }}</td>
                        <td>{{ order.facilityname }}</td>
                        <td>{{ order.facilityno }}</td>
                        <td>{{ order.hfregcode }}</td>
                        <td>{{ order.orderno }}</td>
                        <td>{{ order.orderdate | date }}</td>
                        <td>{{ order.status }}</td>
                        <td>{{ order.duedate | date }}</td>
                        <td>  {{ order.formattedItems }}
                            <span *ngIf="order.moreItems">
                              ...<button (click)="viewOrderDetails(order.orderno)">+ More Items</button>
                            </span>
                        </td>
                        <td>
                            <button (click)="viewOrderDetails(order.orderno)" aria-label="View">
                                <i class="fas fa-eye" data-bs-toggle="tooltip" title="Order Details"
                                    style="cursor: pointer;"></i>
                                <!-- <i class="fas fa-eye"></i> -->
                            </button>
                            <button (click)="viewDelivered(order.orderno)" aria-label="View">
                                <i class="fas fa-truck-fast" data-bs-toggle="tooltip" title="Delivered"
                                    style="cursor: pointer;"></i>
                                <!-- <i class="fas fa-eye"></i> -->
                            </button>
                            <!-- <button aria-label="Forward">
                                <i class="fas fa-arrow-right"></i>
                            </button> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Pagination Controls -->
        <div class="pagination-controls d-flex align-items-center">
            <button (click)="loadPreviousPage()" [disabled]="currentPage === 1"
                class="btn btn-warning">Previous</button>
            <!-- Display current page and total pages -->
            <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
            <button (click)="loadNextPage()" class="btn btn-success">Next</button>
        </div>
    </div>
</div>