<div class="d">
  <app-top-navbar></app-top-navbar>
  <div class="content-area ">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/account/budget">Budget</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
      </ol>
  </nav>
    <!-- Filters for Date Range, Facility, and Order Number -->
    <div class="filters">
      <form (ngSubmit)="applyFilters()">
          <div class="row">
              <!-- Facility Filter -->
              <!-- <div class="form-group col-md-6">
                  <label for="facility">Facility</label>
                  <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility"
                      class="form-control">
              </div> -->
              <!-- Order Number Filter -->
              <div class="form-group col-md-6">
                  <label for="orderNo">MoF code</label>
                  <input type="text" id="orderNo" [(ngModel)]="filters.orderNo" name="orderNo"
                      class="form-control">
              </div>
          </div>

          <div class="row">
              <!-- Start Date Filter -->
               <p>Budget Period Date Range:</p>
              <div class="form-group col-md-6">
                  <label for="startDate">Start Date</label>
                  <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate"
                      class="form-control">
              </div>
              <!-- End Date Filter -->
              <div class="form-group col-md-6">
                  <label for="endDate">End Date</label>
                  <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate"
                      class="form-control">
              </div>
          </div>

          <!-- Submit Button -->
          <div class="row">
              <div class="form-group col-md-12">
                  <button type="submit" class="btn btn-primary">Apply Filters</button>
              </div>
          </div>
      </form>
  </div>
  
    <div class="d-flex justify-content-between mb-2">
      <!-- Left Side: Export Buttons -->
      <div class="d-flex">
        <!-- PDF Export Button -->
        <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
          <i class="fas fa-file-pdf"></i>
        </button>
    
        <!-- XLS Export Button -->
        <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
          <i class="fas fa-file-excel"></i>
        </button>
    
        <!-- CSV Export Button -->
        <button class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
          <i class="fas fa-file-csv"></i>
        </button>
      </div>
    
      <!-- Right Side: Send All to Facility Button -->
      <div>
        <!-- <button class="btn  btn-success actionButton" [routerLink]="['/budget-create']">New Budget</button> -->
      </div>
    </div>
    <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
    <table class="table table-striped table-bordered user-table" *ngIf="budgetData.length > 0">        
      <thead>
        <tr>
          <th>S/N</th>
          <th>Budget Period</th>
          <th>MoF Code</th>
          <th>Amount</th>
          <th>Currency</th>
          <th>Opening Balance</th>
          <th>Trans Code</th>
          <th>Create Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let budget of budgetData;let i = index">
      
          <td>{{  i + 1}}</td>
          <td>{{ budget.finyear}}</td>
          <td>{{ budget.mofcode  }}</td>
          <td>{{ budget.amount }}</td>
          <td>{{ budget.currency }}</td>
          <td>{{ budget.openingbalance | currency }}</td>
          <td>{{ budget.transcode }}</td>
          <td>{{ budget.createdate | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
          <td>
            <!-- Action Buttons -->
            <i class="fas fa-eye" data-bs-toggle="tooltip" title="View" style="cursor: pointer;"></i>
            <!-- <i class="fas fa-edit mx-2" data-bs-toggle="tooltip" title="Edit" style="cursor: pointer;"></i>
            <i class="fas fa-trash" data-bs-toggle="tooltip" title="Delete" style="cursor: pointer;"></i> -->
            <!-- <i class="fas fa-lock mx-2" data-bs-toggle="tooltip" title="Change Password"
                          style="cursor: pointer;"></i> -->


          </td>
        </tr>
      </tbody>
    </table>

  </div>
   <!-- Pagination Controls -->
   <div class="pagination-controls d-flex align-items-center">
    <button (click)="loadPreviousPage()" [disabled]="currentPage === 1"
        class="btn btn-warning">Previous</button>
    <!-- Display current page and total pages -->
    <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
    <button (click)="loadNextPage()" class="btn btn-success">Next</button>
</div>

</div>