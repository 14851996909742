<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/stock-report">Detailed Stock  </a></li>
            <li class="breadcrumb-item active" aria-current="page">Report</li>
        </ol>
    </nav>
    <div class="container mt-5">
        <h5>Stock onHand Per Program</h5>
        
        <!-- Date range input section -->
        <div class="row">
            <div class="col-md-4">
                <label>From Date</label>
                <input type="date" [(ngModel)]="fromDate" class="form-control">
            </div>
            <div class="col-md-4">
                <label>To Date</label>
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>
            <div class="col-md-4">
                <button class="btn btn-primary mt-4" (click)="generateReport()">Generate Report</button>
            </div>
        </div>
        
        <!-- Display Report Section (including Heading) -->
        <div *ngIf="reportGenerated" class="mt-5">
            <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF" (click)="downloadPDF()">
                <i class="fas fa-file-pdf"></i>
              </button>
            <!-- Report Header -->
            <div class="text-center">
                <h6><strong>MEDICAL STORES DEPARTMENT (MSD)</strong></h6>
                <p><strong>Report Title:</strong> DETAILED STOCK ON HAND PER PROGRAM</p>
                <p><strong>Purpose:</strong>EACH TO VIEW AVAILABLE STOCK ON HAND</p>
                <p><strong>From:</strong> {{ fromDate || '...' }} <strong>To:</strong> {{ toDate || '...' }}</p>
            </div>
            
            <!-- Report Table -->
            <table class="table table-bordered mt-4">
                <thead>
                    <tr>
                        <th>Plant</th>
                        <th>Item Code</th>
                        <th>Revision</th>
                        <th>Item Description</th>
                        <th>UOM </th>
                        <th>Lot Number</th>
                        <th>Batch OnHand QTY</th>
                        <th>Available onHand QTY</th>
                        <th>Expiry Date</th>
                        <th>Mfg Date</th>
                        <th>Shelf Life (%)</th>
                        <th>Warehouse</th>
                        <th>Bin Location</th>
                        <th>WAC (TShs)</th>
                        <th>Value (TShs)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of reportData">
                        <td>{{ item.plant }}</td>
                        <td>{{ item.itemcode }}</td>
                        <td>{{ item.revision }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.uom }}</td>
                        <td>{{ item.lotnumber }}</td>
                        <td>{{ item.batch }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.expirydate }}</td>
                        <td>{{ item.mfgdate }}</td>
                        <td>{{ item.shelf }}</td>
                        <td>{{ item.warehouse }}</td>
                        <td>{{ item.binlocation }}</td>
                        <td>{{ item.wac }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                </tbody>
            </table>
            
            <!-- Button to Download PDF -->
            <!-- <button class="btn btn-success mt-3" (click)="downloadPDF()">Download PDF</button> -->
        </div>
    </div>
    
  
</div>