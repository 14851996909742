<!-- <app-layouts></app-layouts> -->


<app-top-navbar></app-top-navbar>

<div class="content-area">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/configuration/facilities">Settings</a></li>
            <li class="breadcrumb-item active" aria-current="page">Health Facilities</li>
        </ol>
    </nav>
    <!-- <p>Settings > Health Facilities</p> -->
    <div class="filters">
        <form>
            <div class="row">
                <!-- Facility Filter -->
                <div class="form-group col-md-6">
                    <label for="facility">Facility Name</label>
                    <input type="text" id="facility" name="facility" class="form-control">
                </div>
                <!-- Order Number Filter -->
                <div class="form-group col-md-6">
                    <label for="orderNo">Council</label>
                    <input type="text" id="orderNo" name="orderNo" class="form-control">
                </div>
            </div>

            <div class="row">
                <!-- Start Date Filter -->

                <!-- <div class="form-group col-md-6">
                        <label for="startDate">Start Date</label>
                        <input type="date" id="startDate" name="startDate"
                            class="form-control">
                    </div> -->
                <!-- End Date Filter -->
                <!-- <div class="form-group col-md-6">
                        <label for="endDate">End Date</label>
                        <input type="date" id="endDate"  name="endDate"
                            class="form-control">
                    </div> -->
            </div>

            <!-- Submit Button -->
            <div class="row">
                <div class="form-group col-md-12">
                    <button type="submit" class="btn btn-primary">Apply Filters</button>
                </div>
            </div>
        </form>
    </div>
    <div class="facility-table">
        <div class="us">
            <button class="btn btn-success" style="background-color: #005590;" (click)="openAddFacilityDialog()">Add New Facility</button>
            <button class="btn btn-secondary">Send Facilities to eLMIS</button>
        </div>
    
        <table class="table table-striped purchase-table">
            <thead>
                <tr>
                    <th>S/N</th>
                    <th>Facility Code</th>
                    <th>Facility Name</th>
                    <th>Number</th>
                    <th>Region</th>
                    <th>Council</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let facility of facilities; let i = index">
                    <td scope="row">{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                    <td>{{ facility.facilitycode }}</td>
                    <td>{{ facility.facilityname }}</td>
                    <td>{{ facility.facilityno }}</td>
                    <td>{{ facility.hfregcode || 'N/A' }}</td>
                    <td>{{ facility.councilname }}</td>
                    <td>{{ facility.isactive ? 'Active' : 'Not Active' }}</td>
                    <td>
                        <button aria-label="Search">
                            <i class="fas fa-eye"></i> 
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <!-- Pagination controls -->
        <div class="pagination-controls">
            <button class="btn btn-warning" (click)="goToPreviousPage()" [disabled]="currentPage === 1">Previous</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button class="btn btn-success" (click)="goToNextPage()" [disabled]="currentPage === totalPages">Next</button>
        </div>
    </div>
    
    
</div>