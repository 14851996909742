import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';  // To get the route parameter
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [TopNavbarComponent, CommonModule, LoadingSpinnerComponent],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.css'
})
export class OrderDetailsComponent implements OnInit {
  Math = Math;
  orderNo!: string;
  orderDetails: any;
  deliveredItems: any;
  loading = false;

  // Pagination variables
  currentPage = 1;
  itemsPerPage = 10;
  paginatedItems: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loading = true;
    // Get the order number from the route
    this.orderNo = this.route.snapshot.paramMap.get('orderNo')!;

    // Fetch the order details
    this.getOrderDetails();

    // Fetch the delivered items
    this.getDeliveredItems();
  }
  private apiUrl = environment.apiEndpoint;

  getOrderDetails() {
    const orderDetailsEndpoint = `${this.apiUrl}orders/api/v1/facility_orders/${this.orderNo}/`;
    this.http.get(orderDetailsEndpoint).subscribe(
      (data: any) => {


        this.orderDetails = data;
        this.paginateItems();
        this.loading = false;

      }, (error: HttpErrorResponse) => {


        // Check if the error object contains the error message
        if (error.error && error.error.detail) {
          // Display the error message from the backend
          this.loading = false;
          this.toastr.error(error.error.detail);
          // alert(error.error.detail);
        } else {
          // Fallback to a generic error message
          this.toastr.error('Internal Server Error');
          this.loading = false;
          // alert('An unexpected error occurred. Please try again.');
        }

        console.error('Retrieve order failed', error);
      });
  }

   // Method to handle pagination
   paginateItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedItems = this.orderDetails?.items?.slice(startIndex, endIndex);
  }

  // Method to change page
  changePage(page: number) {
    
    this.currentPage = page;
    this.paginateItems();
  }

  // Method to go to the next page
  nextPage() {
    if (this.currentPage * this.itemsPerPage < this.orderDetails?.items?.length) {
      this.currentPage++;
      this.paginateItems();
    }
  }

  // Method to go to the previous page
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginateItems();
    }
  }

  getDeliveredItems() {
    const deliveredItemsEndpoint = `https://api.msd.atomatiki.tech/orders/api/v1/api/v1/pod/${this.orderNo}/`;
    this.http.get(deliveredItemsEndpoint).subscribe((data: any) => {
      this.deliveredItems = data;  // Assuming the data is an array of delivered items
    });
  }
  close() {
    this.router.navigate(['/facility/order']);
  }
}
