<app-top-navbar></app-top-navbar>
<div class="content-area ">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="report/facility-consumption">Facility Report </a></li>
            <li class="breadcrumb-item active" aria-current="page">consumption-status</li>
        </ol>
    </nav>
    <div class="container mt-5">
        <h5>Facility Consumption Report</h5>

        <!-- Date range input section -->
        <div class="row">
            <div class="col-md-4">
                <label>From Date</label>
                <input type="date" [(ngModel)]="fromDate" class="form-control">
            </div>
            <div class="col-md-4">
                <label>To Date</label>
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>
            <div class="col-md-4">
                <button class="btn btn-primary mt-4" (click)="generateReport()">Generate Report</button>
            </div>
        </div>

        <!-- Display Report Section (including Heading) -->
        <div *ngIf="reportGenerated" class="mt-5">
            <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF" (click)="downloadPDF()">
                <i class="fas fa-file-pdf"></i>
            </button>
            <!-- XLS Export Button -->
            <button (click)="exportToExcel()" class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
                <i class="fas fa-file-excel"></i>
            </button>
            <!-- Report Header -->
            <div class="text-center">
                <h6><strong>MEDICAL STORES DEPARTMENT (MSD)</strong></h6>
                <p><strong>Report Title:</strong> FACILITY CONSUMPTION REPORT</p>
                <p><strong>Purpose:</strong> To get actual consumption from facilities</p>
                <p><strong>From:</strong> {{ fromDate || '...' }} <strong>To:</strong> {{ toDate || '...' }}</p>
            </div>

            <!-- Report Table -->
            <table class="table table-bordered mt-4">
                <thead>
                    <tr>
                        <th>Part Number</th>
                        <th>Product Description </th>
                        <th>UOM </th>
                        <th>Plant</th>
                        <th>Region </th>
                        <th>District</th>
                        <th>Facility ID </th>
                        <th>Facility Name</th>
                        <th>Total Coonsumption</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of reportData">
                        <td>{{ item.partnumber }}</td>
                        <td>{{ item.productdescription }}</td>
                        <td>{{ item.uom }}</td>
                        <td>{{ item.plant }}</td>
                        <td>{{ item.region }}</td>
                        <td>{{ item.district }}</td>
                        <td>{{ item.facilityid }}</td>
                        <td>{{ item.facilityname }}</td>
                        <td>{{ item.totalamc }}</td>
                    </tr>
                </tbody>
            </table>

            
        </div>
    </div>


</div>