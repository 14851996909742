import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {
  private apiUrl = environment.apiEndpoint;
  private budgetUrl = 'https://api.msd.atomatiki.tech/accounting/api/v1/budget/';
  // private budgetUrl = 'https://api.msd.atomatiki.tech/accounting/api/v1/budget/';

  constructor(private http: HttpClient) {}

  getBudget(page: number): Observable<any> {
    const token = localStorage.getItem('access_token');
    console.log('token from the storage',token);
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    console.log('Authorization Header:', `Bearer ${token}`);

    return this.http.get<any>(`${this.apiUrl}accounting/api/v1/budget/?page=${page}`, { headers }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  getBudget2(): Observable<any> {
    return this.http.get<any>(this.budgetUrl);
  }

}
