import { Component } from '@angular/core';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-facility-report',
  standalone: true,
  imports: [TopNavbarComponent],
  templateUrl: './facility-report.component.html',
  styleUrl: './facility-report.component.css'
})
export class FacilityReportComponent {

}
