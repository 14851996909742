import { Component } from '@angular/core';

@Component({
  selector: 'app-summary-charged',
  standalone: true,
  imports: [],
  templateUrl: './summary-charged.component.html',
  styleUrl: './summary-charged.component.css'
})
export class SummaryChargedComponent {

}
