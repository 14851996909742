import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutsComponent } from '../../../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-gdf-request-part-number',
  standalone: true,
  imports: [LayoutsComponent,TopNavbarComponent,SidebarComponent,FormsModule,CommonModule],
  templateUrl: './gdf-request-part-number.component.html',
  styleUrl: './gdf-request-part-number.component.css'
})
export class GdfRequestPartNumberComponent {

  partNumbers = [
    { wamboPartNumber: 'WB-75937593', msdPartNumber: '10010007' },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '10010008' },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '10010009' },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '', editable: true },
    { wamboPartNumber: 'WB-93749379', msdPartNumber: '', editable: true }
  ];

}
