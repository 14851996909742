import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsermngtService } from '../../modules/userManagement/usermngt.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;

  constructor(private router: Router, private fb: FormBuilder, private userService: UsermngtService) {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  cancel() {
    this.router.navigate(['/login'])
  }

  confirmpassword() {
    console.log('Password reset function triggered:', this.resetPasswordForm.get('email')?.value);

    if (this.resetPasswordForm.valid) {
      const email = this.resetPasswordForm.get('email')?.value;

      this.userService.resetPassword(email).subscribe(
        (response) => {
          console.log('Password reset link sent successfully', response);

          // Assuming the backend response contains uid64 and token
          const uid64 = response.uid64;
          const token = response.token;

          alert('Password reset link sent successfully');

          this.router.navigate(['/confirmpassword'])
          // this.router.navigate([`/confirmpassword`, uid64, token]);
        },
        (error) => {
          console.error('Error sending password reset link', error);
        }
      );
    } else {
      alert('Invalid email');
      location.reload();
    }
  }
}
