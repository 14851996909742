import { Component } from '@angular/core';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';

@Component({
  selector: 'app-acoountsreports',
  standalone: true,
  imports: [TopNavbarComponent],
  templateUrl: './acoountsreports.component.html',
  styleUrl: './acoountsreports.component.css'
})
export class AcoountsreportsComponent {

}
