<!-- <app-layouts></app-layouts> -->


<!-- <app-sidebar></app-sidebar> -->
<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/facility/outstock">Facilities</a></li>
                <li class="breadcrumb-item active" aria-current="page">Out Of stock</li>
            </ol>
        </nav>
        <!-- <p>Facilities > Orders</p> -->

        <!-- Filters for Date Range, Facility, and Order Number -->
        <div class="filters">
            <form (ngSubmit)="applyFilters()">
                <div class="row">
                    <!-- Facility Filter -->
                    <!-- <div class="form-group col-md-6">
                        <label for="facility">Facility</label>
                        <input type="text" id="facility" [(ngModel)]="filters.facility" name="facility"
                            class="form-control">
                    </div> -->
                    <!-- Order Number Filter -->
                    <div class="form-group col-md-6">
                        <label for="orderNo">Order Number</label>
                        <input type="text" id="orderNo" [(ngModel)]="filters.orderNo" name="orderNo"
                            class="form-control">
                    </div>
                </div>

                <div class="row">
                    <!-- Start Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="startDate">Start Date</label>
                        <input type="date" id="startDate" [(ngModel)]="filters.startDate" name="startDate"
                            class="form-control">
                    </div>
                    <!-- End Date Filter -->
                    <div class="form-group col-md-6">
                        <label for="endDate">End Date</label>
                        <input type="date" id="endDate" [(ngModel)]="filters.endDate" name="endDate"
                            class="form-control">
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="row">
                    <div class="form-group col-md-12">
                        <button type="submit" class="btn btn-primary1">Apply Filters</button>
                    </div>
                </div>
            </form>
        </div>


        <div class="facility-table">
            <div class="d-flex justify-content-between mb-2">
                <!-- Left Side: Export Buttons -->
                <div class="d-flex">
                  <!-- PDF Export Button -->
                  <button class="btn btn-sm btn-outline-primary me-1" title="Export to PDF">
                    <i class="fas fa-file-pdf"></i>
                  </button>
              
                  <!-- XLS Export Button -->
                  <button class="btn btn-sm btn-outline-success me-1" title="Export to XLS">
                    <i class="fas fa-file-excel"></i>
                  </button>
              
                  <!-- CSV Export Button -->
                  <button class="btn btn-sm btn-outline-info me-1" title="Export to CSV">
                    <i class="fas fa-file-csv"></i>
                  </button>
                </div>
              
                <!-- Right Side: Send All to Facility Button -->
                <div>
                  <!-- <button class="btn  btn-success" style="background-color: #005590;">Send All to Facility</button> -->
                </div>
              </div>

            <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
            <table class="table table-striped purchase-table">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Order Number</th>
                        <th>Product Code</th>
                        <th>Out Of Stock Quantity</th>
                        <th>Stock Availability Date</th>
                        <th>Remark</th>

                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <pre>{{ items | json  }}</pre> -->
                    <tr *ngFor="let item of  items.results; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{item.orderno}}</td>
                        <td>{{ item.productcode }}</td>
                        <td>{{ item.osquantity }}</td>
                        <td>{{ item.refdate }}</td>
                        <td>{{item.remark}}</td>
                        <td>
                            <button aria-label="Search">
                                <i class="fas fa-eye"></i>
                            </button>
                           

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Pagination Controls -->
        <div class="pagination-controls d-flex align-items-center">
            <button (click)="loadPreviousPage()" [disabled]="currentPage === 1"
                class="btn btn-warning">Previous</button>
            <!-- Display current page and total pages -->
            <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
            <button (click)="loadNextPage()" class="btn btn-success">Next</button>
        </div>

    </div>
</div>