<app-top-navbar></app-top-navbar>
<!-- <h6>Under Construction</h6> -->
<div class="content-area ">
  <div class="container-fluid">
    <!-- Row for Traffic, New Users, Sales, and Performance -->
    <div class="row text-center">
      <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
        <div class="card bg-danger text-white p-3">
          <div>ORDERS</div>
          <!-- <h3>350,897</h3>
              <small>↑ 3.48% Since last month</small> -->
          <div class="ibox-content d-flex justify-content-between">

            <div>
              <h3 class="no-margins">79</h3>
              <small class="text-white">Facility</small>
            </div>
            <div>
              <h3 class="no-margins" id="travelrequestflight">90</h3>
              <small class="text-white">External</small>
            </div>
            <div>
              <h3 class="no-margins font-bold">169</h3>
              <small class="text-white">Total</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
        <div class="card bg-warning text-white p-3">
          <div>REGISTERED USERS</div>
          <h3>20</h3>
          <small>Total</small>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
        <div class="card bg-primary text-white p-3">
          <div>STOCK ITEMS</div>
          <!-- <h3>924</h3>
              <small>↓ 1.10% Since yesterday</small> -->
              <div class="ibox-content d-flex justify-content-between">

                <div>
                  <h3 class="no-margins">59</h3>
                  <small class="text-white">IN STOCK</small>
                </div>
                <div>
                  <h3 class="no-margins" id="travelrequestflight">10</h3>
                  <small class="text-white">OUT</small>
                </div>
                <div>
                  <h3 class="no-margins font-bold">69</h3>
                  <small class="text-white">Total</small>
                </div>
              </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
        <div class="card bg-info text-white p-3">
          <div>CONSUMPTION</div>
          <h3>49.65%</h3>
          <small>↑ 12% Since last month</small>
        </div>
      </div>
    </div>

    <!-- Sales Value and Total Orders -->
    <div class="row">
      <div class="col-lg-6 col-md-12 mb-4">
        <div class="card p-4">
          <h5>Budget </h5>
          <canvas id="salesChart" style="width: 100%;"></canvas>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mb-4">
        <div class="card p-1">
          <h5>VP Orders</h5>
          <ngx-charts-bar-vertical [view]="[viewWidth, viewHeight]" [results]="totalOrdersData" [gradient]="false"
            [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
            xAxisLabel="Month" yAxisLabel="Orders">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
    
  </div>

</div>

<!-- <div class="content-area ">
            <p style="color:#2E7E8E">Settings > Dashboard</p>
            <div class="dashboard_inside">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5>Orders Status</h5>
                                <canvas id="ordersChart"></canvas>
                                <button class="btn btn-primary">View Data</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5>Items in Stock</h5> 
                                <canvas id="stockChart"></canvas> 
                                <button class="btn btn-primary">Edit report</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5>Cash Summary</h5>
                                <canvas id="cashChart"></canvas>
                                <button class="btn btn-primary">Edit report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
     -->