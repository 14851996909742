import { EventEmitter, Injector, ElementRef, TemplateRef, Injectable, ComponentFactoryResolver, NgZone, ApplicationRef } from '@angular/core';
import { listenToTriggersV2, registerOutsideClick, registerEscClick } from 'ngx-bootstrap/utils';
import { PositioningService } from 'ngx-bootstrap/positioning';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @template T
 */
class BsComponentRef {}
if (false) {
  /** @type {?} */
  BsComponentRef.prototype.templateRef;
  /** @type {?} */
  BsComponentRef.prototype.viewContainer;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @copyright Valor Software
 * @copyright Angular ng-bootstrap team
 */
class ContentRef {
  /**
   * @param {?} nodes
   * @param {?=} viewRef
   * @param {?=} componentRef
   */
  constructor( /* tslint:disable-next-line: no-any */
  nodes, viewRef, /* tslint:disable-next-line: no-any */
  componentRef) {
    this.nodes = nodes;
    this.viewRef = viewRef;
    this.componentRef = componentRef;
  }
}
if (false) {
  /** @type {?} */
  ContentRef.prototype.nodes;
  /** @type {?} */
  ContentRef.prototype.viewRef;
  /** @type {?} */
  ContentRef.prototype.componentRef;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @template T
 */
class ComponentLoader {
  /**
   * Do not use this directly, it should be instanced via
   * `ComponentLoadFactory.attach`
   * \@internal
   * @param {?} _viewContainerRef
   * @param {?} _renderer
   * @param {?} _elementRef
   * @param {?} _injector
   * @param {?} _componentFactoryResolver
   * @param {?} _ngZone
   * @param {?} _applicationRef
   * @param {?} _posService
   */
  // tslint:disable-next-line
  constructor(_viewContainerRef, _renderer, _elementRef, _injector, _componentFactoryResolver, _ngZone, _applicationRef, _posService) {
    this._viewContainerRef = _viewContainerRef;
    this._renderer = _renderer;
    this._elementRef = _elementRef;
    this._injector = _injector;
    this._componentFactoryResolver = _componentFactoryResolver;
    this._ngZone = _ngZone;
    this._applicationRef = _applicationRef;
    this._posService = _posService;
    this.onBeforeShow = new EventEmitter();
    /* tslint:disable-next-line: no-any*/
    this.onShown = new EventEmitter();
    /* tslint:disable-next-line: no-any*/
    this.onBeforeHide = new EventEmitter();
    this.onHidden = new EventEmitter();
    this._providers = [];
    this._isHiding = false;
    /**
     * A selector used if container element was not found
     */
    this.containerDefaultSelector = 'body';
    this._listenOpts = {};
    this._globalListener = Function.prototype;
  }
  /**
   * @return {?}
   */
  get isShown() {
    if (this._isHiding) {
      return false;
    }
    return !!this._componentRef;
  }
  /**
   * @param {?} compType
   * @return {?}
   */
  attach(compType) {
    this._componentFactory = this._componentFactoryResolver.resolveComponentFactory(compType);
    return this;
  }
  // todo: add behaviour: to target element, `body`, custom element
  /**
   * @param {?=} container
   * @return {?}
   */
  to(container) {
    this.container = container || this.container;
    return this;
  }
  /**
   * @param {?=} opts
   * @return {?}
   */
  position(opts) {
    this.attachment = opts.attachment || this.attachment;
    /* tslint:disable-next-line: no-unnecessary-type-assertion */
    this._elementRef = ( /** @type {?} */opts.target) || this._elementRef;
    return this;
  }
  /**
   * @param {?} provider
   * @return {?}
   */
  provide(provider) {
    this._providers.push(provider);
    return this;
  }
  // todo: appendChild to element or document.querySelector(this.container)
  /**
   * @param {?=} opts
   * @return {?}
   */
  show(opts = {}) {
    this._subscribePositioning();
    this._innerComponent = null;
    if (!this._componentRef) {
      this.onBeforeShow.emit();
      this._contentRef = this._getContentRef(opts.content, opts.context, opts.initialState);
      /** @type {?} */
      const injector = Injector.create({
        providers: this._providers,
        parent: this._injector
      });
      this._componentRef = this._componentFactory.create(injector, this._contentRef.nodes);
      this._applicationRef.attachView(this._componentRef.hostView);
      // this._componentRef = this._viewContainerRef
      //   .createComponent(this._componentFactory, 0, injector, this._contentRef.nodes);
      this.instance = this._componentRef.instance;
      Object.assign(this._componentRef.instance, opts);
      if (this.container instanceof ElementRef) {
        this.container.nativeElement.appendChild(this._componentRef.location.nativeElement);
      }
      if (typeof this.container === 'string' && typeof document !== 'undefined') {
        /** @type {?} */
        const selectedElement = document.querySelector(this.container) || document.querySelector(this.containerDefaultSelector);
        selectedElement.appendChild(this._componentRef.location.nativeElement);
      }
      if (!this.container && this._elementRef && this._elementRef.nativeElement.parentElement) {
        this._elementRef.nativeElement.parentElement.appendChild(this._componentRef.location.nativeElement);
      }
      // we need to manually invoke change detection since events registered
      // via
      // Renderer::listen() are not picked up by change detection with the
      // OnPush strategy
      if (this._contentRef.componentRef) {
        this._innerComponent = this._contentRef.componentRef.instance;
        this._contentRef.componentRef.changeDetectorRef.markForCheck();
        this._contentRef.componentRef.changeDetectorRef.detectChanges();
      }
      this._componentRef.changeDetectorRef.markForCheck();
      this._componentRef.changeDetectorRef.detectChanges();
      this.onShown.emit(opts.id ? {
        id: opts.id
      } : this._componentRef.instance);
    }
    this._registerOutsideClick();
    return this._componentRef;
  }
  /**
   * @param {?=} id
   * @return {?}
   */
  hide(id) {
    if (!this._componentRef) {
      return this;
    }
    this._posService.deletePositionElement(this._componentRef.location);
    this.onBeforeHide.emit(this._componentRef.instance);
    /** @type {?} */
    const componentEl = this._componentRef.location.nativeElement;
    componentEl.parentNode.removeChild(componentEl);
    if (this._contentRef.componentRef) {
      this._contentRef.componentRef.destroy();
    }
    if (this._viewContainerRef && this._contentRef.viewRef) {
      this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._contentRef.viewRef));
    }
    if (this._contentRef.viewRef) {
      this._contentRef.viewRef.destroy();
    }
    this._contentRef = null;
    this._componentRef = null;
    this._removeGlobalListener();
    this.onHidden.emit(id ? {
      id
    } : null);
    return this;
  }
  /**
   * @return {?}
   */
  toggle() {
    if (this.isShown) {
      this.hide();
      return;
    }
    this.show();
  }
  /**
   * @return {?}
   */
  dispose() {
    if (this.isShown) {
      this.hide();
    }
    this._unsubscribePositioning();
    if (this._unregisterListenersFn) {
      this._unregisterListenersFn();
    }
  }
  /**
   * @param {?} listenOpts
   * @return {?}
   */
  listen(listenOpts) {
    this.triggers = listenOpts.triggers || this.triggers;
    this._listenOpts.outsideClick = listenOpts.outsideClick;
    this._listenOpts.outsideEsc = listenOpts.outsideEsc;
    listenOpts.target = listenOpts.target || this._elementRef.nativeElement;
    /** @type {?} */
    const hide = this._listenOpts.hide =
    /**
    * @return {?}
    */
    () => listenOpts.hide ? listenOpts.hide() : void this.hide();
    /** @type {?} */
    const show = this._listenOpts.show =
    /**
    * @param {?} registerHide
    * @return {?}
    */
    registerHide => {
      listenOpts.show ? listenOpts.show(registerHide) : this.show(registerHide);
      registerHide();
    };
    /** @type {?} */
    const toggle =
    /**
    * @param {?} registerHide
    * @return {?}
    */
    registerHide => {
      this.isShown ? hide() : show(registerHide);
    };
    this._unregisterListenersFn = listenToTriggersV2(this._renderer, {
      target: listenOpts.target,
      triggers: listenOpts.triggers,
      show,
      hide,
      toggle
    });
    return this;
  }
  /**
   * @return {?}
   */
  _removeGlobalListener() {
    if (this._globalListener) {
      this._globalListener();
      this._globalListener = null;
    }
  }
  /**
   * @param {?} vRef
   * @param {?} template
   * @return {?}
   */
  attachInline(vRef, /* tslint:disable-next-line: no-any*/
  template) {
    this._inlineViewRef = vRef.createEmbeddedView(template);
    return this;
  }
  /**
   * @return {?}
   */
  _registerOutsideClick() {
    if (!this._componentRef || !this._componentRef.location) {
      return;
    }
    // why: should run after first event bubble
    if (this._listenOpts.outsideClick) {
      /** @type {?} */
      const target = this._componentRef.location.nativeElement;
      setTimeout(
      /**
      * @return {?}
      */
      () => {
        this._globalListener = registerOutsideClick(this._renderer, {
          targets: [target, this._elementRef.nativeElement],
          outsideClick: this._listenOpts.outsideClick,
          hide: (
          /**
          * @return {?}
          */
          () => this._listenOpts.hide())
        });
      });
    }
    if (this._listenOpts.outsideEsc) {
      /** @type {?} */
      const target = this._componentRef.location.nativeElement;
      this._globalListener = registerEscClick(this._renderer, {
        targets: [target, this._elementRef.nativeElement],
        outsideEsc: this._listenOpts.outsideEsc,
        hide: (
        /**
        * @return {?}
        */
        () => this._listenOpts.hide())
      });
    }
  }
  /**
   * @return {?}
   */
  getInnerComponent() {
    return this._innerComponent;
  }
  /**
   * @private
   * @return {?}
   */
  _subscribePositioning() {
    if (this._zoneSubscription || !this.attachment) {
      return;
    }
    this.onShown.subscribe(
    /**
    * @return {?}
    */
    () => {
      this._posService.position({
        element: this._componentRef.location,
        target: this._elementRef,
        attachment: this.attachment,
        appendToBody: this.container === 'body'
      });
    });
    this._zoneSubscription = this._ngZone.onStable.subscribe(
    /**
    * @return {?}
    */
    () => {
      if (!this._componentRef) {
        return;
      }
      this._posService.calcPosition();
    });
  }
  /**
   * @private
   * @return {?}
   */
  _unsubscribePositioning() {
    if (!this._zoneSubscription) {
      return;
    }
    this._zoneSubscription.unsubscribe();
    this._zoneSubscription = null;
  }
  /**
   * @private
   * @param {?} content
   * @param {?=} context
   * @param {?=} initialState
   * @return {?}
   */
  _getContentRef( /* tslint:disable-next-line: no-any*/
  content, /* tslint:disable-next-line: no-any*/
  context, /* tslint:disable-next-line: no-any*/
  initialState) {
    if (!content) {
      return new ContentRef([]);
    }
    if (content instanceof TemplateRef) {
      if (this._viewContainerRef) {
        /** @type {?} */
        const _viewRef = this._viewContainerRef.createEmbeddedView(content, context);
        _viewRef.markForCheck();
        return new ContentRef([_viewRef.rootNodes], _viewRef);
      }
      /** @type {?} */
      const viewRef = content.createEmbeddedView({});
      this._applicationRef.attachView(viewRef);
      return new ContentRef([viewRef.rootNodes], viewRef);
    }
    if (typeof content === 'function') {
      /** @type {?} */
      const contentCmptFactory = this._componentFactoryResolver.resolveComponentFactory(content);
      /** @type {?} */
      const modalContentInjector = Injector.create({
        providers: this._providers,
        parent: this._injector
      });
      /** @type {?} */
      const componentRef = contentCmptFactory.create(modalContentInjector);
      Object.assign(componentRef.instance, initialState);
      this._applicationRef.attachView(componentRef.hostView);
      return new ContentRef([[componentRef.location.nativeElement]], componentRef.hostView, componentRef);
    }
    return new ContentRef([[this._renderer.createText(`${content}`)]]);
  }
}
if (false) {
  /** @type {?} */
  ComponentLoader.prototype.onBeforeShow;
  /** @type {?} */
  ComponentLoader.prototype.onShown;
  /** @type {?} */
  ComponentLoader.prototype.onBeforeHide;
  /** @type {?} */
  ComponentLoader.prototype.onHidden;
  /** @type {?} */
  ComponentLoader.prototype.instance;
  /** @type {?} */
  ComponentLoader.prototype._componentRef;
  /** @type {?} */
  ComponentLoader.prototype._inlineViewRef;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._providers;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._componentFactory;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._zoneSubscription;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._contentRef;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._innerComponent;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._unregisterListenersFn;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._isHiding;
  /**
   * Placement of a component. Accepts: "top", "bottom", "left", "right"
   * @type {?}
   * @private
   */
  ComponentLoader.prototype.attachment;
  /**
   * A selector specifying the element the popover should be appended to.
   * @type {?}
   * @private
   */
  ComponentLoader.prototype.container;
  /**
   * A selector used if container element was not found
   * @type {?}
   * @private
   */
  ComponentLoader.prototype.containerDefaultSelector;
  /**
   * Specifies events that should trigger. Supports a space separated list of
   * event names.
   * @type {?}
   * @private
   */
  ComponentLoader.prototype.triggers;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._listenOpts;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._globalListener;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._viewContainerRef;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._renderer;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._elementRef;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._injector;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._componentFactoryResolver;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._ngZone;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._applicationRef;
  /**
   * @type {?}
   * @private
   */
  ComponentLoader.prototype._posService;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class ComponentLoaderFactory {
  /**
   * @param {?} _componentFactoryResolver
   * @param {?} _ngZone
   * @param {?} _injector
   * @param {?} _posService
   * @param {?} _applicationRef
   */
  constructor(_componentFactoryResolver, _ngZone, _injector, _posService, _applicationRef) {
    this._componentFactoryResolver = _componentFactoryResolver;
    this._ngZone = _ngZone;
    this._injector = _injector;
    this._posService = _posService;
    this._applicationRef = _applicationRef;
  }
  /**
   *
   * @template T
   * @param {?} _elementRef
   * @param {?} _viewContainerRef
   * @param {?} _renderer
   * @return {?}
   */
  createLoader(_elementRef, _viewContainerRef, _renderer) {
    return new ComponentLoader(_viewContainerRef, _renderer, _elementRef, this._injector, this._componentFactoryResolver, this._ngZone, this._applicationRef, this._posService);
  }
}
ComponentLoaderFactory.decorators = [{
  type: Injectable
}];
/** @nocollapse */
ComponentLoaderFactory.ctorParameters = () => [{
  type: ComponentFactoryResolver
}, {
  type: NgZone
}, {
  type: Injector
}, {
  type: PositioningService
}, {
  type: ApplicationRef
}];
if (false) {
  /**
   * @type {?}
   * @private
   */
  ComponentLoaderFactory.prototype._componentFactoryResolver;
  /**
   * @type {?}
   * @private
   */
  ComponentLoaderFactory.prototype._ngZone;
  /**
   * @type {?}
   * @private
   */
  ComponentLoaderFactory.prototype._injector;
  /**
   * @type {?}
   * @private
   */
  ComponentLoaderFactory.prototype._posService;
  /**
   * @type {?}
   * @private
   */
  ComponentLoaderFactory.prototype._applicationRef;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { BsComponentRef, ComponentLoader, ComponentLoaderFactory, ContentRef };
