import { Component } from '@angular/core';

@Component({
  selector: 'app-auditaccounts',
  standalone: true,
  imports: [],
  templateUrl: './auditaccounts.component.html',
  styleUrl: './auditaccounts.component.css'
})
export class AuditaccountsComponent {

}
