<h2 mat-dialog-title>Add New Facility</h2>

<form [formGroup]="facilityForm" (ngSubmit)="onSubmit()" class="facility-form">
  <div class="form-row">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Facility Number</mat-label>
      <input matInput formControlName="facilityno" type="number" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Facility Code</mat-label>
      <input matInput formControlName="facilitycode" required>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Facility Name</mat-label>
      <input matInput formControlName="facilityname" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Facility Type</mat-label>
      <input matInput formControlName="facilitytype" required>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>HF Reg Code</mat-label>
      <input matInput formControlName="hfregcode" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Ownership Authority</mat-label>
      <input matInput formControlName="ownershipauthority" required>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Ownership Category</mat-label>
      <input matInput formControlName="ownershipcategory" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Council</mat-label>
      <mat-select formControlName="council" required>
        <mat-option *ngFor="let council of councils" [value]="council.councilname">{{ council.councilname }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-checkbox formControlName="isactive">Active</mat-checkbox>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="facilityForm.invalid">Add Facility</button>
  </div>
</form>
