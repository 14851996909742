import { Component } from '@angular/core';
import { TopNavbarComponent } from '../../../shared/top-navbar/top-navbar.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { LayoutsComponent } from '../../../pages/layouts/layouts.component';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { MainDashboardComponent } from '../../main-dashboard/main-dashboard.component';


interface Permission {
  class: string;
  permission: string;
  // email: string;
  // username: string;
  // role: string;
}

@Component({
  selector: 'app-permission',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,FormsModule,MatTableModule,MainDashboardComponent,SidebarComponent,ReactiveFormsModule,LayoutsComponent],
  templateUrl: './permission.component.html',
  styleUrl: './permission.component.css'
})
export class PermissionComponent {
  displayedColumns: string[] = ['S/N', 'Class', 'Permission','actions'];
  users: Permission[] = [
    {
      class: 'Configuration',
      permission: 'Add part Number',
      
      
    },
    {
      class: 'Accounts',
      permission: 'View Budget',
    },
  ]

}
