<!-- uom-dialog.component.html -->
<h2 mat-dialog-title>Map Part Number</h2>
<mat-dialog-content>
  <p><strong>Part Number:</strong> {{ data.partnumber }}</p>
  <mat-form-field>
    <mat-label>MSD Part Number</mat-label>
    <input matInput [(ngModel)]="msdPartno" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button >Map</button>
</mat-dialog-actions>
