<div class="parent">
    <div class="container" id="container">

        <div class="form-container sign-in">
           
            <form  (ngSubmit)="confirmpassword()"[formGroup]="resetPasswordForm">
                <!-- <h1>Sign In</h1> -->
                <img src="assets/msd.png"/>

             
                <input type="text" id="email" formControlName="email" placeholder="enter your email..." />
               

                
                 <div class="row">
                    <button   type="submit" [disabled]="resetPasswordForm.invalid" >Reset</button> 
                    <P>Go back to login? <a href="/login"><u>click here</u></a></P>
                 </div>
              
             
               
            </form>
        </div>
        <div class="toggle-container">
            <div class="toggle">
                <!-- <div class="toggle-panel toggle-left">
                    <h1>Welcome Back!</h1>
                    <p>Enter your personal details to use all of portal features</p>
                    <button class="hidden" id="login">Sign In</button>
                </div> -->
                <div class="toggle-panel toggle-right">
                    <h1>MSD PORTAL</h1>
                    <!-- <p>Login with your personal details to use all of portal features</p> -->
                    <!-- <button class="hidden" id="register">Sign Up</button> -->
                </div>
            </div>
        </div>
       
    </div>
  
</div>


<!-- <script src="script.js"></script> -->