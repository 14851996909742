import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerComponent } from '../../../../shared/loading/loading-spinner/loading-spinner.component';
import { TopNavbarComponent } from '../../../../shared/top-navbar/top-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UsermngtService } from '../../usermngt.service';

@Component({
  selector: 'app-viewusers',
  standalone: true,
  imports: [CommonModule,TopNavbarComponent,LoadingSpinnerComponent],
  templateUrl: './viewusers.component.html',
  styleUrl: './viewusers.component.css'
})
export class ViewusersComponent implements OnInit {
  id!: string;
  loading = false;
  user : any ={};
  userdetails: any;
  
constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router,private userProfileService: UsermngtService,){}
  ngOnInit(): void {
    this.loading=true;
  this.id = this.route.snapshot.paramMap.get('id')!;
  this.ViewUserDetails(this.id);
  }

  ViewUserDetails(id: string){
    this.userProfileService.getUserProfile(id).subscribe(
      (response) => {
        this.user = response;
        // this.phoneNumber = response.phone;
        console.log('User Details:', this.user);
      },
      (error) => {
        console.error('Error fetching user profile:', error);
      }
    );
  }

}
