<div class="d">
    <app-top-navbar></app-top-navbar>
    <div class="content-area ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/facility/consumption">Facilities</a></li>
                <li class="breadcrumb-item"><a href="/facility/consumption">Consumption</a></li>
                <li class="breadcrumb-item active" aria-current="page">Details</li>
            </ol>
        </nav>
        <!-- <h5>Facilities > Orders </h5> -->
        <!-- <pre>{{ consumptionDetails | json }}</pre> -->
        <div class="order-info">
            <h6>Consumption Details</h6>
          
            <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
          
            <div *ngIf="consumptionDetails?.length > 0; else noData">
              <div *ngFor="let detail of consumptionDetails">
                <p><strong>Facility:</strong> {{ detail.facilitycode }}</p>
                <p><strong>Facility Number:</strong> {{ detail.facilityno }}</p>
                
                <p><strong>Items:</strong></p>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Facility Number</th>
                      <th>Product Code</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>UOM</th>
                      <th>Month ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of detail.items">
                      <td>{{ detail.facilityno }}</td>
                      <td>{{ item.productcode }}</td>
                      <td>{{ item.productname }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.uom }}</td>
                      <td>{{ item.monthid }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
            <!-- Template to show if no data is found -->
            <ng-template #noData>
              <p class="text-center">No data found</p>
            </ng-template>
          </div>
          
        <div class="close-button">
            <button (click)="close()" class="btn btn-primary">Close</button>
        </div>
    </div>
</div>