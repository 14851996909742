import { Component, OnInit } from '@angular/core';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';
import { TopNavbarComponent } from '../../shared/top-navbar/top-navbar.component';
import { FacilityComponent } from '../../modules/facility/facility.component';
import { HeaderComponent } from '../../shared/header/header.component';
import { LayoutsComponent } from '../layouts/layouts.component';
import { Chart } from 'chart.js/auto';
import { FooterComponent } from '../../shared/footer/footer.component';
import { MainDashboardComponent } from '../../modules/main-dashboard/main-dashboard.component';
import { OheaderComponent } from '../../shared/oheader/oheader.component';
import {  registerables } from 'chart.js';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SidebarComponent,TopNavbarComponent,FacilityComponent,LayoutsComponent,FooterComponent,MainDashboardComponent,OheaderComponent,NgxChartsModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  viewWidth!: number;
  viewHeight!: number;

  totalOrdersData = [
    { name: 'Jul', value: 25 },
    { name: 'Aug', value: 15 },
    { name: 'Sep', value: 30 },
    { name: 'Oct', value: 20 },
    { name: 'Nov', value: 18 },
    { name: 'Dec', value: 30 },
  ];

  constructor() {
    Chart.register(...registerables);
  }

  

  ngOnInit(): void {
    this.createSalesChart();
    this.setChartSize();
    window.onresize = () => {
      this.setChartSize();
    };
  }

  createSalesChart() {
    const ctx = document.getElementById('salesChart') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
          label: 'Budget Value',
          data: [10000, 20000, 15000, 30000, 40000, 35000, 50000, 60000],
          borderColor: '#4e73df',
          backgroundColor: 'transparent',
          borderWidth: 2,
          pointRadius: 3,
        }]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            beginAtZero: true
          },
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
  setChartSize() {
    if (window.innerWidth < 768) {
      this.viewWidth = window.innerWidth - 40; // Leave margin on mobile
      this.viewHeight = 300; // Adjust height for mobile
    } else {
      this.viewWidth = 600; // Default desktop size
      this.viewHeight = 400;
    }
  }
  // ngOnInit(): void {
  //   this.initializeCharts();
  // }

  // initializeCharts() {
  //   const ordersChart = new Chart('ordersChart', {
  //     type: 'pie',
  //     data: {
  //       labels: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
  //       datasets: [{
  //         data: [300, 50, 100, 150],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
  //       }]
  //     }
  //   });

  //   const stockChart = new Chart('stockChart', {
  //     type: 'bar',
  //     data: {
  //       labels: ['Title 1', 'Title 2', 'Title 3'],
  //       datasets: [{
  //         label: 'Medicines',
  //         data: [12, 19, 3],
  //         backgroundColor: ['#4BC0C0', '#FFCE56', '#36A2EB']
  //       }]
  //     }
  //   });

  //   const cashChart = new Chart('cashChart', {
  //     type: 'bar',
  //     data: {
  //       labels: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6', 'Option 7', 'Option 8'],
  //       datasets: [{
  //         label: 'CRDB',
  //         data: [12, 19, 3, 5, 2, 3, 10, 7],
  //         backgroundColor: ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545', '#fd7e14', '#ffc107', '#28a745']
  //       }]
  //     }
  //   });
  // }

}
