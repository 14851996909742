<div class="parent">
    <div class="container" id="container">
        <!-- <div class="form-container sign-up">
            <form>
                <h1>Create Account</h1>
                <div class="social-icons">
                    <a href="#" class="icon"><i class="fa-brands fa-google-plus-g"></i></a>
                    <a href="#" class="icon"><i class="fa-brands fa-facebook-f"></i></a>
                    <a href="#" class="icon"><i class="fa-brands fa-github"></i></a>
                    <a href="#" class="icon"><i class="fa-brands fa-linkedin-in"></i></a>
                </div>
                <span>or use your email for registeration</span>
                <input type="text" placeholder="Name">
                <input type="email" placeholder="Email">
                <input type="password" placeholder="Password">
                <button>Sign Up</button>
            </form>
        </div> -->
        <div class="form-container sign-in">
           
            <form (ngSubmit)="login()" [formGroup]="loginForm">
            <!-- <form > -->
                <!-- <h1>Sign In</h1> -->
                <img src="assets/msd.png"/>

                <!-- <input type="email" name="Emaild" id="email" [(ngModel)]="email" placeholder="Email"> -->
                <input type="text" id="username" formControlName="username" placeholder="username..." />
                <!-- <input type="password" name="password" id="password" [(ngModel)]="password" placeholder="Password"> -->
                <input type="password" id="password" formControlName="password" placeholder="Password.." />
                <!-- <re-captcha siteKey="6LfcoCkqAAAAAPj_tC-fPosLpzkRm-DgHE-ywpoF"  name="captcha"> -->
                <re-captcha siteKey="6Lfaw0wqAAAAABQSVgoROfVaGKdIls9_ylkYA98E" 
                (resolved)="resolvedCaptcha($event)"
                 name="captcha">
                </re-captcha>

                <!-- <button (click)="onLogin()"type="submit">Sign In</button> -->
                <button type="submit" [disabled]="loginForm.invalid">Login</button>
                <p>Forgot Password? <a href="/reset"><u>Reset here</u></a></p>

               
            </form>
        </div>
        <div class="toggle-container">
            <div class="toggle">
                <!-- <div class="toggle-panel toggle-left">
                    <h1>Welcome Back!</h1>
                    <p>Enter your personal details to use all of portal features</p>
                    <button class="hidden" id="login">Sign In</button>
                </div> -->
                <div class="toggle-panel toggle-right">
                    <h1>MSD PORTAL</h1>
                    <!-- <p>Login with your personal details to use all of portal features</p> -->
                    <!-- <button class="hidden" id="register">Sign Up</button> -->
                </div>
            </div>
        </div>
       
    </div>
   
    <!-- <app-loading-spinner *ngIf="loading"></app-loading-spinner> -->
</div>


<!-- <script src="https://www.google.com/recaptcha/api.js?render=6Ld8pEwqAAAAAG9ZO9eZ4Lxhv6Pfm0bN08Ge0-Xc"></script> -->
